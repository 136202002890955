import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  status: "idle", // possible values: "idle", "loading", "success", "error"
  error: null,
};

const UserAccountDetailsSlice = createSlice({
  name: "UserAccountDetails",
  initialState,
  reducers: {
    setUserAccountDetails: (state, action) => {
      state.data = action.payload;
      state.status = "success";
      state.error = null;
    },


    //   logout reset to initial state
    logout: () => initialState,
  },
});

export const { setUserAccountDetails, logout} = UserAccountDetailsSlice.actions;
export default UserAccountDetailsSlice.reducer;
