import { createSlice } from "@reduxjs/toolkit";

const initialState = [];

const resumeSkillSlice = createSlice({
    name: "resumeSkills",
    initialState,
    reducers: {
        setResumeSkills: (state, action) => {
           return action.payload; 
        },
        removeResumeSkills: (state, action) => {
            return [];
        }
    }
});

export const { setResumeSkills, removeResumeSkills } = resumeSkillSlice.actions;
export default resumeSkillSlice.reducer;