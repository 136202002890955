import { createSlice } from "@reduxjs/toolkit";



const myCurrencySlice = createSlice({
  name: "myCurrency",
  initialState: {
    value: 'US'
  },
  reducers: {
    changeCurrency: (state, action) => {      
        state.value = action.payload
    },

  }
})

// Action creators are generated for each case reducer function
export const { changeCurrency} = myCurrencySlice.actions
export default myCurrencySlice.reducer