import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BASE_URL } from "../../config/Properties";

export const fetchGender = createAsyncThunk("gender/fetchData", async () => {
  try {
    const response = await fetch(
      `${BASE_URL}/skill/api/v1/skills/get-master-data/Gender Map`
    );
    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
});

const genderMapSlice = createSlice({
  name: "gender",
  initialState: {
    status: "idle",
    error: null,
    data: [],
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchGender.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchGender.fulfilled, (state, action) => {
        state.status = "succeeded";
        const homeLang = localStorage.getItem("HLang");
        state.data = action.payload?.filter(
          (data) => data?.mlanguage === homeLang && data?.active === "Yes"
        );
      })
      .addCase(fetchGender.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default genderMapSlice.reducer;
