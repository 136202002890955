import { createAsyncThunk } from "@reduxjs/toolkit";
import { BASE_URL } from "../../config/Properties";

export const fetchDataSkillsAcquired = createAsyncThunk(
  "skillsAcquired/fetchData",
  async () => {
    try {
      // const token = useSelector(state => state.userProfile.token);
      const token = localStorage.getItem("token");
      const userId = localStorage.getItem("userId");

      const response = await fetch(
        `${BASE_URL}/skill/api/v1/skills/get-all-user-data-by-userId/Skills Acquired/${userId}?authToken=${token}`
      );
      const data = await response.json();
      return data;
    } catch (error) {
      throw error;
    }
  }
);
