import React from "react";

const CustomInput = ({ type, id, className, style, value, onChange }) => {
  return (
    <input
      type={type}
      id={id}
      className={`form-control ${className}`}
      style={style}
      value={value}
      onChange={onChange}
    />
  );
};

export default CustomInput;
