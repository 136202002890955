import { lazy } from 'react';

const skillOwnerRoutes = [
  {
    exact: 'true',
    path: 'skillprofile',
    element: lazy(() => import('../../pages/SkillOwner/SkillProfile/SkillProfileDashboard'))
  },
  {
    exact: 'true',
    path: 'timeline',
    element: lazy(() => import('../../pages/SkillOwner/Timeline/Timeline'))
  },
  {
    exact: 'true',
    path: 'jobview/:id',
    element: lazy(() => import('../../pages/SkillOwner/Job View/JobViewSo'))
  },
  {
    exact: 'true',
    path: 'courseview/:id',
    element: lazy(() => import('../../pages/SkillOwner/course view/CourseViewSo'))
  },
  {
    exact: 'true',
    path: 'detailedprofile',
    element: lazy(() => import('../../pages/SkillOwner/DetailedProfile/DetailsProfileManagement'))
  },
  {
    exact: 'true',
    path: 'detailedprofile/emploment',
    element: lazy(() => import('../../pages/SkillOwner/DetailedProfile/DetailsProfileManagement'))
  },
  {
    exact: 'true',
    path: 'detailedprofile/project',
    element: lazy(() => import('../../pages/SkillOwner/DetailedProfile/ProjectHistory'))
  },
  {
    exact: 'true',
    path: 'detailedprofile/education',
    element: lazy(() => import('../../pages/SkillOwner/DetailedProfile/EducationHistory'))
  },
  {
    exact: 'true',
    path: 'detailedprofile/certification',
    element: lazy(() => import('../../pages/SkillOwner/DetailedProfile/CertificationHistory'))
  },
  {
    exact: 'true',
    path: 'detailedprofile/training',
    element: lazy(() => import('../../pages/SkillOwner/DetailedProfile/TrainingHistory'))
  },
  {
    exact: 'true',
    path: 'detailedprofile/conference',
    element: lazy(() => import('../../pages/SkillOwner/DetailedProfile/ConferenceHistory'))
  },
  {
    exact: 'true',
    path: 'detailedprofile/skilling',
    element: lazy(() => import('../../pages/SkillOwner/DetailedProfile/SkillingHistory'))
  },
  {
    exact: 'true',
    path: 'detailedprofile/licenses',
    element: lazy(() => import('../../pages/SkillOwner/DetailedProfile/LicenseHistory'))
  },
  {
    exact: 'true',
    path: 'detailedprofile/achivements',
    element: lazy(() => import('../../pages/SkillOwner/DetailedProfile/AchivementsHistory'))
  },
  {
    exact: 'true',
    path: 'detailedprofile/membership',
    element: lazy(() => import('../../pages/SkillOwner/DetailedProfile/MembershipHistory'))
  },
  {
    exact: 'true',
    path: 'detailedprofile/language',
    element: lazy(() => import('../../pages/SkillOwner/DetailedProfile/LanguageHistory'))
  },
  {
    exact: 'true',
    path: 'detailedprofile/others',
    element: lazy(() => import('../../pages/SkillOwner/DetailedProfile/Others'))
  },
  {
    exact: 'true',
    path: '*',
    element: lazy(() => import('../../pages/NotFound'))
  },
];

export default skillOwnerRoutes;
