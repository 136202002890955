import React, { createContext, useState, useContext } from 'react';
import { useSelector } from 'react-redux';

const DirectionContext = createContext();

export const useDirection = () => useContext(DirectionContext);

export const DirectionProvider = ({ children }) => {
  const direction = useSelector(state => state.direction);

  return (
    <DirectionContext.Provider value={{ direction }}>
      <div dir={direction}>{children}</div>
    </DirectionContext.Provider>
  );
};
