import React from "react";

const LazyLoadingImageComponent = ({
  src,
  alt,
  height,
  width,
  style,
  className,
  onError,
  onLoad
}) => {
  return (
    <React.Fragment>
      <img
        src={src}
        className={className}
        style={style}
        width={width}
        height={height}
        alt={alt}
        loading="lazy"
        onError={onError}
        onLoad={onLoad}
      />
    </React.Fragment>
  );
};

export default LazyLoadingImageComponent;
