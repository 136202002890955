import { createSlice } from "@reduxjs/toolkit";
import { fetchMembershipHistory } from "../../api/fetchAllData/fetchMembershipHistory";

const initialState = {
  data: [
  //   {
  //   mtype:"Membership",
  //   fromDate:"",
  //   toDate:"",
  //   duration:"",
  //   briefDescriptions:"",
  //   location:"",
  //   showHide:"",
  //   validation:"",
  //   blockChain:"",
  //   mlanguage:"",
  //   MembershipName:"",
  //   organization:"",
  //   userId:"",
  //   title:""
  // }
  ],
  status: "idle", // possible values: "idle", "loading", "success", "error"
  error: null,
};

const membershipSlice = createSlice({
  name: "membershipHistory",
  initialState,
  reducers: {
    setMembership: (state, action) => {
      state.data = action.payload;
      state.status = "success";
      state.error = null;
    },
    emptyMembership: (state) => {
      state.data = [];
      state.status = "idle";
      state.error = null;
    },
    addNewMembership: (state, action) => {
      state.data = [action.payload,...state.data ];
      state.status = "success";
      state.error = null;
    },
    MembershipDelete: (state, action) => {
      state.data = state.data.filter((skill) => skill.id !== action.payload);
    },
    logoutMembership: ()=> initialState,
    membershipUpdate: (state, action) => {
      const { id, validation } = action.payload;
      const skillToUpdate = state.data.find((skill) => skill.id === id);
      if (skillToUpdate) {
        skillToUpdate.validation = validation;
      }
    },
    membershipUpdateRecord: (state, action) => {
      state.data = state.data.map((skill) => {
        if (skill.id === action.payload.id) {

          return {
            ...skill,
            ...action.payload,
          };
        }

        return skill;
      });
      console.log(state.data);
      state.status = "success";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMembershipHistory.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchMembershipHistory.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = "success";
        state.error = null;
      })
      .addCase(fetchMembershipHistory.rejected, (state, action) => {
        state.status = "error";
        state.error = action.error.message;
      });
  },
});

export const {membershipUpdateRecord, setMembership, emptyMembership,addNewMembership,MembershipDelete,logoutMembership, membershipUpdate } =membershipSlice.actions;
export default membershipSlice.reducer;
