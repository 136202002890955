import React, { useState, useEffect } from "react";
import icons from "../../constants/icons";
const BackToTop = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const toggleVisibility = () => {
      setIsVisible(window.pageYOffset > 300);
    };

    window.addEventListener("scroll", toggleVisibility);
    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  if (!isVisible) return null;

  return (
    <icons.FaChevronCircleUp
      size={35}
      className="backtotop"
      onClick={scrollToTop}
    />
  );
};

export default BackToTop;
