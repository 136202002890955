import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BASE_URL } from "../../config/Properties";

export const fetchData = createAsyncThunk("content/fetchData", async () => {
  try {
    const response = await fetch(`${BASE_URL}/skill/api/v1/content/get`);
    const data = await response.json();
    
    return data;
  } catch (error) {
    throw error;
  }
});


const contentSlice = createSlice({
  name: "content",
  initialState: {
    "AR-SA": null,
    "EN-US": null,
    "ES-ES": null,
    "HI-IN": null,
    "EN-GB": null,
    "TA-IN": null,
    status: "idle",
    error: null,
  },
  reducers: {
    setLanguageContent: (state, action) => {
      state.language = action.payload;
    },
    getLanguageContent: (state) => { 
      return state.en;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchData.fulfilled, (state, action) => {
        state.status = "succeeded";
        const englishUS = action.payload.filter((item) => item.mlanguage === "EN-US");
        const englishGB = action.payload.filter((item) => item.mlanguage === "EN-GB");
        const hindi = action.payload.filter((item) => item.mlanguage === "HI-IN");
        const arabic = action.payload.filter((item) => item.mlanguage === "AR-SA");
        const spanish = action.payload.filter((item) => item.mlanguage === "ES-ES");
        const tamil = action.payload.filter((item) => item.mlanguage === "TA-IN");
        
        state["AR-SA"] = arabic;
        state["ES-ES"] = spanish;
        state["EN-US"] = englishUS;
        state["HI-IN"] = hindi;
        state["EN-GB"] = englishGB;
        state["TA-IN"] = tamil;
      })
      .addCase(fetchData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});



export const { setLanguageContent } = contentSlice.actions;
export default contentSlice.reducer;
