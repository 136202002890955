import { createAsyncThunk } from "@reduxjs/toolkit";
import { BASE_URL } from "../../config/Properties";

export const fetchDataSkillsApplied = createAsyncThunk(
  "skillsApplied/fetchData",
  async () => {
    try {
      const token = localStorage.getItem("token");
      const userId = localStorage.getItem("userId");

      const response = await fetch(`${BASE_URL}/skill/api/v1/skills/get-all-user-data-by-userId/Skills Applied/${userId}?authToken=${token}`);
      const data = await response.json();
      return data;
    } catch (error) {
      throw error;
    }
  }
);
