import { createAsyncThunk } from "@reduxjs/toolkit";
import { BASE_URL } from "../../../config/Properties";

export const fetchOpporShortlistData = createAsyncThunk(
    "fetchOpporShortlistData/fetchData",
    async (body) => {
        try {
            const token = localStorage.getItem("token");
            const userId = localStorage.getItem("userId");

            const response = await fetch(`${BASE_URL}/skill/api/v1/skills/jd/Jdapplication?filter=jid%3A${body.jid}${body.preFilter ? body.preFilter : ""}${body.filter ? body.filter : ""}&start=${body.start}&size=${body.size}&sortField=${body.sortField}&sortOrder=${body.sortOrder}&authToken=${token}`);

            const data = await response.json();
            return data;
        } catch (error) {
            throw error;
        }
    }
);
