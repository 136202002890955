/* eslint-disable react/prop-types */

import { useEffect, useRef, useState } from "react";
import { FaAngleRight, FaAngleLeft } from "react-icons/fa6";
import { useLocation, useNavigate } from "react-router-dom";



const CheckoutStepper = ({ steps }) => {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const [currentStep, setCurrentStep] = useState(0);
  const [margins, setMargins] = useState({
    marginLeft: 0,
    marginRight: 0,
  });
  const stepRef = useRef([]);

  useEffect(() => {
    setMargins({
      marginLeft: stepRef.current[0].offsetWidth / 2,
      marginRight: stepRef.current[steps.length - 1].offsetWidth / 2,
    });
  }, [stepRef, steps.length]);

  useEffect(() => {
    const step = steps?.find((step) => {
      return step.link === pathname
    })
    setCurrentStep(step?.number)
  }, [pathname])

  console.log(currentStep);





  const calculateProgressBarWidth = () => {
    // return ((currentStep - 1) / (steps.length - 1)) * 100;    ///////// This will active the steps complited progressbar colors 
    return 0
  };


  // const ActiveComponent = steps[currentStep - 1]?.Component;  ///////// active component without routing


  if (!steps.length) {
    return <></>;
  }


  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="stepper">
            {steps.map((step, index) => {
              return (
                <div
                  key={step.name}
                  ref={(el) => (stepRef.current[index] = el)}
                  // className={`step ${currentStep > index + 1 || isComplete ? "complete" : "" } ${currentStep === index + 1 ? "active" : ""} `}    ///////// This will active the steps complited colors                       
                  className={`step ${currentStep === step?.number ? "active" : ""} `}
                  onClick={() => {
                    navigate(step.link)
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  <div className="step-number">

                    {
                      step?.number
                    }

                  </div>
                  <div className="step-name" style={{ color: currentStep === step?.number ? '#815F0B' : '', fontWeight: currentStep === step?.number ? 'bold' : '' }}>{step.name}</div>
                </div>
              );
            })}

            <div
              className="progress-bar"
              style={{
                width: `calc(100% - ${margins.marginLeft + margins.marginRight}px)`,
                // width: `94%`,
                marginLeft: margins.marginLeft,
                marginRight: margins.marginRight,
              }}>
              <div
                className="progress"
                style={{ width: `${calculateProgressBarWidth()}%` }}

              ></div>
            </div>
          </div>
        </div>

      </div>

    </>
  );

}




export default CheckoutStepper;
