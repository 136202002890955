import { createSlice } from "@reduxjs/toolkit";
import { fetchDataSkillsApplied } from "../../api/fetchAllData/fetchDataSkillsApplied";

const initialState = {
  data: [
    {
      id: "",
      title: "",
      fromDate: "",
      toDate: "",
      duration: "",
      briefDescription: "",
      organization: "",
      location: "",
      type: "",
      showHide: true,
      validation: true,
      blockChain: true,
      dateSent: "",
      validatorName: "",
      relationship: "",
      description: "",
      validatorEmail: "",
      validatorPhone: "",
      isValidated: true,
    },
  ],
  status: "idle", // possible values: "idle", "loading", "success", "error"
  error: null,
};


const SkillsAppliedSlice = createSlice({
  name: "SkillsApplied",
  initialState,
  reducers: {
    setSkillsApplied: (state, action) => {
      state.data = action.payload;
      state.status = "success";
      state.error = null;
    },
    skillDelete: (state, action) => {
      state.data = state.data.filter((skill) => skill.id !== action.payload);
    },
    addNewSkillApplied: (state, action) => {
      state.data = [action.payload, ...state.data];
      state.status = "success";
      state.error = null;
    },
    emptySkillsApplied: (state) => {
      state.data = [];
      state.status = "idle";
      state.error = null;
    },
    editSkillApplied: (state, action) => {
      state.data = state.data.map((skill) => {
        if (skill.id === action.payload.id) {
          
          return {
            ...skill,
            ...action.payload,
          };
        }
        
        return skill;
      });
      console.log(state.data);
      state.status = "success";
      state.error = null;
    },
    logoutSkillsApplied: () => initialState,
    updateValidation: (state, action) => {
      const { id, validation } = action.payload;
      const skillToUpdate = state.data.find((skill) => skill.id === id);
      if (skillToUpdate) {
        skillToUpdate.validation = validation;
      }
    },

  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDataSkillsApplied.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchDataSkillsApplied.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = "success";
        state.error = null;
      })
      .addCase(fetchDataSkillsApplied.rejected, (state, action) => {
        state.status = "error";
        state.error = action.error.message;
      });
  },
});

export const { setSkillsApplied,editSkillApplied, emptySkillsApplied, skillDelete, addNewSkillApplied, logoutSkillsApplied, updateValidation } =
  SkillsAppliedSlice.actions;
export default SkillsAppliedSlice.reducer;
