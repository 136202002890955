import React from "react";
import { Navigate } from "react-router-dom";

const AuthGuardProvider = ({ role, isSkillOwner, children }) => {
  const userRole = localStorage.getItem("USER_ROLE");
  const token = localStorage.getItem("token");

  if (!token || (!role?.includes(userRole) && isSkillOwner)) {
    return <Navigate to={"/skillowner/user-auth"} />;
  } else if (!token || (userRole === "R1" && !role?.includes(userRole))) {
    return <Navigate to={"/skillowner/user-auth"} />;
  } else if (!token || !role?.includes(userRole)) {
    return <Navigate to={"/"} />;
  }

  /* RETURN CHILDREN */
  return children;
};

export default AuthGuardProvider;
