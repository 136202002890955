import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GetJdSkillAndQnWithId } from "../../../api/SkillSeeker/job detail/GetJdSkillAndQnWithId.js";
import { showErrorToast } from "../../../components/ToastNotification/showErrorToast.js";
import { useDispatch } from 'react-redux';
import store from "../../../Store.js";
import { FetchSavedSearches } from "../../../api/SkillSeeker/FetchSavedSearches.js";

// Define a thunk for recalling the JD list

export const recallJd = createAsyncThunk(
    "SavedSearchSlice/recallJd",
    async (_, { getState, rejectWithValue }) => {
        const { SavedSearchSlice } = getState();
        try {
            const response = await FetchSavedSearches(SavedSearchSlice.searchQuery);
            return response;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const initialState = {
    dataList: [],
    SelectedJd: {},
    EditData: {},
    SelectedApplication: {},
    noOfResult: 0,
    status: 'idle',
    dataListLoading: false,
    opportunitiesApiTrigger: false,

    // 'idle', 'loading', 'succeeded', 'failed'
    error: null,
    searchQuery: {
        start: 0,
        size: 10,
        sortOrder: "desc",
        sortField: "favorite",
        filter: ""
    },
    metaData: {
        totalCount: 0,
        nextStart: 0,
        hasNext: true
    }

};
const SavedSearchSlice = createSlice({
    name: "SavedSearchSlice",
    initialState: {
        dataList: [],
        SelectedJd: {},
        EditData: {},
        noOfResult: 0,
        status: 'idle',
        dataListLoading: false,
        SelectedApplication: {},
        opportunitiesApiTrigger: false,
        // 'idle', 'loading', 'succeeded', 'failed'
        error: null,
        searchQuery: {
            start: 0,
            size: 10,
            sortOrder: "desc",
            sortField: "favorite",
            filter: ""
        },
        metaData: {
            totalCount: 0,
            nextStart: 0,
            hasNext: true
        }

    },
    reducers: {
        setSavedDataList: (state, action) => {


            state.dataList = action.payload;

        },
        setAcquiredSkills: (state, action) => {
            const { userId, acquiredSkillData } = action.payload;
            const index = state.SelectedApplication && state.SelectedApplication.mystProfile === userId ? 0 : -1;
            console.log("found Acquired  index ", index);
            // If the object with the given userId is found
            if (index !== -1) {
                console.log("Object with id axquired", acquiredSkillData, " found.");

                if (acquiredSkillData?.length > 0) {
                    acquiredSkillData?.map((datas) => {
                        return {
                            ...datas,
                            validation: datas?.ValidationData?.length > 0 ? "Yes" : "No"
                        }
                    })
                }



                // Create a copy of the object with the additional field added
                state.SelectedApplication = { ...state.SelectedApplication, skillacq: acquiredSkillData };
                console.log(state.SelectedApplication);
            } else {
                console.log("Object with id axquired", acquiredSkillData, " not found.");
            }
        },
        setAppliedSkills: (state, action) => {
            const { userId, appliedSkillData } = action.payload;
            const index = state.SelectedApplication && state.SelectedApplication.mystProfile === userId ? 0 : -1;
            console.log("found Applie  index ", index);
            // If the object with the given userId is found
            if (index !== -1) {

                if (appliedSkillData?.length > 0) {
                    appliedSkillData?.map((datas) => {
                        return {
                            ...datas,
                            validation: datas?.ValidationData?.length > 0 ? "Yes" : "No"
                        }
                    })
                }

                console.log(appliedSkillData);
                // Create a copy of the object with the additional field added
                state.SelectedApplication = { ...state.SelectedApplication, skillapp: appliedSkillData };
                console.log("Object with id applied", appliedSkillData, "  found.");
                console.log("indx ", state.SelectedApplication);
            } else {
                console.log("Object with id applied", appliedSkillData, " not found.");
            }
        },
        setDetailedProfileData: (state, action) => {
            console.log("action of detailed page ", action.payload);
            const { userID } = action.payload;
            if (userID === null) {
                return;
            }
            const index = state.SelectedApplication && state.SelectedApplication.mystProfile === userID ? 0 : -1;
            console.log("found Detaile  index ", index);
            // If the object with the given userId is found
            if (index !== -1) {
                console.log("All details of user", action.payload, " found.");

                if (action.payload) {
                    state.SelectedApplication = { ...state.SelectedApplication, detailedProfileData: action.payload };

                }

                // Create a copy of the object with the additional field added

            } else {
                console.log("Object with id axquired", userID, " not found.");
            }
        },

        handleSeekerCandidateApiTrigger: (state) => {
            state.opportunitiesApiTrigger = !state.opportunitiesApiTrigger;
            console.log("handleSeekerCandidateApiTrigger");
        },
        setSelectedJd: (state, action) => {
            let data = action.payload;
            // let data = state.dataList?.findIndex(item => item.id === id);
            state.SelectedJd = data;
            console.log("selected ", state.SelectedJd);
            //state.SelectedJd = action.payload;

        },
        setSelectedApplication: (state, action) => {
            let data = action.payload;

            state.SelectedApplication = data;
            console.log(action.payload);
            //state.SelectedJd = action.payload;

        },
        //   logout reset to initial state
        emptyJdDetails: (state) => {
            return initialState;

        },
        emptySelectedApplication: (state) => {
            state.SelectedApplication = {};
        },
        setJdSkillsInSelectedJd: (state, action) => {
            let ids = action.payload;
            let data = action.payload.data;

            if (data.length === 0) {
                return;
            }
            const hasName = data[data.length - 1].hasOwnProperty('id');
            console.log("hasName ", hasName);
            if (!hasName)
                data = data.slice(0, data.length - 1);


            state.SelectedJd.JdSkills = data;
        },
        updateJd: (state, action) => {
            let data = action.payload;
            console.log("data ", data);
            if (data.length === 0) {
                return;
            }
            let jdDataIndx = state.dataList.findIndex(item => item.id === data.id);

            state.dataList[jdDataIndx] = data;
            state.SelectedJd = data;
        },
        setPaginationStore: (state, action) => {
            state.searchQuery.start = action.payload.start;
            state.searchQuery.size = action.payload.size;
        },
        setSortingStore: (state, action) => {
            console.log("state ", action.payload);
            state.searchQuery.sortOrder = action.payload.sortOrder;
            state.searchQuery.sortField = action.payload.sortField;
        },
        setFilterStore: (state, action) => {
            state.searchQuery.filter = action.payload.filter;
        },
        getQueryDetails: (state) => {
            console.log("Query Details JD ", state.searchQuery);
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(recallJd.pending, (state) => {
                state.status = "loading";
                state.error = null;
                state.dataListLoading = true
            })
            .addCase(recallJd.fulfilled, (state, action) => {
                try {
                    let data = action.payload;
                    const length = data.length;
                    state.dataList = data.slice(0, length - 1);
                    state.metaData = data[length - 1];
                    state.status = "success";
                    state.error = null;
                    state.dataListLoading = false;
                    console.log("jd Lists ", data);
                } catch (error) {
                    console.log(error);
                   

                }
            })
            .addCase(recallJd.rejected, (state, action) => {
                state.status = "error";
                state.dataListLoading = false;
                state.error = action.error.message;
            })
            .addCase(FetchSavedSearches.pending, (state) => {
                state.status = "loading";
                state.error = null;
                state.dataListLoading = true
            })
            .addCase(FetchSavedSearches.fulfilled, (state, action) => {
                try {
                    let data = action.payload;
                    const length = data.length;
                    state.dataList = data.slice(0, length - 1);
                    state.metaData = data[length - 1];
                    state.status = "success";
                    state.error = null;
                    state.dataListLoading = false;
                    console.log("jd Lists ", data);
                } catch (error) {
                    console.log(error);
                    

                }
            })
            .addCase(FetchSavedSearches.rejected, (state, action) => {
                state.status = "error";
                state.dataListLoading = false;
                state.error = action.error.message;
            })
            .addCase(GetJdSkillAndQnWithId.pending, (state) => {
                state.status = "loading";
                state.error = null;
            })
            .addCase(GetJdSkillAndQnWithId.fulfilled, (state, action) => {
                try {
                    let data = action.payload;

                    let jdDataIndx = state.dataList.findIndex(item => item.id === data.id);

                    console.log("data ", data);
                    if (!data || jdDataIndx === -1 || Object.keys(data).length === 0) {
                        if (jdDataIndx !== -1) {
                            state.dataList[jdDataIndx] = {
                                ...state.dataList[jdDataIndx],
                                JdSkills: [],
                                Questions: [],
                            };
                        }
                        state.status = "success";
                        state.error = null;
                        return;
                    }

                    const hasIdInJdSkill = data?.jdSkillsList[data.jdSkillsList.length - 1].hasOwnProperty('id');
                    const hasIdInQn = data?.jdQuestionsList[data.jdQuestionsList.length - 1].hasOwnProperty('id');
                    console.log("hasIdInJdSkill ", hasIdInJdSkill);
                    console.log("hasIdInQn ", hasIdInQn);
                    if (!hasIdInJdSkill)
                        data.jdSkillsList = data.jdSkillsList.slice(0, data.jdSkillsList.length - 1);

                    if (!hasIdInQn)
                        data.jdQuestionsList = data.jdQuestionsList.slice(0, data.jdQuestionsList.length - 1);


                    state.dataList[jdDataIndx] = {
                        ...state.dataList[jdDataIndx],
                        "JdSkills": data.jdSkillsList ? data.jdSkillsList : [],
                        "Questions": data.jdQuestionsList ? data.jdQuestionsList : []
                    };


                    state.SelectedJd = state.dataList[jdDataIndx];
                    console.log("Selected ", state.dataList[jdDataIndx]);
                    state.status = "success";
                    state.error = null;

                } catch (error) {
                    console.log(error);
                    showErrorToast("Jd Detail ", error)
                }
                // console.log("jd Lists ", state.dataList);
            })
            .addCase(GetJdSkillAndQnWithId.rejected, (state, action) => {
                state.status = "error";
                state.error = action.error.message;
            });
    },

});

export const { setJdBasedResult, emptyJdDetails, setSelectedJd, setSavedDataList,
    setJdSkillsInSelectedJd, updateJd, setPaginationStore, setSelectedApplication,
    setSortingStore, setFilterStore, handleSeekerCandidateApiTrigger,
    setAcquiredSkills, setAppliedSkills, setDetailedProfileData, emptySelectedApplication } = SavedSearchSlice.actions;
export default SavedSearchSlice.reducer;



