import { createSlice } from "@reduxjs/toolkit";

const viewDataSlice = createSlice({
  name: "view_data",
  initialState: {
    data: null,
  },
  reducers: {
    setViewData: (state, action) => {
      state.data = action.payload;
    },
    removeViewData: (state, action) => {
      state.data = null;
    },
  },
});

export const { setViewData, removeViewData } = viewDataSlice.actions;
export default viewDataSlice.reducer;
