import { createSlice } from "@reduxjs/toolkit";
import { fetchTrainingHistory } from "../../api/fetchAllData/fetchTrainingHistory";

const initialState = {
  data: [
    {
      mtype: "Certification",
      fromDate: "",
      toDate: "",
      duration: "",
      briefDescriptions: "",
      location: "",
      showHide: "",
      validation: "",
      blockChain: "",
      mlanguage: "",
      certificationName: "",
      organization: "",
      userId: "",
      title: ""
    }
  ],
  status: "idle", // possible values: "idle", "loading", "success", "error"
  error: null,
};


const trainingSlice = createSlice({
  name: "trainingHistory",
  initialState,
  reducers: {
    setTraining: (state, action) => {
      state.data = action.payload;
      state.status = "success";
      state.error = null;
    },
    emptyTraining: (state) => {
      state.data = [];
      state.status = "idle";
      state.error = null;
    },
    addNewTraining: (state, action) => {
      state.data = [action.payload, ...state.data];
      state.status = "success";
      state.error = null;
    },
    TrainingDelete: (state, action) => {
      state.data = state.data.filter((skill) => skill.id !== action.payload);
    },
    logoutTraining: () => initialState,
    trainingUpdate: (state, action) => {
      const { id, validation } = action.payload;
      const skillToUpdate = state.data.find((skill) => skill.id === id);
      if (skillToUpdate) {
        skillToUpdate.validation = validation;
      }
    },
    trainingUpdateRecord: (state, action) => {
      state.data = state.data.map((skill) => {
        if (skill.id === action.payload.id) {

          return {
            ...skill,
            ...action.payload,
          };
        }

        return skill;
      });
      console.log(state.data);
      state.status = "success";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTrainingHistory.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchTrainingHistory.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = "success";
        state.error = null;
      })
      .addCase(fetchTrainingHistory.rejected, (state, action) => {
        state.status = "error";
        state.error = action.error.message;
      });
  },
});

export const {trainingUpdateRecord, setTraining, emptyTraining, addNewTraining, TrainingDelete, logoutTraining, trainingUpdate } = trainingSlice.actions;
export default trainingSlice.reducer;
