import { createSlice } from "@reduxjs/toolkit";
import { showErrorToast } from "../../../components/ToastNotification/showErrorToast.js";
import { fetchCourseApplicationList } from "../../../api/SkillingAgency/fetchCourseApplicationList.js";
// import { getCourseResSkillsAndAnswers } from "../../../api/SkillSeeker/job detail/getCourseResSkillsAndAnswers.js";

const initialState = {
    courseAppList: [],
    courseApplicationLoading: false,
    courseAppDetailsLoading: false,
    noOfResult: 0,
    status: 'idle',
    searchQuery: {
        start: 0,
        size: 15,
        sortOrder: "desc",
        sortField: "createdTime",
        filter: ""
    },
    metaData: {
        totalCount: 0,
        nextStart: 0,
        hasNext: true
    }

};
const courseApplication = createSlice({
    name: "courseApplicationSlice",
    initialState: {
        courseAppList: [],
        courseApplicationLoading: false,
        courseAppDetailsLoading: false,
        noOfResult: 0,
        status: 'idle',

        // 'idle', 'loading', 'succeeded', 'failed'
        error: null,
        searchQuery: {
            start: 0,
            size: 15,
            sortOrder: "desc",
            sortField: "createdTime",
            filter: ""
        },
        metaData: {
            totalCount: 0,
            nextStart: 0,
            hasNext: true
        }

    },
    reducers: {
        setCourseAppList: (state, action) => {


            state.courseAppList = action.payload;

        },

        //   logout reset to initial state
        emptyCourseApplicationDetails: (state) => {
            return initialState;

        },

        resetSearchQuery: (state) => {
            state.searchQuery = {
                start: 0,
                size: 15,
                sortOrder: "desc",
                sortField: "createdTime",
                filter: ""
            }
        },

        setPaginationStore: (state, action) => {
            state.searchQuery.start = action.payload.start;
            state.searchQuery.size = action.payload.size;
        },
        setSortingStore: (state, action) => {
            console.log("state ", action.payload);
            state.searchQuery.sortOrder = action.payload.sortOrder;
            state.searchQuery.sortField = action.payload.sortField;
        },
        setFilterStore: (state, action) => {
            state.searchQuery.filter = action.payload.filter;
        },
        getQueryDetails: (state) => {
            console.log("Query Details Course ", state.searchQuery);
        },
        setAcquiredSkills: (state, action) => {
            const { userId, acquiredSkillData } = action.payload;
            const index = state.courseAppList.findIndex(obj => obj.mystProfile === userId);
            console.log("found Acquired  index ", index);
            // If the object with the given userId is found
            if (index !== -1) {
                console.log("Object with id axquired", acquiredSkillData, " found.");

                if (acquiredSkillData?.length > 0) {
                    acquiredSkillData?.map((datas) => {
                        return {
                            ...datas,
                            validation: datas?.ValidationData?.length > 0 ? "Yes" : "No"
                        }
                    })
                }

                // Create a copy of the object with the additional field added
                state.courseAppList[index] = { ...state.courseAppList[index], skillacq: acquiredSkillData };
                console.log(state.courseAppList);
            } else {
                console.log("Object with id axquired", acquiredSkillData, " not found.");
            }
        },
        setAppliedSkills: (state, action) => {
            const { userId, appliedSkillData } = action.payload;
            const index = state.courseAppList.findIndex(obj => {
                console.log("found bj.mystProfile - userId ", obj.mystProfile, " ", userId);
                return obj.mystProfile === userId
            });
            console.log("found Applie  index ", index);
            // If the object with the given userId is found
            if (index !== -1) {

                if (appliedSkillData?.length > 0) {
                    appliedSkillData?.map((datas) => {
                        return {
                            ...datas,
                            validation: datas?.ValidationData?.length > 0 ? "Yes" : "No"
                        }
                    })
                }

                console.log(appliedSkillData);
                // Create a copy of the object with the additional field added
                state.courseAppList[index] = { ...state.courseAppList[index], skillapp: appliedSkillData };
                console.log("Object with id applied", appliedSkillData, "  found.");
                console.log("indx ", state.courseAppList[index].skillapp);
            } else {
                console.log("Object with id applied", appliedSkillData, " not found.");
            }
        },
        setDetailedProfileData: (state, action) => {
            console.log("action of detailed page ", action.payload);
            const { userID } = action.payload;
            if (userID === null) {
                return;
            }
            const index = state.courseAppList.findIndex(obj => obj.mystProfile === userID);
            console.log("found Detaile  index ", index);
            // If the object with the given userId is found
            if (index !== -1) {
                console.log("All details of user", action.payload, " found.");

                if (action.payload) {
                    state.courseAppList[index] = { ...state.courseAppList[index], detailedProfileData: action.payload };

                }

                // Create a copy of the object with the additional field added

            } else {
                console.log("Object with id axquired", userID, " not found.");
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCourseApplicationList.pending, (state) => {
                state.courseAppList = [];
                console.log("-----------------Course application state clear ------------------ " , state.courseAppList);
                state.status = "loading";
                state.error = null;
                state.courseApplicationLoading = true
            })
            .addCase(fetchCourseApplicationList.fulfilled, (state, action) => {
                try {
                    state.courseAppList = [];
                    let data = action.payload;
                    const length = data.length;
                    state.courseAppList = data.slice(0, length - 1);
                    state.metaData = data[length - 1];
                    state.status = "success";
                    state.error = null;
                    state.courseApplicationLoading = false;
                    console.log("Course App list ", data);
                } catch (error) {
                    console.log(error);
                    state.courseAppList = [];
                    showErrorToast("Course Application list failed to load ", error);
                    state.courseApplicationLoading = false;
                }
            })
            .addCase(fetchCourseApplicationList.rejected, (state, action) => {
                state.courseAppList = [];
                state.status = "error";
                state.courseApplicationLoading = false;
                state.error = action.error.message;
            })
        // .addCase(getCourseResSkillsAndAnswers.pending, (state) => {

        //     state.status = "loading";
        //     state.CourseAppDetailsLoading = true;
        //     state.error = null;
        // })
        // .addCase(getCourseResSkillsAndAnswers.fulfilled, (state, action) => {
        //     try {
        //         let data = action.payload;
        //         console.log("data ", data);
        //         if (data.length === 0) {
        //             return;
        //         }
        //         let CourseDataIndx = state.courseAppList.findIndex(item => item.id === data.id);
        //         const hasIdInJrrSkill = data.jrSkillsList[data.jrSkillsList.length - 1].hasOwnProperty('id');
        //         const hasIdInAns = data.janswersList[data.janswersList.length - 1].hasOwnProperty('id');
        //         console.log("hasIdInCourseSkill ", hasIdInJrrSkill);
        //         console.log("hasIdInQn ", hasIdInAns);
        //         if (!hasIdInJrrSkill)
        //             data.jrSkillsList = data.jrSkillsList.slice(0, data.jrSkillsList.length - 1);

        //         if (!hasIdInAns)
        //             data.janswersList = data.janswersList.slice(0, data.janswersList.length - 1);


        //         state.courseAppList[CourseDataIndx] = { ...state.courseAppList[CourseDataIndx], "availableSkills": data.jrSkillsList ?? [], "janswersList": data.janswersList ?? [] };

        //         console.log("availableSkills ", data.jrSkillsList, " janswersList ", data.janswersList);
        //         //state.SelectedCourse = state.CourseList[CourseDataIndx];
        //         state.status = "success";
        //         state.CourseAppDetailsLoading = false;
        //         state.error = null;

        //     } catch (error) {
        //         console.log(error);
        //         state.CourseAppDetailsLoading = false;
        //         showErrorToast("Application detail failed ", error)
        //     }
        //     // console.log("Course Lists ", state.CourseList);
        // })
        // .addCase(getCourseResSkillsAndAnswers.rejected, (state, action) => {
        //     state.status = "error";
        //     state.error = action.error.message;
        // });
    },

});

export const { setcourseAppList, emptycourseApplicationDetails, setPaginationStore, setSortingStore, setFilterStore, setAcquiredSkills, setAppliedSkills, setDetailedProfileData , resetSearchQuery } = courseApplication.actions;
export default courseApplication.reducer;



