import { createAsyncThunk } from "@reduxjs/toolkit";
import { BASE_URL } from "../../config/Properties";

export const fetchAgencySavedSearch  = createAsyncThunk(
    "courseSearch/savedSearch",
    async (body) => {
        try {
            const token = localStorage.getItem("token");
            const userId = localStorage.getItem("userId");            
            const response = await fetch(`${BASE_URL}/skill/api/v1/skills/Courses/UserCourses?filter=userId%3A${userId}%7CcourseStatus%3A%21ARCHIVE%7CattachmentUrls%3ASearch${body.filter}&start=${body.start}&size=${body.size}&sortField=${body.sortField}&sortOrder=${body.sortOrder}&authToken=${token}`);
            const data = await response.json();
            return data;
        } catch (error) {
            throw error;
        }
    }
);
