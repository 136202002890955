import {  createSlice } from "@reduxjs/toolkit";
import { fetchCertificationHistory } from "../../api/fetchAllData/fetchCertificationHistory";



const initialState = {
  data: [
    {
    mtype:"Certification",
    fromDate:"",
    toDate:"",
    duration:"",
    briefDescriptions:"",
    location:"",
    showHide:"",
    validation:"",
    blockChain:"",
    mlanguage:"",
    certificationName:"",
    organization:"",
    userId:"",
    title:""
  }
  ],
  status: "idle", // possible values: "idle", "loading", "success", "error"
  error: null,
};


const certificationSlice = createSlice({
  name: "CertificationHistory",
  initialState,
  reducers: {
    setCertification: (state, action) => {
      state.data = action.payload;
      state.status = "success";
      state.error = null;
    },
    emptyCertification: (state) => {
      state.data = [];
      state.status = "idle";
      state.error = null;
    },
    addNewCertification: (state, action) => {
      state.data = [action.payload,...state.data ];
      state.status = "success";
      state.error = null;
    },
    CertificationDelete: (state, action) => {
      state.data = state.data.filter((skill) => skill.id !== action.payload);
    },
    logoutCertification: ()=> initialState,
    certificationUpdate: (state, action) => {
      const { id, validation } = action.payload;
      const skillToUpdate = state.data.find((skill) => skill.id === id);
      if (skillToUpdate) {
        skillToUpdate.validation = validation;
      }
    },
    certificationUpdateRecord: (state, action) => {
      state.data = state.data.map((skill) => {
        if (skill.id === action.payload.id) {
          
          return {
            ...skill,
            ...action.payload,
          };
        }
        
        return skill;
      });
      console.log(state.data);
      state.status = "success";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCertificationHistory.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchCertificationHistory.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = "success";
        state.error = null;
      })
      .addCase(fetchCertificationHistory.rejected, (state, action) => {
        state.status = "error";
        state.error = action.error.message;
      });
  },
});

export const {certificationUpdateRecord, setCertification, emptyCertification,addNewCertification,CertificationDelete,logoutCertification, certificationUpdate } = certificationSlice.actions;
export default certificationSlice.reducer;





























// import { createSlice } from "@reduxjs/toolkit";

// const initialState = [
//   {
//     certificationName: "Salesforce Certified Sales Cloud",
//     startDate:"02/05/2012",
//     endDate:  "03/04/2012",
//     duration: "12",
//     briefDescription:"Salesforce offers certifications related to its Sales Cloud platform, which is widely used in sales development and customer relationship management (CRM).",
//     organizationName: "Salesforce",
//     location: "Online",
//     type: "Certification",
//     showHide: true,
//     validation: true,
//     blockChain: true,
//     dateSent: "01/06/2021",
//     validatorName: "James",
//     relationship: "Own Team Member",
//     description: "David Marcos's performance on the lead generation campaign has been exceptional. His dedication and hard work significantly contributed to our ability to identify and qualify leads effectively.    ",
//     validatorEmail: "james@gmail.com",
//     validatorPhone: "9710838457",
//     isValidated:true
    
//   },
//   {
//     certificationName: "Google Ads Certification",
//     startDate: "01/01/2011",
//     endDate: "04/04/2013",
//     duration: "6",
//     briefDescription:"Certification can be valuable for understanding website analytics, tracking user behavior, and optimizing online marketing campaigns, all of which are important in sales development",
//     organizationName: "Goggle",
//     location: "New York City",
//     type: "Certification",
//     showHide: false,
//     validation: true,
//     blockChain: false,
//     dateSent: "03/01/2022",
//     validatorName: "Michael Johnson",
//     relationship: "External Consultant",
//     description:
//       "Michael Johnson's expertise in marketing analytics was instrumental in the successful development of the marketing analytics dashboard. He provided valuable insights and recommendations throughout the Certification.",
//     validatorEmail: "michael@example.com",
//     validatorPhone: "555-123-4567",
//     isValidated:true
    
//   },
// ];

// const certificationSlice = createSlice({
//   name: "certificationHistory",
//   initialState,
//   reducers: {
      
//     setCertification: (state, action) => { 
//       return action.payload;
//     },
//     addNewCertification: (state, action) => { 
     
//       return [...state, action.payload];
//     },
//     emptyCertification: (state) => { 
//       return [];
//     }
      
//   },
// });

// export const { setCertification, emptyCertification , addNewCertification } = certificationSlice.actions;
// export default certificationSlice.reducer;
