
import React, { useMemo } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { Outlet, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import BackToTop from './../../components/BackToTop/BackToTop';
import CheckoutStepper from './../../components/Steppers/Stepper';
import DeleteFormDetailedProfile from './../../components/DeleteFormDetailedProfile';
import SkillSeekerBreadCrumb from './SkillSeekerBreadCrumb';
import { FaAngleRight } from 'react-icons/fa';
import useContentLabel from '../../hooks/useContentLabel';

const CreateOpportunityManagement = ({ children }) => {
    const { pathname } = useLocation()
    const contentLabel = useContentLabel();
    //store
    const content = useSelector(state => state.content);
    const selectedLanguage = useSelector(state => state.language);
    const regionalData = useSelector(state => state.regionalData);

    const steps = [
        {
            name: contentLabel("OpportunityDefinition", "nf Opportunity Definition"),
            number: 1,
            link: '/skillSeeker/Opportunities/Create/Opportunity-Definition',
            Component: () => <div>step1</div>,
        },
        {
            name: contentLabel("OpportunityDescription", "nf Opportunity Description") ,
            number: 2,
            link: '/skillSeeker/Opportunities/Create/Opportunity-Description',
            Component: () => <div>step2</div>,
        },
        {
            name: contentLabel("CandidateRequirement", "nf Candidate Requirement") ,
            number: 3,
            link: '/skillSeeker/Opportunities/Create/Candidate-Requirement',
            Component: () => <div>step3</div>,
        },
        {
            name:  contentLabel("ScreeningQuestion", "nf Screening Question") ,
            number: 4,
            link: '/skillSeeker/Opportunities/Create/Screening-Question',
            Component: () => <div>step4</div>,
        },
        {
            name:  contentLabel("PublishStep", "nf Publish Step") ,
            number: 5,
            link: '/skillSeeker/Opportunities/Create/Opportunity-Preview',
            Component: () => <div>step5</div>,
        },

    ];


    const activePath = useMemo(() => {

        if (pathname === '/profile-management/profile-info') {
            return 'Profile'
        }
        if (pathname === '/profile-management/employment') {
            return 'Employment'
        }
        if (pathname === '/profile-management/work-details') {
            return 'Work Details'
        }
        if (pathname === '/profile-management/learnings') {
            return 'Learning'
        }
        if (pathname === '/profile-management/skills') {
            return 'Skills'
        }
    }, [pathname])



    return (
        <div class="content-body">
            <div class="container-fluid">


                <div className="profile-management-layout">


                    <SkillSeekerBreadCrumb />



                    <Row>
                        <Col sm={12}>
                            <Card className='p-4'>
                                <CheckoutStepper steps={steps} />
                            </Card>
                        </Col>
                    </Row>

                    {children}

                </div>
            </div>
            <BackToTop />
        </div>
    );
};

export default CreateOpportunityManagement;
