import { lazy } from 'react';
import SeekerPersonalDetailLayout from '../../views/profile management/Skill Seeker Module/SeekerPersonalDetailLayout';
import SkillingAgencyBreadcrumbs from '../../views/skilling agency module/SkillingAgencyBreadcrumbs';


const skillAgencyRoutes = [
  {
    exact: 'true',
    path: 'profile-management/profile-info',
    layout: SeekerPersonalDetailLayout,
    element: lazy(() => import('../../views/profile management/Skill Seeker Module/Personal/SeekerPersonalDetails'))
  },
  {
    exact: 'true',
    path: 'profile-management/company',
    layout: SeekerPersonalDetailLayout,
    element: lazy(() => import('../../views/profile management/Skill Seeker Module/Company/SeekerCompanyDetails'))
  },
  {
    exact: 'true',
    path: 'Home',
    element: lazy(() => import('../../pages/SkillAvailer/SkillSeekerHomePage'))
  },
  {
    exact: 'true',
    path: 'Skill-Search/Access-Database',
    layout: SkillingAgencyBreadcrumbs,
    element: lazy(() => import('../../pages/SkillAvailer/SkillSearchDashboard'))
  },
  {
    exact: 'true',
    path: 'Skill-Search/Saved-Searches',
    layout: SkillingAgencyBreadcrumbs,
    element: lazy(() => import('../../pages/SkillingAgencies/AgencySavedSearchDashBoard'))
  },
  // {
  //   exact: 'true',
  //   path: 'skill-search',
  //   layout: SkillingAgencyBreadcrumbs,
  //   element: lazy(() => import('../../pages/SkillAvailer/SkillSearchDashboard'))
  // },
  {
    exact: 'true',
    path: 'my-courses',
    layout: SkillingAgencyBreadcrumbs,
    element: lazy(() => import('../../pages/SkillingAgencies/CourseViewDashboard'))
  },
  {
    exact: 'true',
    path: 'my-courses/create',
    layout: SkillingAgencyBreadcrumbs,
    element: lazy(() => import('../../pages/SkillingAgencies/components/CreateCourse'))
  },
  {
    exact: 'true',
    path: 'my-courses/edit',
    layout: SkillingAgencyBreadcrumbs,
    element: lazy(() => import('../../pages/SkillingAgencies/components/EditCourse'))
  },
  {
    exact: 'true',
    path: 'candidate-management',
    layout: SkillingAgencyBreadcrumbs,
    // element: lazy(() => import('../../pages/SkillAvailer/CandidateManagementDashBoard'))
    element: lazy(() => import('../../pages/SkillingAgencies/CourseCandidateManagementDashboard'))
  },
  // {
  //   exact: 'true',
  //   path: 'JobList',
  //   element: lazy(() => import('../../components/SkillAvailer/OppurtunityLayout')),
  //   children: [
  //     {
  //       path: '',
  //       index: true,
  //       element: lazy(() => import('../../components/SkillAvailer/JobList'))
  //     }
  //   ]
  // }
];

export default skillAgencyRoutes;
