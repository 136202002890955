const menuItems = {
  items: localStorage.getItem("USER_ROLE") === "R2"
    ? [
      {
        id: 'navigation',
        title: 'Analytics',
        type: 'group',
        icon: 'icon-navigation',
        children: [
          {
            id: 'dashboard',
            title: 'Dashboard',
            type: 'item',
            icon: 'feather icon-grid',
            url: '/basic/dashboard'
          }
        ]
      },

      {
        id: 'dashboard',
        title: 'Navigation',
        type: 'group',
        icon: 'feather icon-grid',
        children: [
          {
            id: 'profile-management',
            title: 'Profile Management',
            type: 'collapse',
            icon: 'feather icon-user',
            children: [
              {
                id: 'Personal-details',
                title: 'Personal Details',
                type: 'item',
                url: '/skillSeeker/profile-management/profile-info'
              },
              {
                id: 'Company',
                title: 'Company',
                type: 'item',
                url: '/skillSeeker/profile-management/company'
              }
            ]
          },

          {
            id: 'opportunities',
            title: 'Opportunities',
            type: 'item',
            icon: 'feather feather icon-bar-chart',
            url: '/skillSeeker/Opportunities',

          },
          {
            id: 'skill-search',
            title: 'Skill Search',
            type: 'item',
            icon: 'feather feather icon-bar-chart',
            url: '/skillSeeker/Skill-Search',

          },
        ]
      },
      {
        id: 'consoles',
        title: 'Console',
        type: 'group',
        icon: 'icon-navigation',
        children: [
          {
            id: 'candidate-tracker',
            title: 'Candidate Tracker',
            type: 'item',
            icon: 'feather icon-check-circle',
            url: '/skillSeeker/Candidate-management'
          },
          {
            id: 'attachment-console',
            title: 'Attachment Console',
            type: 'item',
            icon: 'feather icon-paperclip',
            url: '/skillSeeker/analytics'
          },

          {
            id: 'privacy-console',
            title: 'Privacy Console',
            type: 'item',
            icon: 'feather icon-shield',
            url: '/skillSeeker/analytics'
          },
        ]
      },
      {
        id: 'settings',
        title: 'Settings',
        type: 'group',
        icon: 'icon-navigation',
        children:[
          {
            id: 'account-settings',
            title: 'Account Settings',
            type: 'item',
            icon: 'feather icon-settings',
            url: '/settings/account-settings',
            target: false,
            breadcrumbs: false
          }, {
            id: 'reset-password',
            title: 'Reset Password',
            type: 'item',
            icon: 'feather icon-lock',
            url: '/settings/reset-password',
            target: false,
            breadcrumbs: false
          }
        ]
      },
      
      // {
      //   id: 'auth',
      //   title: 'Authentication',
      //   type: 'group',
      //   icon: 'icon-pages',
      //   children: [
      //     {
      //       id: 'sign in',
      //       title: 'Login',
      //       type: 'item',
      //       icon: 'feather icon-lock',
      //       url: '/auth/signin-1',
      //       target: true,
      //       breadcrumbs: false
      //     },
      //     {
      //       id: 'sign Up',
      //       title: 'Register',
      //       type: 'item',
      //       icon: 'feather icon-log-in',
      //       url: '/auth/signup-1',
      //       target: true,
      //       breadcrumbs: false
      //     },
      //     {
      //       id: 'reset-pass',
      //       title: 'Reset Password',
      //       type: 'item',
      //       icon: 'feather icon-unlock',
      //       url: '/auth/reset-password-1',
      //       target: true,
      //       breadcrumbs: false
      //     }
      //   ]
      // },
      // {
      //   id: 'support',
      //   title: 'Support',
      //   type: 'group',
      //   icon: 'icon-support',
      //   children: [
      //     {
      //       id: 'sample-page',
      //       title: 'Chat',
      //       type: 'item',
      //       url: '/basic/sample-page',
      //       classes: 'nav-item',
      //       icon: 'feather icon-sidebar'
      //     }
      //     // ,
      //     // {
      //     //   id: 'documentation',
      //     //   title: 'Documentation',
      //     //   type: 'item',
      //     //   icon: 'feather icon-help-circle',
      //     //   classes: 'nav-item',
      //     //   url: 'https://codedthemes.gitbook.io/gradient-able-react/',
      //     //   target: true,
      //     //   external: true
      //     // }
      //   ]
      // }
    ]
    :
    localStorage.getItem("USER_ROLE") === "R3"
      ?

      [
        {
          id: 'navigation',
          title: 'Analytics',
          type: 'group',
          icon: 'icon-navigation',
          children: [
            {
              id: 'dashboard',
              title: 'Dashboard',
              type: 'item',
              icon: 'feather icon-grid',
              url: '/basic/dashboard'
            }
          ]
        },

        {
          id: 'dashboard',
          title: 'Navigation',
          type: 'group',
          icon: 'feather icon-grid',
          children: [
            {
              id: 'profile-management',
              title: 'Profile Management',
              type: 'collapse',
              icon: 'feather icon-user',
              children: [
                {
                  id: 'Personal-details',
                  title: 'Personal Details',
                  type: 'item',
                  url: '/skillingagency/profile-management/profile-info'
                },
                {
                  id: 'Company',
                  title: 'Skillers',
                  type: 'item',
                  url: '/skillingagency/profile-management/company'
                }
              ]
            },

            {
              id: 'mycourses',
              title: 'My Courses',
              type: 'item',
              icon: 'feather feather icon-bar-chart',
              // icon: <icons.SchoolOutlinedIcon/>,
              url: '/skillingagency/my-courses',

            },
            {
              id: 'skill-search',
              title: 'Skill Search',
              type: 'item',
              icon: 'feather feather icon-bar-chart',
              url: '/skillingagency/skill-search',

            },
          ]
        },
        {
          id: 'consoles',
          title: 'Console',
          type: 'group',
          icon: 'icon-navigation',
          children: [
            {
              id: 'attachment-console',
              title: 'Attachment Console',
              type: 'item',
              icon: 'feather icon-paperclip',
              url: '/console/attachment'
            },
            {
              id: 'validation-console',
              title: 'Validation Console',
              type: 'item',
              icon: 'feather icon-check-circle',
              url: '/console/validations'
            },
            {
              id: 'privacy-console',
              title: 'Privacy Console',
              type: 'item',
              icon: 'feather icon-shield',
              url: '/app/dashboard/analytics'
            },
          ]
        },
        {
          id: 'settings',
          title: 'Settings',
          type: 'group',
          icon: 'icon-navigation',
          children:[
            {
              id: 'account-settings',
              title: 'Account Settings',
              type: 'item',
              icon: 'feather icon-settings',
              url: '/settings/account-settings',
              target: false,
              breadcrumbs: false
            }, {
              id: 'reset-password',
              title: 'Reset Password',
              type: 'item',
              icon: 'feather icon-lock',
              url: '/settings/reset-password',
              target: false,
              breadcrumbs: false
            }
          ]
        },
        // {
        //   id: 'auth',
        //   title: 'Authentication',
        //   type: 'group',
        //   icon: 'icon-pages',
        //   children: [
        //     {
        //       id: 'sign in',
        //       title: 'Login',
        //       type: 'item',
        //       icon: 'feather icon-lock',
        //       url: '/auth/signin-1',
        //       target: true,
        //       breadcrumbs: false
        //     },
        //     {
        //       id: 'sign Up',
        //       title: 'Register',
        //       type: 'item',
        //       icon: 'feather icon-log-in',
        //       url: '/auth/signup-1',
        //       target: true,
        //       breadcrumbs: false
        //     },
        //     {
        //       id: 'reset-pass',
        //       title: 'Reset Password',
        //       type: 'item',
        //       icon: 'feather icon-unlock',
        //       url: '/auth/reset-password-1',
        //       target: true,
        //       breadcrumbs: false
        //     }
        //   ]
        // },
        // {
        //   id: 'support',
        //   title: 'Support',
        //   type: 'group',
        //   icon: 'icon-support',
        //   children: [
        //     {
        //       id: 'sample-page',
        //       title: 'Chat',
        //       type: 'item',
        //       url: '/basic/sample-page',
        //       classes: 'nav-item',
        //       icon: 'feather icon-sidebar'
        //     }
        //   ]
        // }
      ]



      :

      [
        {
          id: 'navigation',
          title: 'Analytics',
          type: 'group',
          icon: 'icon-navigation',
          children: [
            {
              id: 'dashboard',
              title: 'Dashboard',
              type: 'item',
              icon: 'feather icon-grid',
              url: '/basic/dashboard'
            }
          ]
        },
        // {
        //   id: 'utilities',
        //   title: 'Utilities',
        //   type: 'group',
        //   icon: 'icon-ui',
        //   children: [
        //     {
        //       id: 'component',
        //       title: 'Component',
        //       type: 'collapse',
        //       icon: 'feather icon-box',
        //       children: [
        //         {
        //           id: 'button',
        //           title: 'Button',
        //           type: 'item',
        //           url: '/basic/button'
        //         },
        //         {
        //           id: 'badges',
        //           title: 'Badges',
        //           type: 'item',
        //           url: '/basic/badges'
        //         },
        //         {
        //           id: 'breadcrumb-pagination',
        //           title: 'Breadcrumb & Pagination',
        //           type: 'item',
        //           url: '/basic/breadcrumb-pagination'
        //         },
        //         {
        //           id: 'collapse',
        //           title: 'Collapse',
        //           type: 'item',
        //           url: '/basic/collapse'
        //         },
        //         {
        //           id: 'typography',
        //           title: 'Typography',
        //           type: 'item',
        //           url: '/basic/typography'
        //         },
        //         {
        //           id: 'tooltip-popovers',
        //           title: 'Tooltip & Popovers',
        //           type: 'item',
        //           url: '/basic/tooltip-popovers'
        //         }
        //       ]
        //     }
        //   ]
        // },
        {
          id: 'dashboard',
          title: 'Navigation',
          type: 'group',
          icon: 'feather icon-grid',
          children: [
            {
              id: 'profile-management',
              title: 'Profile Management',
              type: 'collapse',
              icon: 'feather icon-user',
              children: [
                {
                  id: 'Profile Info',
                  title: 'Profile Info',
                  type: 'item',
                  url: '/profile-management/profile-info'
                },
                {
                  id: 'Employment',
                  title: 'Employment',
                  type: 'item',
                  url: '/profile-management/employment'
                },
                {
                  id: 'Work Details',
                  title: 'Work Details',
                  type: 'item',
                  url: '/profile-management/work-details'
                },
                {
                  id: 'Learnings',
                  title: 'Learnings',
                  type: 'item',
                  url: '/profile-management/learnings'
                },
                {
                  id: 'Skills',
                  title: 'Skills',
                  type: 'item',
                  url: '/profile-management/skills'
                }
              ]
            },
            {
              id: 'skill-mapping',
              title: 'Skill Mapping',
              type: 'collapse',
              icon: 'feather icon-layers',
              children: [
                {
                  id: 'skills-applied',
                  title: 'Skills Applied',
                  type: 'item',
                  url: '/skill-mapping/skills-applied'
                },
                {
                  id: 'skills-acquired',
                  title: 'Skills Acquired',
                  type: 'item',
                  url: '/skill-mapping/skills-acquired'
                },
              ]
            },
            {
              id: 'reports-&-views',
              title: 'Reports/Views',
              type: 'collapse',
              icon: 'feather icon-bar-chart',
              children: [
                {
                  id: 'skill-profile',
                  title: 'Skill Profile',
                  type: 'item',
                  url: '/basic/button'
                },
                {
                  id: 'detailed-profile',
                  title: 'Detailed Profile',
                  type: 'item',
                  url: '/basic/badges'
                }
              ]
            },
            {
              id: 'opportunities',
              title: 'Opportunities',
              type: 'collapse',
              icon: 'feather icon-star',
              children: [
                {
                  id: 'view-opportunities',
                  title: 'View Opportunities',
                  type: 'item',
                  url: '/opportunities/view-opportunities'
                },
                {
                  id: 'applied-opportunities',
                  title: 'Applied Opportunities',
                  type: 'item',
                  url: '/basic/badges'
                },
                {
                  id: 'saved-opportunities',
                  title: 'Saved Opportunities',
                  type: 'item',
                  url: '/basic/badges'
                }
              ]
            },
          ]
        },
        {
          id: 'consoles',
          title: 'Console',
          type: 'group',
          icon: 'icon-navigation',
          children: [
            {
              id: 'attachment-console',
              title: 'Attachment Console',
              type: 'item',
              icon: 'feather icon-paperclip',
              url: '/console/attachment'
            },
            {
              id: 'validation-console',
              title: 'Validation Console',
              type: 'item',
              icon: 'feather icon-check-circle',
              url: '/console/validations'
            },
            {
              id: 'privacy-console',
              title: 'Privacy Console',
              type: 'item',
              icon: 'feather icon-shield',
              url: '/app/dashboard/analytics'
            },
          ]
        },
        // {
        //   id: 'auth',
        //   title: 'Authentication',
        //   type: 'group',
        //   icon: 'icon-pages',
        //   children: [
        //     {
        //       id: 'sign in',
        //       title: 'Login',
        //       type: 'item',
        //       icon: 'feather icon-lock',
        //       url: '/auth/signin-1',
        //       target: true,
        //       breadcrumbs: false
        //     },
        //     {
        //       id: 'sign Up',
        //       title: 'Register',
        //       type: 'item',
        //       icon: 'feather icon-log-in',
        //       url: '/auth/signup-1',
        //       target: true,
        //       breadcrumbs: false
        //     },
        //     {
        //       id: 'reset-pass',
        //       title: 'Reset Password',
        //       type: 'item',
        //       icon: 'feather icon-unlock',
        //       url: '/auth/reset-password-1',
        //       target: true,
        //       breadcrumbs: false
        //     }
        //   ]
        // },
        // {
        //   id: 'support',
        //   title: 'Support',
        //   type: 'group',
        //   icon: 'icon-support',
        //   children: [
        //     {
        //       id: 'sample-page',
        //       title: 'Chat',
        //       type: 'item',
        //       url: '/basic/sample-page',
        //       classes: 'nav-item',
        //       icon: 'feather icon-sidebar'
        //     }
        //     // ,
        //     // {
        //     //   id: 'documentation',
        //     //   title: 'Documentation',
        //     //   type: 'item',
        //     //   icon: 'feather icon-help-circle',
        //     //   classes: 'nav-item',
        //     //   url: 'https://codedthemes.gitbook.io/gradient-able-react/',
        //     //   target: true,
        //     //   external: true
        //     // }
        //   ]
        // }
        {
          id: 'settings',
          title: 'Settings',
          type: 'group',
          icon: 'icon-navigation',
          children:[
            {
              id: 'account-settings',
              title: 'Account Settings',
              type: 'item',
              icon: 'feather icon-settings',
              url: '/settings/account-settings',
              target: false,
              breadcrumbs: false
            }, {
              id: 'reset-password',
              title: 'Reset Password',
              type: 'item',
              icon: 'feather icon-lock',
              url: '/settings/reset-password',
              target: false,
              breadcrumbs: false
            }
          ]
        },
      ]
};

export default menuItems;
