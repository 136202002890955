import {  createSlice } from "@reduxjs/toolkit";
import { FetchProjectHistory } from "../../api/fetchAllData/FetchProjectHistory";


const initialState = {
  data: [
    {
      certificationName: "",
      startDate: "",
      endDate: "",
      duration: "",
      briefDescription: "",
      organizationName: "",
      location: "",
      type: "",
      showHide: false,
      validation: false,
      blockChain: false,
      dateSent: "",
      validatorName: "",
      relationship: "",
      description: "",
      validatorEmail: "",
      validatorPhone: "",
      isValidated: true,
    },
  ],
  status: "idle", // possible values: "idle", "loading", "success", "error"
  error: null,
};


const projectSlice = createSlice({
  name: "projectHistory",
  initialState,
  reducers: {
    setProject: (state, action) => {
      state.data = action.payload;
      state.status = "success";
      state.error = null;
    },
    emptyProject: (state) => {
      state.data = [];
      state.status = "idle";
      state.error = null;
    },
    addNewProject: (state, action) => {
      state.data = [ action.payload,...state.data];
      state.status = "success";
      state.error = null;
    },
    ProjectDelete: (state, action) => {
      state.data = state.data.filter((skill) => skill.id !== action.payload);
    },
    logoutProject: ()=> initialState,
    projectUpdate: (state, action) => {
      const { id, validation } = action.payload;
      const skillToUpdate = state.data.find((skill) => skill.id === id);
      if (skillToUpdate) {
        skillToUpdate.validation = validation;
      }
    },
    projectUpdateRecord: (state, action) => {
      state.data = state.data.map((skill) => {
        if (skill.id === action.payload.id) {
          
          return {
            ...skill,
            ...action.payload,
          };
        }
        
        return skill;
      });
      console.log(state.data);
      state.status = "success";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(FetchProjectHistory.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(FetchProjectHistory.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = "success";
        state.error = null;
      })
      .addCase(FetchProjectHistory.rejected, (state, action) => {
        state.status = "error";
        state.error = action.error.message;
      });
  },
});

export const { projectUpdateRecord,setProject, emptyProject,addNewProject,ProjectDelete,logoutProject, projectUpdate } = projectSlice.actions;
export default projectSlice.reducer;
