import React from 'react';
import { useSelector } from 'react-redux';

const SecondaryBtnLoader = ({ label, onClick, backgroundColor, color, loading, disable, Active = false }) => {
    const selectedLanguage = useSelector(state => state.language);
    const content = useSelector(state => state.content);
    const buttonStyle = {
        backgroundColor: (content[selectedLanguage].find(item => item.elementLabel === 'SecondaryButtonOffBgColor') || {}).mvalue || "#000",
        cursor: loading ? "not-allowed" : "pointer",
        color: (content[selectedLanguage].find(item => item.elementLabel === 'SecondaryButtonOffFontColor') || {}).mvalue || "#FFEA00",
        borderStyle: "solid",
        borderColor: loading ? "#ccc" : (content[selectedLanguage].find(item => item.elementLabel === 'SecondaryButtonOffFontColor') || {}).mvalue || "#FFEA00",
        opacity: loading || disable ? 0.7 : 1,
        fontSize: (content[selectedLanguage].find(item => item.elementLabel === 'SecondaryButtonFontSize') || {}).mvalue || '12px',
        fontWeight: 'normal'
    };
    const buttonStyleActive = {
        backgroundColor: (content[selectedLanguage].find(item => item.elementLabel === 'SecondaryButtonOnBgColor') || {}).mvalue || "#FFEA00",
        cursor: loading ? "not-allowed" : "pointer",
        color: (content[selectedLanguage].find(item => item.elementLabel === 'SecondaryButtonOnFontColor') || {}).mvalue || "#000",
        borderStyle: "solid",
        borderColor: loading ? "#ccc" : (content[selectedLanguage].find(item => item.elementLabel === 'SecondaryButtonOnFontColor') || {}).mvalue || "#000",
        opacity: loading || disable ? 0.7 : 1,
        fontSize: (content[selectedLanguage].find(item => item.elementLabel === 'SecondaryButtonFontSize') || {}).mvalue || '12px',
        fontWeight: 'normal'
    };

    return (
        <button className={`btn d-flex justify-content-center align-items-center  py-2 px-2 gap-1 badge border-1 ${loading || disable ? 'disabled' : ''}`} style={Active === false ? buttonStyle : buttonStyleActive} onClick={!loading ? onClick : undefined}>

            {
                loading &&
                <div class="spinner-border " style={{ width: '15px', height: '15px', color: (Active === false) ?  (content[selectedLanguage].find(item => item.elementLabel === 'SecondaryButtonOnBgColor') || {}).mvalue || "#FFEA00"  : (content[selectedLanguage].find(item => item.elementLabel === 'SecondaryButtonOnFontColor') || {}).mvalue || "#000"    }} role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            }

            <span>
                {label}
            </span>

        </button>
    );
};

export default SecondaryBtnLoader;
