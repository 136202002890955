import React from "react";
import { Col } from "react-bootstrap";

const BriefDescriptionTextArea = ({
  limit = 1000,
  label,
  htmlFor,
  isRequired,
  textareaClass,
  id,
  name,
  onChange,
  value,
  isBold = false,
}) => {
  return (
    <React.Fragment>
      <label htmlFor={htmlFor} className={`form-label ${isBold && " fw-bold"}`}>
        {label}
        {isRequired && <span className="text-danger">*</span>}
      </label>
      <textarea
        className={`form-control ${textareaClass}`}
        id={id}
        rows="3"
        name={name}
        onChange={onChange}
        value={value}
      ></textarea>
      <Col className="text-secondary text-muted text-end w-100">
        {limit - value?.length}
      </Col>
    </React.Fragment>
  );
};

export default BriefDescriptionTextArea;
