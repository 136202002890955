import { createSlice } from '@reduxjs/toolkit';

const initialState ={
  id: '',
  mlanguage: '',
  userSkill: '',
  yoe: '',
  userRank: 1,
  skillOccupation: '',
}

const SkillSelectedSlice = createSlice({
  name: 'SkillSelected',
  initialState,
  reducers: {
    setMySkill: (state, action) => {
      return { ...action.payload };
    },
    resetMySkill: (state) => {
      return { ...initialState };
    },
    editYoe: (state, action) => {
      state.yoe = action.payload;
    },
    editAppliedExp: (state, action) => {
      state.skillAppliedExp = action.payload;
    },
    editAcquiredExp: (state, action) => {
      state.skillAcquiredExp = action.payload;
    },
    innitialSkillSelect: (state, action) => {
      console.log('default selct',action.payload);
      state = action.payload;
    },
    logoutSkillSelected: ()=> initialState
  },
});

// Export the reducer and actions
export const { setMySkill,resetMySkill,editYoe,logoutSkillSelected , innitialSkillSelect , editAppliedExp,editAcquiredExp } = SkillSelectedSlice.actions;
export default SkillSelectedSlice.reducer;
