import { createSlice } from "@reduxjs/toolkit";
import { fetchDataSkillsAcquired } from "../../api/fetchAllData/fetchDataSkillsAcquired";

const initialState = {
  data: [
    {
      id: "",
      title: "",
      fromDate: "",
      toDate: "",
      duration: "",
      briefDescription: "",
      organization: "",
      location: "",
      type: "",
      showHide: true,
      validation: true,
      blockChain: true,
      dateSent: "",
      validatorName: "",
      relationship: "",
      description: "",
      validatorEmail: "",
      validatorPhone: "",
      isValidated: true,
      source:""
    },
  ],
  status: "idle", // possible values: "idle", "loading", "success", "error"
  error: null,
};

const SkillsAcquiredSlice = createSlice({
  name: "SkillsAcquired",
  initialState,
  reducers: {
      
    setSkillsAcquired: (state, action) => { 
      state.data = action.payload;
      state.status = "success";
      state.error = null;
    },
    skillAcquiredDelete: (state, action) => {
      state.data = state.data.filter((skill) => skill.id !== action.payload);
    },
    addNewSkillAcquired: (state, action) => {
      state.data = [action.payload,...state.data];
      state.status = "success";
      state.error = null;
    },
    emptySkillsAcquired: (state) => {
      state.data = [];
      state.status = "idle";
      state.error = null;
    },
    editSkillAcquired: (state, action) => { 
      state.data = state.data.map((skill) => {
        console.log("state id ", skill.id);
        if (skill.id === action.payload.id) {
          return {
            ...skill,
            ...action.payload,
          };
        }
        return skill;
      });
      state.status = "success";
      state.error = null;
    },
    logoutSkillsAcquired: ()=> initialState,
    skillAcquiredUpdate: (state, action) => {
      const { id, validation } = action.payload;
      const skillToUpdate = state.data.find((skill) => skill.id === id);
      if (skillToUpdate) {
        skillToUpdate.validation = validation;
      }
    },

  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDataSkillsAcquired.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchDataSkillsAcquired.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = "success";
        state.error = null;
      })
      .addCase(fetchDataSkillsAcquired.rejected, (state, action) => {
        state.status = "error";
        state.error = action.error.message;
      });
  },
});

export const { setSkillsAcquired, emptySkillsAcquired,addNewSkillAcquired,skillAcquiredDelete,logoutSkillsAcquired,editSkillAcquired, skillAcquiredUpdate } = SkillsAcquiredSlice.actions;
export default SkillsAcquiredSlice.reducer;
