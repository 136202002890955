import React, { useState } from 'react'
import LanguageComponent from '../LanguageComponent'

const Flag = () => {


    const [Country, setCountry] = useState({ countryCode: localStorage.getItem('countryCode') });


    return (
        <div class="   px-0 d-none d-lg-block   ">

            <div class="btn border-0 p-0 m-0  mb-2  " data-bs-auto-close="outside">
                <div>

                    <div className='font-10px p-0 m-0 d-flex flex-column justify-content-center  ' >

                        <img className='m-0 p-0 align-self-center me-1' style={{ width: "35px", height: "37px" }} src={`https://flagsapi.com/${Country.countryCode}/flat/32.png`} alt="" />
                    </div>
                </div>
            </div>

        </div>

    )
}

export default Flag