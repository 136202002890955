import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { FetchJdList } from "../../../api/SkillSeeker/FetchJdList.js";
import { GetJdSkillAndQnWithId } from "../../../api/SkillSeeker/job detail/GetJdSkillAndQnWithId.js";
import { showErrorToast } from "../../../components/ToastNotification/showErrorToast.js";
import { useDispatch } from 'react-redux';
import store from "../../../Store.js";


const initialState = {
    rightColW: "0%",
    leftColW: "99%",
    viewMode: "single"

};
const JdViewConfigSlice = createSlice({
    name: "JdViewConfigSlice",
    initialState: {
        rightColW: "0%",
        leftColW: "99%",
        viewMode: "single"


    },
    reducers: {
        setRightColW: (state, action) => {

            console.log("state ", action.payload);
            state.rightColW = action.payload;

        },
        setLeftColW: (state, action) => {


            state.leftColW = action.payload;

        },
        setViewMode: (state, action) => {


            state.viewMode = action.payload;

        },

        changeViewToSingle: (state, action) => {
            state.rightColW = "0%";
            state.leftColW = "100%";
            state.viewMode = "single";
        },
        changeViewToSplit: (state, action) => {
            state.rightColW = "55%";
            state.leftColW = "44%";
            state.viewMode = "split";
        }


    }


});

export const { setRightColW, setLeftColW, setViewMode, changeViewToSingle, changeViewToSplit } = JdViewConfigSlice.actions;
export default JdViewConfigSlice.reducer;



