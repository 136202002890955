import axios from "axios";
import { BASE_URL } from "../../../config/Properties";

const getHeaders = () => {
  const token = localStorage.getItem("token");
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
};

export const MakeAttachmentRelationPostApi = async (body) => {
  try {
    const res = await axios.post(
      `${BASE_URL}/skill/api/v1/skills/create/Attachment Map`,
      body,
      getHeaders()
    );
    return res;
  } catch (error) {
    throw error;
  }
};

// export const getAttachmentsRelationData = async () => {
//   try {
//     const res = await axios.get(
//       `${BASE_URL}/skill/api/v1/skills/get-all-user-data-by-userId/Attachment Map/${localStorage.getItem(
//         "userId"
//       )}?authToken=${localStorage.getItem("token")}`
//     );
//     return res;
//   } catch (error) {
//     throw error;
//   }
// };

/* GET LINKED ATTACHMENT */
export const getLinkedAttachment = async (userId, fileId) => {
  const filter = `userId:${userId}*|fileId:${fileId}`;
  try {
    const res = await axios.get(
      `${BASE_URL}/skill/api/v1/skills/dynamics/Attachment Map?filter=${encodeURIComponent(
        filter
      )}&authToken=${localStorage.getItem("token")}`
    );
    return res;
  } catch (error) {
    throw error;
  }
};

/* GET LINKED ATTACHMENT DETAIL */
export const getLinkedAttachmentDetail = async (userId, fileId) => {
  try {
    const res = await axios.get(
      `${BASE_URL}/skill/api/v1/skills/linkedAtachment/${userId}/${fileId}?authToken=${localStorage.getItem(
        "token"
      )}`
    );
    return res;
  } catch (error) {
    throw error;
  }
};
