const skillOwnerMenuItems = {
  items:
  [
    {
        "customId": "analytics",
        "title": "Analytics",
        "type": "group",
        "orderNum": 1,
        "hide": "FALSE",
        "description": "r1",
        "children": [
            {
                "customId": "dashboard",
                "parentId": "analytics",
                "title": "Dashboard",
                "type": "item",
                "url": "/owner/dashboard",
                "icon": "feather icon-grid",
                "orderNum": 1,
                "hide": "FALSE",
                "description": "r1",
                "children": []
            }
        ]
    },
    {
        "customId": "navigation",
        "title": "Navigation",
        "type": "group",
        "orderNum": 2,
        "hide": "FALSE",
        "description": "r1",
        "children": [
            {
                "customId": "profileManagement",
                "parentId": "navigation",
                "title": "Profile Management",
                "type": "collapse",
                "icon": "feather icon-user",
                "orderNum": 1,
                "hide": "FALSE",
                "description": "r1",
                "children": [
                    {
                        "customId": "profileInfo",
                        "parentId": "profileManagement",
                        "title": "Profile Info",
                        "type": "item",
                        "url": "/owner/profile-management/profile-info",
                        "orderNum": 1,
                        "hide": "FALSE",
                        "description": "r1",
                        "children": []
                    },
                    {
                        "customId": "employment",
                        "parentId": "profileManagement",
                        "title": "Employment",
                        "type": "item",
                        "url": "/owner/profile-management/employment",
                        "orderNum": 2,
                        "hide": "FALSE",
                        "description": "r1",
                        "children": []
                    },
                    {
                        "customId": "workDetails",
                        "parentId": "profileManagement",
                        "title": "Work Details",
                        "type": "item",
                        "url": "/owner/profile-management/work-details",
                        "orderNum": 3,
                        "hide": "FALSE",
                        "description": "r1",
                        "children": []
                    },
                    {
                        "customId": "learnings",
                        "parentId": "profileManagement",
                        "title": "Learnings",
                        "type": "item",
                        "url": "/owner/profile-management/learnings",
                        "orderNum": 4,
                        "hide": "FALSE",
                        "description": "r1",
                        "children": []
                    },
                    {
                        "customId": "skills",
                        "parentId": "profileManagement",
                        "title": "Skills",
                        "type": "item",
                        "url": "/owner/profile-management/skills",
                        "orderNum": 5,
                        "hide": "FALSE",
                        "description": "r1",
                        "children": []
                    }
                ]
            },
            {
                "customId": "skilling",
                "parentId": "navigation",
                "title": "Skilling",
                "type": "collapse",
                "icon": "feather icon-award",
                "orderNum": 1,
                "hide": "FALSE",
                "description": "r1",
                "children": [
                    {
                        "customId": "viewCourses",
                        "parentId": "skilling",
                        "title": "View Courses",
                        "type": "item",
                        "url": "/owner/up-skilling/view-courses",
                        "orderNum": 1,
                        "hide": "FALSE",
                        "description": "r1",
                        "children": []
                    }
                ]
            },
            {
                "customId": "skillMapping",
                "parentId": "navigation",
                "title": "Skill Mapping",
                "type": "collapse",
                "icon": "feather icon-layers",
                "orderNum": 2,
                "hide": "FALSE",
                "description": "r1",
                "children": [
                    {
                        "customId": "skillsAcquired",
                        "parentId": "skillMapping",
                        "title": "Skills Acquired",
                        "type": "item",
                        "url": "/owner/skill-mapping/skills-acquired",
                        "orderNum": 1,
                        "hide": "FALSE",
                        "description": "r1",
                        "children": []
                    },
                    {
                        "customId": "skillsApplied",
                        "parentId": "skillMapping",
                        "title": "Skills Applied",
                        "type": "item",
                        "url": "/owner/skill-mapping/skills-applied",
                        "orderNum": 2,
                        "hide": "FALSE",
                        "description": "r1",
                        "children": []
                    }
                ]
            },
            {
                "customId": "reportsAndViews",
                "parentId": "navigation",
                "title": "Reports/Views",
                "type": "collapse",
                "icon": "feather icon-bar-chart",
                "orderNum": 3,
                "hide": "FALSE",
                "description": "r1",
                "children": [
                    {
                        "customId": "mystProfile",
                        "parentId": "reportsAndViews",
                        "title": "MyST Profile",
                        "type": "item",
                        "url": "/owner/reports/skill-profile",
                        "orderNum": 1,
                        "hide": "FALSE",
                        "description": "r1",
                        "children": []
                    },
                    {
                        "customId": "mySkillsTreeProfile",
                        "parentId": "reportsAndViews",
                        "title": "MySkillsTree Profile",
                        "type": "item",
                        "url": "/owner/reports/detailed-profile",
                        "orderNum": 2,
                        "hide": "FALSE",
                        "description": "r1",
                        "children": []
                    },
                    {
                        "customId": "timeLine",
                        "parentId": "reportsAndViews",
                        "title": "Timeline",
                        "type": "item",
                        "url": "/owner/reports/timeline",
                        "orderNum": 3,
                        "hide": "FALSE",
                        "description": "r1",
                        "children": []
                    }
                ]
            },
            {
                "customId": "opportunities",
                "parentId": "navigation",
                "title": "Opportunities",
                "type": "collapse",
                "icon": "feather icon-search",
                "orderNum": 4,
                "hide": "FALSE",
                "description": "r1",
                "children": [
                    {
                        "customId": "viewOpportunities",
                        "parentId": "opportunities",
                        "title": "View Opportunities",
                        "type": "item",
                        "url": "/owner/opportunities/view-opportunities",
                        "orderNum": 1,
                        "hide": "FALSE",
                        "description": "r1",
                        "children": []
                    }
                ]
            }
        ]
    },
    {
        "customId": "console",
        "title": "Console",
        "type": "group",
        "orderNum": 3,
        "hide": "FALSE",
        "description": "r1",
        "children": [
            {
                "customId": "attachmentConsole",
                "parentId": "console",
                "title": "Attachment Console",
                "type": "item",
                "url": "/console/attachment",
                "icon": "feather icon-paperclip",
                "orderNum": 1,
                "hide": "FALSE",
                "description": "r1",
                "children": []
            },
            {
                "customId": "validationConsole",
                "parentId": "console",
                "title": "Validation Console",
                "type": "item",
                "url": "/console/validations",
                "icon": "feather icon-check-circle",
                "orderNum": 2,
                "hide": "FALSE",
                "description": "r1",
                "children": []
            }
        ]
    },
    {
        "customId": "settings",
        "title": "Settings",
        "type": "group",
        "orderNum": 4,
        "hide": "FALSE",
        "description": "r1",
        "children": [
            {
                "customId": "supportService",
                "parentId": "settings",
                "title": "Support Service",
                "type": "item",
                "url": "/owner/support-service",
                "icon": "feather icon-user-plus",
                "orderNum": 1,
                "hide": "FALSE",
                "description": "r1",
                "children": []
            },
            {
                id: 'independent-assessments',
                title: 'Independent Assessments',
                type: 'item',
                icon: 'feather icon-activity',
                url: '/owner/independent-assessments',
                target: false,
                breadcrumbs: false
              },
            {
                "customId": "accountSettings",
                "parentId": "settings",
                "title": "Account Settings",
                "type": "item",
                "url": "/owner/settings/account-settings",
                "icon": "feather icon-settings",
                "orderNum": 2,
                "hide": "FALSE",
                "description": "r1",
                "children": []
            }
        ]
    }
]
};

export default skillOwnerMenuItems;