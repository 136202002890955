import { lazy } from 'react';
import CreateOpportunityManagement from '../../views/Skill Profile Management/CreateOpportunityManagement';

const CreateOpportunityRoutes = [
    {
        exact: 'true',
        path: 'Opportunity-Definition',
        layout: CreateOpportunityManagement,
        element: lazy(() => import('../../components/SkillAvailer/Opportunity Creation/Create Opportunity steps/OpportunityDefinition'))
    },
    {
        exact: 'true',
        path: 'Opportunity-Description',
        layout: CreateOpportunityManagement,
        element: lazy(() => import('../../components/SkillAvailer/Opportunity Creation/Create Opportunity steps/OpportunityDescription'))
    },
    {
        exact: 'true',
        path: 'Candidate-Requirement',
        layout: CreateOpportunityManagement,
        element: lazy(() => import('../../components/SkillAvailer/Opportunity Creation/Create Opportunity steps/CandidateProfileStep'))
    },
    {
        exact: 'true',
        path: 'Screening-Question',
        layout: CreateOpportunityManagement,
        element: lazy(() => import('../../components/SkillAvailer/Opportunity Creation/Create Opportunity steps/ScreeningQuestion'))
    },
    {
        exact: 'true',
        path: 'Opportunity-Preview',
        layout: CreateOpportunityManagement,
        element: lazy(() => import('../../components/SkillAvailer/Opportunity Creation/Create Opportunity steps/OpportunityPreviewStep'))
    },
   

];

export default CreateOpportunityRoutes;
