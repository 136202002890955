import React from "react";
import { Button, Container } from "react-bootstrap";

const SomethingWentWrong = () => {
  /* REFRESH */
  const handleRefresh = () => {
    window.location.reload();
  };
  return (
    <Container className="text-center my-5">
      <h2 className="text-primary">Something Went Wrong...</h2>
      <p className="text-secondary">Please try refreshing the page.</p>
      <Button variant="primary" onClick={handleRefresh} className="mt-3">
        Refresh
      </Button>
    </Container>
  );
};

export default SomethingWentWrong;
