import logoFull from '../assets/images/logo-full.png';
import logo from '../assets/images/logo.png';
import user from "../Images/avatar-placeholder.webp";
import company_image from "../Images/skyline.png";
import pdf_image from '../assets/images/pdf-svg.svg'
import logoFullRtl from "../assets/images/logoRTL.png";
import img from "../assets/images/img-box.svg";

const images = {
    logoFull,
    logo,
    user,
    company_image,
    pdf_image,
    logoFullRtl,
    img
}

export default images;