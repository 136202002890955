import { useSelector } from "react-redux";

const useContentLabel = () => {
  /* STORE IMPORTS */
  const selectedLanguage = useSelector((state) => state?.language);
  const content = useSelector((state) => state?.content);

  return (elementLabel, defaultValue) => {
    const label = content[selectedLanguage]?.find(
      (val) => val?.elementLabel === elementLabel
    );
    return label?.mvalue || defaultValue;
  };
};

export default useContentLabel;
