import { createSlice } from "@reduxjs/toolkit";


const initialState = {

  data: []

}
;


const skillOwnerAttachmentSlice = createSlice({
  name: "skillOwnerAttachment",
  initialState,
  reducers: {
    setSkillOwnerAttachment: (state, action) => {
      console.log(action.payload);
      state.data = action.payload;
      console.log(state.data);
    },
    deleteTheState:(state)=>{
      state.data = {};
    }



  }

});

export const { setSkillOwnerAttachment,deleteTheState } =
  skillOwnerAttachmentSlice.actions;
export default skillOwnerAttachmentSlice.reducer;
