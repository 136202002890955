import React from "react";
import { Button } from "react-bootstrap";
import useContentLabel from "../../../hooks/useContentLabel";

const AddButton = ({ disabled, onClick }) => {
  /* LABEL CONTENT */
  const contentLabel = useContentLabel();
  return (
    <Button variant="primary" disabled={disabled} onClick={onClick}>
      {contentLabel("Add", "nf Add")}
    </Button>
  );
};

export default AddButton;
