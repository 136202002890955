import { createAsyncThunk } from "@reduxjs/toolkit";
import { BASE_URL } from "../../config/Properties";

export const FetchSavedSearches = createAsyncThunk(
    "FetchSavedSearches/fetchData",
    async (body) => {
        try {
            const token = localStorage.getItem("token");
            const userId = localStorage.getItem("userId");

            const response = await fetch(`${BASE_URL}/skill/api/v1/skills/dynamic/JDS?filter=userId%3A${userId}%7CjdsType%3ASEARCH*${body.filter}&start=${body.start}&size=${body.size}&sortField=${body.sortField}&sortOrder=${body.sortOrder}&authToken=${token}`);
            //dynamics/JQuestions?filter=%20jid%3AJDS-3367912&start=0&size=25&sortOrder=desc&authToken=c28a4b254192a6e37147ee8533afa6ca
            const data = await response.json();
            return data;
        } catch (error) {
            throw error;
        }
    }
);
