import React, { useMemo } from "react";
import { useLocation } from "react-router-dom";
import useContentLabel from "../../hooks/useContentLabel";
import BackToTop from "../../components/BackToTop/BackToTop";

const ReportsAndViewsLayout = ({ children }) => {
  /* LOCATION */
  const { pathname } = useLocation();

  /* LABEL HOOK */
  const contentLabel = useContentLabel();

  const activePath = useMemo(() => {
    switch (pathname) {
      case "/owner/reports/skill-profile":
        return contentLabel("MySTSkillProfile", "nf MyST Skill Profile");
      case "/owner/reports/detailed-profile":
        return contentLabel("MySkillsTreeProfile", "nf MySkills Tree Profile");
      case "/owner/reports/timeline":
        return contentLabel("Timeline", "nf Timeline");
      default:
        return "";
    }
  }, [contentLabel, pathname]);
  return (
    <div className="content-body">
      <div className="container-fluid">
        <div className="profile-management-layout">
          <div
            className="row page-titles card d-print-none"
            style={{ backgroundColor: "#fff" }}
          >
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                {contentLabel("Reports", "nf Reports")}
              </li>
              <li className="breadcrumb-item active">{activePath}</li>
            </ol>
          </div>
          {children}
        </div>
      </div>
      <BackToTop />
    </div>
  );
};

export default ReportsAndViewsLayout;
