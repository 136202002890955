import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../config/Properties";

// Fetch Menu Item R1
export const fetchMenuItemR1 = createAsyncThunk(
  "menuItems/fetchR1",
  async () => {
    try {
      const res = await axios.get(`${BASE_URL}/skill/api/v1/menuItems/get/R1`);
      return res?.data;
    } catch (error) {
      throw error;
    }
  }
);

// Fetch Menu Item R2
export const fetchMenuItemR2 = createAsyncThunk(
  "menuItems/fetchR2",
  async () => {
    try {
      const res = await axios.get(`${BASE_URL}/skill/api/v1/menuItems/get/R2`);
      return res?.data;
    } catch (error) {
      throw error;
    }
  }
);

// Fetch Menu Item R3
export const fetchMenuItemR3 = createAsyncThunk(
  "menuItems/fetchR3",
  async () => {
    try {
      const res = await axios.get(`${BASE_URL}/skill/api/v1/menuItems/get/R3`);
      return res?.data;
    } catch (error) {
      throw error;
    }
  }
);

// Single slice to handle multiple roles' menu items
const menuItemsSlice = createSlice({
  name: "menuItems",
  initialState: {
    statusR1: "idle",
    statusR2: "idle",
    statusR3: "idle",
    errorR1: null,
    errorR2: null,
    errorR3: null,
    dataR1: [],
    dataR2: [],
    dataR3: [],
  },
  extraReducers: (builder) => {
    // Handle R1 menu items
    builder
      .addCase(fetchMenuItemR1.pending, (state) => {
        state.statusR1 = "loading";
      })
      .addCase(fetchMenuItemR1.fulfilled, (state, action) => {
        state.statusR1 = "succeeded";
        state.dataR1 = action.payload;
      })
      .addCase(fetchMenuItemR1.rejected, (state, action) => {
        state.statusR1 = "failed";
        state.errorR1 = action.error.message;
      });

    // Handle R2 menu items
    builder
      .addCase(fetchMenuItemR2.pending, (state) => {
        state.statusR2 = "loading";
      })
      .addCase(fetchMenuItemR2.fulfilled, (state, action) => {
        state.statusR2 = "succeeded";
        state.dataR2 = action.payload;
      })
      .addCase(fetchMenuItemR2.rejected, (state, action) => {
        state.statusR2 = "failed";
        state.errorR2 = action.error.message;
      });

    // Handle R3 menu items
    builder
      .addCase(fetchMenuItemR3.pending, (state) => {
        state.statusR3 = "loading";
      })
      .addCase(fetchMenuItemR3.fulfilled, (state, action) => {
        state.statusR3 = "succeeded";
        state.dataR3 = action.payload;
      })
      .addCase(fetchMenuItemR3.rejected, (state, action) => {
        state.statusR3 = "failed";
        state.errorR3 = action.error.message;
      });
  },
});

export default menuItemsSlice.reducer;
