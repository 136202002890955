import { createSlice } from "@reduxjs/toolkit"

const skillsAcquiredEditSlice = createSlice({
    name: "skillsAcquiredEdit",
    initialState: null,
    reducers: {
        setSkillsAcquiredEditData: (state, action) => action.payload
    }
});

export const { setSkillsAcquiredEditData } = skillsAcquiredEditSlice.actions;
export default skillsAcquiredEditSlice.reducer;