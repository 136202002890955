import axios from "axios";
import { BASE_URL } from "../../config/Properties";

const DeleteApi = async (form, id) => {


  return axios
    .delete(`${BASE_URL}/skill/api/v1/skills/remove/${form}/${id}?authToken=${localStorage.getItem("token")}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export default DeleteApi;
