

import { createSlice } from "@reduxjs/toolkit";
import { myCourses } from "../../../../../pages/SkillingAgency/SkillingAgencyConstants";
import { myCoursesApi } from "../../../services/myCourses";




const myCourseSlice = createSlice({
  name: "myCourse",
  initialState: {
    value: myCourses
  },
  reducers: {
    addMyCourse: (state, action) => {      
        state.value = [action.payload, ...state.value]
    },
    editMyCourse: (state, action) => {      
        state.value = 
        state.value.map((course) => {
          if (course.id === action.payload.id) {
              // Replace the INDEX object with the updated one
              return action.payload;
          }
          // Keep other objects unchanged
          return course;
      });
    },
  },
  // extraReducers: (builder) => {
  //   builder.addMatcher(myCoursesApi.endpoints.getMyCourses.matchFulfilled, (state, action) => {
  //     // pretend this field and this payload data exist for sake of example
  //     state.value = action.payload;
  //   })
  // }
})

// Action creators are generated for each case reducer function
export const { addMyCourse,editMyCourse } = myCourseSlice.actions
export default myCourseSlice.reducer