import React, { useCallback, useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  DeleteAttachment,
  GetAttachment,
} from "../../../../api/Attachment  API/DownloadAttachmentApi";
import UserDetailsApi from "../../../../api/auth/UserDetailsApi";
import EditApi from "../../../../api/editData/EditApi";
import PostApi from "../../../../api/PostData/PostApi";
import LazyLoadingImageComponent from "../../../../components/Lazy Loading Images/LazyLoadingImageComponent";
import { formatTimestampToDate } from "../../../../components/SkillOwner/HelperFunction/FormatTimestampToDate";
import { showErrorToast } from "../../../../components/ToastNotification/showErrorToast";
import { showSuccessToast } from "../../../../components/ToastNotification/showSuccessToast";
import { icons, images } from "../../../../constants";
import useContentLabel from "../../../../hooks/useContentLabel";
import { fetchUserAttachment } from "../../../../reducer/attachments/getUserAttachmentSlice";

import { Skeleton } from "@mui/material";
import Avatar from "react-avatar-edit";
import { MakeAttachmentRelationPostApi } from "../../../../api/Attachment  API/Attachment Console/MakeRelationApi";
import AttachmentPost from "../../../../api/Attachment  API/AttachmentPost";
import DeleteApi from "../../../../api/DeleteData/DeleteApi";
import { GetCompanyListByUserId } from "../../../../api/SkillSeeker/GetCompanyListByUserId";
import Gallery from "../../../../components/molecules/Gallery/Gallery";
import {
  editExistingUserProfile,
  listaddNewUserProfile,
  setUserInfo,
} from "../../../../reducer/userDetails/UserProfileSlice";

const PersonalDetailSummary = () => {
  /* CONTENT LABEL */
  const contentLabel = useContentLabel();

  /* USER ID */
  const userId = localStorage.getItem("userId");
  /* USER ROLE */
  const role_ = localStorage.getItem("USER_ROLE");

  /* DISPATCH INIT */
  const dispatch = useDispatch();

  /* STORES */
  const userDetails = useSelector((state) => state.userProfile.data);
  const userInfo = useSelector(
    (state) => state.userProfile.accountDetails.userInfo
  );
  const { status: userInfoLoading } = useSelector(
    (state) => state.userProfile.status
  );
  const {
    getUserAttachment: { userAttachmentData },
  } = useSelector((state) => state);
  const selectedLanguage = useSelector((state) => state.language);
  const regionalData = useSelector((state) => state.regionalData);
  const { CompanyList } = useSelector((state) => state.UserCompanyDataSlice);

  /* STATES */
  const [loading, setLoading] = useState(false);
  const [previewDisplay, setPreviewDisplay] = useState({
    preview: null,
    src: "https://bootdey.com/img/Content/avatar/avatar7.png",
  });
  const [location, setLocation] = useState([]);
  const [iniProfilePicture, setIniProfilePicture] = useState(null);
  const [profilePicture, setProfilePicture] = useState(null);
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [profilePicName, setProfilePicName] = useState(null);
  const [picReloader, setPicReloader] = useState(false);
  const [profilePicObj, setProfilePicObj] = useState({});
  const [isNewLangProfile, setIsNewLangProfile] = useState(false);
  const [displayPicture, setDisplayPicture] = useState({});
  const [selectedImage, setSelectedImage] = useState(null);
  const [openGallery, setOpenGallery] = useState(false);
  const [editProfilePic, setEditProfilePic] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(undefined);
  const [selectedCity, setSelectedCity] = useState(undefined);
  const [imgLoadingState, setImgLoadingState] = useState(true);

  /* DISPATCH THE USER INFO */
  const setuserInfo = useCallback(
    (newUserInfo) => {
      dispatch(setUserInfo(newUserInfo));
    },
    [dispatch]
  );

  useEffect(() => {
    const fetchCompanyList = () => {
      dispatch(GetCompanyListByUserId())
        .then(() => {
          return dispatch(fetchUserAttachment());
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    };

    fetchCompanyList();
  }, [dispatch]);

  useEffect(() => {}, [CompanyList]);

  /* HANDLE GALLERY CLOSE */
  const handleGalleryClose = useCallback(() => {
    setOpenGallery(false);
  }, []);

  /* HANDLE GALLERY OPEN */
  const handleGalleryOpen = useCallback(() => {
    setOpenGallery(true);
  }, []);

  function stringConvertor(fileId, fileName, title) {
    return `fileName = ${fileName} || fileId = ${fileId} || title = ${title}`;
  }

  function splitStringToObject(str) {
    try {
      const parts = str.split("||").map((part) => part.trim());
      const obj = {};
      parts?.forEach((part) => {
        const [key, value] = part.split("=").map((item) => item.trim());
        obj[key] = value;
      });
      console.log(obj);
      return obj;
    } catch (error) {
      console.error("Error occurred while parsing the string:", error.message);
      return {}; /* RETURN EMPTY OBJECT INCASE OF FAILURE */
    }
  }

  const [isLangProfile, setIsLangProfile] = useState(true);

  useEffect(() => {
    /* CONVERT OBJECT INTO AN ARRAY */
    const userDetailsArray = Object.values(userDetails);
    /* CHECK FOR USER DETAILS ARRAY EMPTY */
    if (Array.isArray(userDetailsArray) && userDetailsArray.length > 0) {
      const userForSelectedLanguage = userDetailsArray.find(
        (userObject) => userObject && userObject.mlanguage === selectedLanguage
      );

      if (userForSelectedLanguage) {
        setIsNewLangProfile(false);
        setuserInfo({ ...userForSelectedLanguage });

        setIsLangProfile(true);
      } else {
        setIsNewLangProfile(true);
        document.getElementById("clickConfirmLang").click();
      }
    } else {
      console.log("userDetails is not a non-empty array.");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLanguage, localStorage.getItem("userName"), userDetails]);

  useEffect(() => {
    if (userInfo?.profilePictureFileName?.length > 1) {
      setProfilePicObj(splitStringToObject(userInfo?.profilePictureFileName));
    }

    if (userInfo?.location?.length > 0) {
      let locToString = "";
      if (location.length > 0)
        locToString = location.map((item) => item.value).join(",");
      if (locToString !== userInfo?.location) {
        let tempLocArray = userInfo?.location.split(",");
        const data = tempLocArray.map((item) => {
          return { value: item, label: item };
        });
        setLocation(data);
      }
    }
  }, [userInfo, location]);

  /* HANDLE SUBMIT PERSONAL DATA */
  const handleSubmitProfile = useCallback(
    async (e) => {
      e.preventDefault();
      setEditProfilePic(false);
      setLoading(true);

      try {
        if (profilePicture != null) {
          setPicReloader(true);
          const formData = new FormData();
          formData.append("file", profilePicture);

          const img_res = await AttachmentPost(
            "handleMultiFile",
            userInfo?.id,
            formData
          );

          try {
            const body = {
              userId: userId,
              fileId: img_res?.data[0]?.fileId,
              fileName: img_res?.data[0]?.fileName,
              fileTitle: img_res?.data[0]?.fileTitle || "",
              fileSize: displayPicture?.fileSize,
              fileType: displayPicture?.fileType,
              linkedId: userId,
              linkedApplicationName: "Profile",
              roleName: role_,
            };
            var att_relation_res = await MakeAttachmentRelationPostApi(body);
          } catch (error) {
            console.error("Error in MakeAttachmentRelationPostApi:", error);
            showErrorToast(
              contentLabel("SomethingWentWrong", "nf Something Went Wrong")
            );
            setLoading(false);
            setPicReloader(false);
            return;
          }

          await DeleteAttachment(userId, profilePicObj?.fileId);
          try {
            const junk_ = userAttachmentData?.filter(
              (att) =>
                att?.linkedApplicationName === "Profile" &&
                att?.id !== att_relation_res?.id &&
                att?.roleName === role_
            );
            const delete_ = junk_?.map((file) => {
              return DeleteApi("Attachment Map", file?.id);
            });
            Promise.all(delete_);
            dispatch(fetchUserAttachment());
          } catch (error) {
            console.error("Error deleting attachment:", error);
          }

          showSuccessToast(
            contentLabel(
              "ProfilePictureUploaded",
              "nf Profile Picture Uploaded"
            )
          );

          setSelectedImage(null);
          setDisplayPicture({});
          setProfilePicture(null);
          setIsFileUploaded(true);
          setPicReloader(false);

          const profilepic = stringConvertor(
            img_res?.data[0]?.fileId,
            img_res?.data[0]?.fileName,
            img_res?.data[0]?.fileName || ""
          );

          const data = {
            ...userInfo,
            profilePictureFileName: profilepic,
            userCountry:
              selectedCountry !== undefined
                ? selectedCountry.label
                : userInfo?.userCountry,
            city:
              selectedCity !== undefined ? selectedCity.label : userInfo?.city,
            mlanguage: selectedLanguage,
            dob: formatTimestampToDate(userInfo?.dob),
            location:
              location.length > 0
                ? location.map((item) => item.value).join(", ")
                : "",
          };

          try {
            const editRes = await EditApi("User Details", userInfo?.id, data);
            showSuccessToast(
              contentLabel(
                "ProfileUpdatedSuccess",
                "nf Profile Updated Successfully"
              )
            );
            dispatch(
              editExistingUserProfile({
                id: userInfo?.id,
                updatedData: {
                  ...editRes.data,
                  dob: `${new Date(editRes.data.dob).getTime()}`,
                },
              })
            );
            setLoading(false);
            setProfilePicName(null);
            setSelectedImage(null);
            const userDetailRes = await UserDetailsApi();
            setuserInfo({ ...userDetailRes.data[0] });
          } catch (err) {
            setuserInfo({ ...userDetails[0] });
            console.error("Error editing profile:", err);
            showErrorToast(
              contentLabel("SomethingWentWrong", "nf Something Went Wrong")
            );
            setLoading(false);
          }
        } else if (isLangProfile) {
          const data = {
            ...userInfo,
            userCountry:
              selectedCountry !== undefined
                ? selectedCountry.label
                : userInfo?.userCountry,
            city:
              selectedCity !== undefined ? selectedCity.label : userInfo?.city,
            mlanguage: selectedLanguage,
            dob: formatTimestampToDate(userInfo?.dob),
            location:
              location.length > 0
                ? location.map((item) => item.value).join(", ")
                : "",
          };

          try {
            const editRes = await EditApi("User Details", userInfo?.id, data);
            showSuccessToast(
              contentLabel(
                "ProfileUpdatedSuccess",
                "nf Profile Updated Successfully"
              )
            );
            dispatch(
              editExistingUserProfile({
                id: userInfo?.id,
                updatedData: {
                  ...editRes.data,
                  dob: `${new Date(editRes.data.dob).getTime()}`,
                },
              })
            );
            setLoading(false);
            setProfilePicName(null);
            setSelectedImage(null);
            const userDetailRes = await UserDetailsApi();
            setuserInfo({ ...userDetailRes.data[0] });
          } catch (err) {
            setuserInfo({ ...userDetails[0] });
            console.error("Error editing profile:", err);
            showErrorToast(
              contentLabel("SomethingWentWrong", "nf Something Went Wrong")
            );
            setLoading(false);
          }
        } else {
          const data = {
            ...userInfo,
            userCountry:
              selectedCountry !== undefined
                ? selectedCountry.label
                : userInfo?.userCountry,
            city:
              selectedCity !== undefined ? selectedCity.label : userInfo?.city,
            mlanguage: selectedLanguage,
            dob: formatTimestampToDate(userInfo?.dob),
            location:
              location.length > 0
                ? location.map((item) => item.value).join(", ")
                : "",
          };

          try {
            const response = await PostApi(
              `User Details`,
              {
                ...data,
                UserID: localStorage.getItem("userId"),
                accountId: localStorage.getItem("userName"),
              },
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
              }
            );
            dispatch(listaddNewUserProfile(response.data));
            showSuccessToast(
              contentLabel(
                "ProfileCreatedSuccessfully",
                "nf Profile Created Successfully"
              )
            );
            setLoading(false);
            setIsLangProfile(true);
            await UserDetailsApi();
            setSelectedImage(null);
          } catch (error) {
            console.error("Error creating profile:", error);
            showErrorToast(
              contentLabel("SomethingWentWrong", "nf Something Went Wrong")
            );
            setLoading(false);
          }
        }
      } catch (err) {
        console.error("Error during profile submission:", err);
        showErrorToast(
          contentLabel("SomethingWentWrong", "nf Something Went Wrong")
        );
        setLoading(false);
        setPicReloader(false);
      }
    },
    [
      dispatch,
      isLangProfile,
      location,
      profilePicture,
      selectedCity,
      selectedCountry,
      selectedLanguage,
      setuserInfo,
      userDetails,
      userInfo,
      contentLabel,
      role_,
      userId,
      userAttachmentData,
      displayPicture?.fileSize,
      displayPicture?.fileType,
      profilePicObj?.fileId,
    ]
  );

  /* HANDLE SELECT IMAGE */
  const handleSelectImage = useCallback((image) => {
    const imageUrl = GetAttachment(
      image?.userId,
      image?.fileName,
      image?.fileId
    );
    setDisplayPicture(image);
    setSelectedImage(imageUrl);
    setEditProfilePic(true);
  }, []);

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[arr.length - 1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  /* AVATAR CROPPING */
  const onCrop = (preview) => {
    setPreviewDisplay({ preview });
    var file = dataURLtoFile(preview, displayPicture?.fileName);
    setProfilePicture(file);
    setProfilePicName(displayPicture?.fileName);
  };
  /* ON CLOSE AVATAR CROPPING */
  const onClose = () => {
    setPreviewDisplay({ preview: null });
    setProfilePicture(null);
    setIniProfilePicture(null);
    setEditProfilePic(false);
  };

  useEffect(() => {}, [
    selectedCity,
    selectedCountry,
    regionalData?.listOfCountries,
  ]);

  useEffect(() => {}, [picReloader]);

  useEffect(() => {}, [profilePicObj]);

  return (
    <Card.Body>
      {loading || !userInfoLoading === "idle" ? (
        <Row className="gy-4">
          <Col xl={6} lg={6} md={12} sm={12} xs={12}>
            <Col className="d-flex justify-content-lg-start justify-content-md-start justify-content-xl-start justify-content-sm-center">
              <Skeleton
                variant="circular"
                animation="wave"
                width={"150px"}
                height={"150px"}
              />
            </Col>
          </Col>
          <Col xl={6} lg={6} md={12} sm={12} xs={12}>
            {[...Array(4)].map((_, index) => (
              <Skeleton
                key={index}
                variant="rectangular"
                height={20}
                sx={{
                  marginBottom: "10px",
                  marginTop: index === 0 ? "20px" : "10px",
                }}
              />
            ))}
          </Col>
        </Row>
      ) : (
        <React.Fragment>
          {/* EDITABLE FORM */}
          <Row className="gy-4 align-items-lg-center align-items-md-center align-items-lg-center">
            <Col
              xl={6}
              lg={6}
              md={12}
              sm={12}
              xs={12}
              className="d-flex mb-2 justify-content-lg-start justify-content-md-start justify-content-xl-start justify-content-sm-center"
            >
              <div className="d-flex flex-column align-items-center text-center">
                <div style={{ position: "relative" }}>
                  <div
                    style={{
                      width: "150px",
                      height: "150px",
                    }}
                    className="rounded-circle"
                  >
                    {editProfilePic ? (
                      <div>
                        <div
                          style={{
                            position: "absolute",
                          }}
                        >
                          <Avatar
                            width={150}
                            onCrop={onCrop}
                            onClose={onClose}
                            height={150}
                            src={selectedImage}
                          />
                        </div>
                        {editProfilePic && (
                          <div>
                            <label
                              htmlFor="profilePic"
                              style={{
                                bottom: 0,
                                right: "10px",
                              }}
                              className="p-1 rounded-circle cursor-pointer bg-white position-absolute"
                            >
                              {!selectedImage ? (
                                <icons.ModeEditOutlineOutlinedIcon
                                  style={{
                                    fontSize: "25px",
                                  }}
                                  onClick={handleGalleryOpen}
                                />
                              ) : (
                                <icons.IoCheckmarkSharp
                                  style={{
                                    fontSize: "25px",
                                  }}
                                  onClick={(e) => handleSubmitProfile(e)}
                                />
                              )}
                            </label>
                          </div>
                        )}
                      </div>
                    ) : (
                      <React.Fragment>
                        {imgLoadingState && (
                          <Skeleton
                            variant="circular"
                            animation="wave"
                            height={150}
                            width={150}
                          />
                        )}
                        {userInfo?.profilePictureFileName &&
                        userInfo?.profilePictureFileName.length > 2 &&
                        profilePicObj?.fileName ? (
                          <img
                            style={{
                              objectFit: "cover",
                              display: imgLoadingState ? "none" : "block",
                            }}
                            src={GetAttachment(
                              userInfo?.id,
                              profilePicObj?.fileName,
                              profilePicObj?.fileId
                            )}
                            alt="Profile"
                            className="rounded-circle"
                            width="150"
                            height="150"
                            onError={(e) => {
                              e.target.src = images.user;
                            }}
                            onLoad={() => {
                              setImgLoadingState(false);
                            }}
                          />
                        ) : (
                          <LazyLoadingImageComponent
                            src={images.user}
                            alt="Profile picture"
                            className="rounded-circle"
                            style={{
                              display: imgLoadingState ? "none" : "block",
                            }}
                            width="150"
                            onLoad={() => setImgLoadingState(false)}
                          />
                        )}
                      </React.Fragment>
                    )}
                  </div>
                  {!editProfilePic && (
                    <div>
                      <label
                        htmlFor="profilePic"
                        style={{
                          bottom: 0,
                          right: "10px",
                        }}
                        className="p-1 rounded-circle cursor-pointer bg-white position-absolute"
                      >
                        {!selectedImage ? (
                          <icons.ModeEditOutlineOutlinedIcon
                            style={{
                              fontSize: "25px",
                            }}
                            onClick={handleGalleryOpen}
                          />
                        ) : (
                          <icons.IoCheckmarkSharp
                            style={{
                              fontSize: "25px",
                            }}
                            onClick={(e) => handleSubmitProfile(e)}
                          />
                        )}
                      </label>
                    </div>
                  )}
                </div>
              </div>
            </Col>
            {/* DETAILS */}
            <Col xl={6} lg={6} md={12} sm={12} xs={12}>
              <Row xl={6} lg={6} md={6} className="d-flex align-items-center">
                <Col xl={2} lg={3} md={2}>
                  <h6 className="mb-0">{contentLabel("Name", "nf Name")}</h6>
                </Col>
                <Col xl={10} lg={9} md={10}>
                  {userInfo?.firstName}&nbsp;
                  {userInfo?.lastName && userInfo?.lastName}
                </Col>
              </Row>

              <Row xl={6} lg={6} md={6} className="d-flex align-items-center">
                <Col xl={2} lg={3} md={2}>
                  <h6 className="mb-0">{contentLabel("Phone", "nf Phone")}</h6>
                </Col>
                <Col xl={10} lg={9} md={10}>
                  {userInfo?.mobileNumber}
                </Col>
              </Row>

              <Row xl={6} lg={6} md={6} className="d-flex align-items-center">
                <Col xl={2} lg={3} md={2}>
                  <h6 className="mb-0">{contentLabel("Email", "nf Email")}</h6>
                </Col>
                <Col xl={10} lg={9} md={10}>
                  {userInfo?.email}
                </Col>
              </Row>

              <Row xl={6} lg={6} md={6} className="d-flex align-items-center">
                <Col xl={2} lg={3} md={2}>
                  <h6 className="mb-0">{contentLabel("About", "nf About")}</h6>
                </Col>
                <Col xl={10} lg={9} md={10}>
                  {userInfo?.about}
                </Col>
              </Row>
            </Col>

            {/* GALLERY */}
            <Gallery
              title={contentLabel("ChoosePP", "nf Choose your profile picture")}
              openGallery={openGallery}
              handleGalleryClose={handleGalleryClose}
              handleSelectImage={handleSelectImage}
            />
          </Row>
        </React.Fragment>
      )}
    </Card.Body>
  );
};

export default PersonalDetailSummary;
