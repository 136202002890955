import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { FetchDetailedDataByUsedId } from "../../api/fetchAllData/FetchDetailedDataByUsedId";

export const fetchUserDetailData = createAsyncThunk(
  "detail/fetchData",
  async () => {
    if (localStorage.getItem("token") && localStorage.getItem("userId")) {
      try {
        const userId = localStorage.getItem("userId");
        const res = await FetchDetailedDataByUsedId(userId);
        return res;
      } catch (error) {
        throw error;
      }
    }
  }
);

const initialState = {
  detailedProfileData: null,
  loading: false,
  error: null,
};

const userDetailedProfileSLice = createSlice({
  name: "userDetailedProfile",
  initialState,
  reducers: {
    setUserDetailedProfileData: (state, action) => {
      state.detailedProfileData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserDetailData.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(fetchUserDetailData.fulfilled, (state, action) => {
        state.detailedProfileData = action.payload;
        state.loading = false;
      })
      .addCase(fetchUserDetailData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { setUserDetailedProfileData } = userDetailedProfileSLice.actions;

export default userDetailedProfileSLice.reducer;
