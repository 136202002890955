import React, { useMemo } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import BackToTop from "../../../components/BackToTop/BackToTop";
import useContentLabel from "../../../hooks/useContentLabel";
import PersonalDetailSummary from "./Personal/PersonalDetailSummary";

const SeekerPersonalDetailLayout = ({ children }) => {
  /* LOCATION */
  const { pathname } = useLocation();

  /* LABEL HOOK */
  const contentLabel = useContentLabel();

  const activePath = useMemo(() => {
    switch (pathname) {
      case "/skillSeeker/profile-management/profile-info":
        return contentLabel("PersonalDetails", "nf Personal Details");
      case "/skillSeeker/profile-management/company":
        return contentLabel("Company", "nf Company");
      case "/skillingagency/profile-management/profile-info":
        return contentLabel("PersonalDetails", "nf Personal Details");
      case "/skillingagency/profile-management/company":
        return contentLabel("Skillers", "nf Skillers");
      default:
        return "";
    }
  }, [contentLabel, pathname]);

  return (
    <div className="content-body">
      <div className="container-fluid">
        <div className="profile-management-layout">
          <div
            className="row page-titles card"
            style={{ backgroundColor: "#fff" }}
          >
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                {contentLabel("ProfileManagement", "nf Profile Management")}
              </li>
              <li className="breadcrumb-item active">{activePath}</li>
            </ol>
          </div>

          <Row>
            <Col sm={12}>
              <Card className="p-4">
                <PersonalDetailSummary />
              </Card>
            </Col>
          </Row>
          {children}
        </div>
      </div>
      <BackToTop />
    </div>
  );
};

export default SeekerPersonalDetailLayout;
