import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isEditMode: false,
  isNewUser: false
};

const editModeSlice = createSlice({
  name: "editMode",
  initialState,
  reducers: {
    setEditMode: (state, action) => {
      state.isEditMode = action.payload;
    },
    setIsNewUser: (state, action) => {
      state.isNewUser = action.payload;
    }
  },
});

export const { setEditMode, setIsNewUser } = editModeSlice.actions;
export default editModeSlice.reducer;
