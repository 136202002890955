import { createSlice } from '@reduxjs/toolkit';

const directionSlice = createSlice({
  name: 'direction',
  initialState: 'ltr', 
  reducers: {
    setDirection: (state, action) => {
      return action.payload;
      },

    },
  
});

export const { setDirection } = directionSlice.actions;
export default directionSlice.reducer;
