import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import noAnon from "../Images/No anon .jpg";
import EditApi from "../api/editData/EditApi";
import { EditRelationsApi } from "../api/editData/EditSkillsApi";
import { FetchProjectHistory } from "../api/fetchAllData/FetchProjectHistory";
import { fetchAwardsHistory } from "../api/fetchAllData/fetchAwardsHistory";
import { fetchCertificationHistory } from "../api/fetchAllData/fetchCertificationHistory";
import { fetchConferencesHistory } from "../api/fetchAllData/fetchConferenceHistory";
import { fetchDataSkillsAcquired } from "../api/fetchAllData/fetchDataSkillsAcquired";
import { fetchDataSkillsApplied } from "../api/fetchAllData/fetchDataSkillsApplied";
import { fetchEducationHistory } from "../api/fetchAllData/fetchEducationHistory";
import { fetchLicensesHistory } from "../api/fetchAllData/fetchLicensesHistory";
import { fetchMembershipHistory } from "../api/fetchAllData/fetchMembershipHistory";
import { FetchOrganizationHistory } from "../api/fetchAllData/fetchOrganization";
import { fetchOthersHistory } from "../api/fetchAllData/fetchOtherHistory";
import { fetchSkillingHistory } from "../api/fetchAllData/fetchSkillingHistory";
import { fetchTrainingHistory } from "../api/fetchAllData/fetchTrainingHistory";
import { fetchUserLanguages } from "../api/fetchAllData/fetchUserLanguages";
import { BASE_URL } from "../config/Properties";
import useContentLabel from "../hooks/useContentLabel";
import { achievementUpdateRecord } from "../reducer/detailedProfile/achievementSlice";
import { certificationUpdateRecord } from "../reducer/detailedProfile/certificationSlice";
import { conferenceUpdateRecord } from "../reducer/detailedProfile/conferenceSlice";
import { educationUpdateRecord } from "../reducer/detailedProfile/educationSlice";
import { updateEmployment } from "../reducer/detailedProfile/employmentSlice";
import { licenseUpdateRecord } from "../reducer/detailedProfile/licensesSlice";
import { membershipUpdateRecord } from "../reducer/detailedProfile/membershipSlice";
import { othersUpdateRecord } from "../reducer/detailedProfile/otherSlice";
import { projectUpdateRecord } from "../reducer/detailedProfile/projectSlice";
import { skillingUpdateRecord } from "../reducer/detailedProfile/skillingsSlice";
import { trainingUpdateRecord } from "../reducer/detailedProfile/trainingSlice";
import { editSkillAcquired } from "../reducer/skillProfile/SkillsAcquiredSlice";
import { editSkillApplied } from "../reducer/skillProfile/SkillsAppliedSlice";
import { showErrorToast } from "./ToastNotification/showErrorToast";
import { showSuccessToast } from "./ToastNotification/showSuccessToast";
const Anonimization = ({
  fieldName = "def",
  form,
  setForm = null,
  value,
  toggleable = false,
  api = "false",
  id = "0",
  forValidation = false,
}) => {
  const contentLabel = useContentLabel();
  const [isVisible, setIsVisible] = useState();

  const [Loader, setLoader] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setIsVisible(value);
  }, [value]);

  const toggleVisibility = async () => {
    setLoader(true);

    setIsVisible((prevIsVisible) => !prevIsVisible); // Use state updater function
    const newValue = !isVisible ? "Yes" : "No";
    if (toggleable && api !== "false") {
      try {
        // Use the updated state value
        await EditApi(form?.applicationName, form?.id, {
          recordAnonymous: newValue,
        }).then(async (res) => {
          if (
            [
              "Employment History",
              "Project  History",
              "Education History",
              "Certification History",
              "Conferences",
              "Skilling",
              "Training",
            ].includes(res?.data?.applicationName)
          ) {
            try {
              const payload = {
                recordAnonymous: res?.data?.recordAnonymous,
                tables:
                  res?.data?.applicationName === "Employment History"
                    ? ["Project  History", "Skills Applied"]
                    : res?.data?.applicationName === "Project  History"
                    ? ["Skills Applied"]
                    : ["Skills Acquired"],
              };
              await EditRelationsApi(
                res?.data?.applicationName,
                res?.data?.id,
                payload
              );
              if (
                [
                  "Education History",
                  "Certification History",
                  "Conferences",
                  "Skilling",
                  "Training",
                ].includes(res?.data?.applicationName)
              ) {
                dispatch(fetchDataSkillsAcquired());
              } else {
                dispatch(fetchDataSkillsApplied());
              }
            } catch (error) {
              console.error("Error editing relations", error);
            }
          }

          setLoader(false);
          showSuccessToast(contentLabel("StatusChanged", "Status Changed"));

          switch (res.data.applicationName) {
            case "Skills Applied":
              dispatch(editSkillApplied(res.data));
              dispatch(fetchDataSkillsApplied());
              console.log("skil Applied edit");
              break;
            case "Skills Acquired":
              dispatch(editSkillAcquired(res.data));
              dispatch(fetchDataSkillsAcquired());
              console.log("skil Acquired edit");
              break;

            case "Employment History":
              dispatch(updateEmployment(res.data));
              dispatch(FetchOrganizationHistory());
              dispatch(FetchProjectHistory());
              break;

            case "Project  History":
              dispatch(projectUpdateRecord(res.data));
              dispatch(FetchProjectHistory());
              break;

            case "Education History":
              dispatch(educationUpdateRecord(res.data));
              dispatch(fetchEducationHistory());
              break;

            case "Certification History":
              dispatch(certificationUpdateRecord(res.data));
              dispatch(fetchCertificationHistory());
              break;
            case "Training":
              dispatch(trainingUpdateRecord(res.data));
              dispatch(fetchTrainingHistory());
              break;

            case "Conferences":
              dispatch(conferenceUpdateRecord(res.data));
              dispatch(fetchConferencesHistory());
              break;
            case "Skilling":
              dispatch(skillingUpdateRecord(res.data));
              dispatch(fetchSkillingHistory());
              break;
            case "Licenses":
              dispatch(licenseUpdateRecord(res.data));
              dispatch(fetchLicensesHistory());
              break;
            case "Awards":
              dispatch(achievementUpdateRecord(res.data));
              dispatch(fetchAwardsHistory());
              break;
            case "Memberships":
              dispatch(membershipUpdateRecord(res.data));
              dispatch(fetchMembershipHistory());
              break;
            case "User Languages":
              dispatch(fetchUserLanguages());
              break;
            case "Others":
              dispatch(othersUpdateRecord(res.data));
              dispatch(fetchOthersHistory());
              break;
            default:
              break;
          }
        });
      } catch (error) {
        showErrorToast(contentLabel('StatusNotChanged', 'nf status not changed'));
        console.log(error);
      }
    } else if (toggleable && form && setForm && api === "false") {
      if (fieldName === "fn" || fieldName === "mlnShowHide") {
        setForm({ ...form, fn: newValue, mlnShowHide: newValue });
      } else {
        setForm({ ...form, [fieldName]: newValue });
      }
    }
  };

  return (
    <React.Fragment>
      {api === "false" ? (
        toggleable ? (
          <div
            variant="outline-primary"
            onClick={toggleVisibility}
            className={
              forValidation &&
              "d-flex gap-3 justify-content-start align-items-center cursor-pointer"
            }
          >
            {!isVisible ? (
              <img src={noAnon} width={16} height={16} alt="no-anon" />
            ) : (
              <img
                src={BASE_URL + "/skill/api/v1/getImage/anonymousLogo.png"}
                width={16}
                height={16}
                alt="anon"
              />
            )}
            {forValidation && (
              <span>
                {!isVisible
                  ? contentLabel("Anonimize", "Anonimize")
                  : contentLabel("LiftAnonimize", "Lift Anonimize")}
              </span>
            )}
          </div>
        ) : (
          <div variant="outline-primary">
            {!isVisible ? (
              <img src={noAnon} width={16} height={16} alt="no-anon" />
            ) : (
              <img
                src={BASE_URL + "/skill/api/v1/getImage/anonymousLogo.png"}
                width={16}
                height={16}
                alt="anon"
              />
            )}
            {forValidation && (
              <span>
                {!isVisible
                  ? contentLabel("Anonimize", "Anonimize")
                  : contentLabel("LiftAnonimize", "Lift Anonimize")}
              </span>
            )}
          </div>
        )
      ) : toggleable && Loader === false ? (
        <div
          variant="outline-primary"
          onClick={toggleVisibility}
          className={
            forValidation &&
            "d-flex gap-3 justify-content-start align-items-center cursor-pointer"
          }
        >
          {!isVisible ? (
            <img src={noAnon} width={16} height={16} alt="no-anon" />
          ) : (
            <img
              src={BASE_URL + "/skill/api/v1/getImage/anonymousLogo.png"}
              width={16}
              height={16}
              alt="anon"
            />
          )}
          {forValidation && (
            <span>
              {!isVisible
                ? contentLabel("Anonimize", "Anonimize")
                : contentLabel("LiftAnonimize", "Lift Anonimize")}
            </span>
          )}
        </div>
      ) : (
        <div variant="outline-primary" class="opacity-25 ">
          {!isVisible ? (
            <img src={noAnon} width={16} height={16} alt="no-anon" />
          ) : (
            <img
              src={BASE_URL + "/skill/api/v1/getImage/anonymousLogo.png"}
              class="opacity-75  "
              width={16}
              height={16}
              alt="anon"
            />
          )}
          {forValidation && (
            <span>
              {!isVisible
                ? contentLabel("Anonimize", "Anonimize")
                : contentLabel("LiftAnonimize", "Lift Anonimize")}
            </span>
          )}
        </div>
      )}
    </React.Fragment>
  );
};

export default Anonimization;
