import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const showSuccessToast = (message, options) => {
  toast.success(message, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: false,
    pauseOnHover: false,
    draggable: false,
    closeButton: false,
    // progress: undefined,
    theme: "colored",
    ...options,
    className: "snackbar", 
    bodyClassName: "snackbar-body",
    style:{ backgroundColor: "#17B169" }
  });
};