import React from 'react'
import { Spinner } from 'react-bootstrap'

const Loader = () => {
    return (
        <div class="d-flex justify-content-center align-items-center" style={{ height: "100vh" }}>
            <Spinner animation='border' variant='primary' role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        </div>
    )
}

export default Loader