import { createSlice } from "@reduxjs/toolkit";
import { fetchConferencesHistory } from "../../api/fetchAllData/fetchConferenceHistory";
const initialState = {
  data: [
    {
    mtype:"Conference",
    fromDate:"",
    toDate:"",
    duration:"",
    briefDescriptions:"",
    location:"",
    showHide:"",
    validation:"",
    blockChain:"",
    mlanguage:"",
    conferenceName:"",
    organization:"",
    userId:"",
    title:""
  }
  ],
  status: "idle", // possible values: "idle", "loading", "success", "error"
  error: null,
};

const conferenceSlice = createSlice({
  name: "conferenceHistory",
  initialState,
  reducers: {
    setConference: (state, action) => {
      state.data = action.payload;
      state.status = "success";
      state.error = null;
    },
    emptyConference: (state) => {
      state.data = [];
      state.status = "idle";
      state.error = null;
    },
    addNewConference: (state, action) => {
      state.data = [action.payload,...state.data ];
      state.status = "success";
      state.error = null;
      console.log("dataConfREdx " +state.data );
    },
    ConferenceDelete: (state, action) => {
      state.data = state.data.filter((skill) => skill.id !== action.payload);
    },
    logoutConference: ()=> initialState,
    conferenceUpdate: (state, action) => {
      const { id, validation } = action.payload;
      const skillToUpdate = state.data.find((skill) => skill.id === id);
      if (skillToUpdate) {
        skillToUpdate.validation = validation;
      }
    },
    conferenceUpdateRecord: (state, action) => {
      state.data = state.data.map((skill) => {
        if (skill.id === action.payload.id) {

          return {
            ...skill,
            ...action.payload,
          };
        }

        return skill;
      });
      console.log(state.data);
      state.status = "success";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchConferencesHistory.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchConferencesHistory.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = "success";
        state.error = null;
      })
      .addCase(fetchConferencesHistory.rejected, (state, action) => {
        state.status = "error";
        state.error = action.error.message;
      });
  },
});

export const { conferenceUpdateRecord, setConference, emptyConference,addNewConference,ConferenceDelete,logoutConference, conferenceUpdate } = conferenceSlice.actions;
export default conferenceSlice.reducer;
