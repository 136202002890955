import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../config/Properties";

export const fetchValidation = createAsyncThunk('validation/fetchData', async () => {
    if (localStorage.getItem("token") && localStorage.getItem("userId")) {
        try {
            const res = await axios.get(`${BASE_URL}/skill/api/v1/skills/get-all-user-data-by-userId/validation requests/${localStorage.getItem(
                "userId"
            )}?authToken=${localStorage.getItem("token")}`);
            return res.data;
        } catch (error) {
            throw error;
        }
    }
});

const getUserValidationSlice = createSlice({
    name: 'userValidation',
    initialState: {
        userValidationData: [],
        loading: false,
        error: null
    },
    reducers: {
        editValidationRequest: (state, action) => {
            state.userValidationData = state.userValidationData.map((req) => {
                if (req.id === action.payload.id) {
                    return {
                        ...req,
                        ...action.payload,
                    };
                }
                return req;
            });
            state.loading = "success";
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchValidation.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchValidation.fulfilled, (state, action) => {
                state.loading = false;
                state.userValidationData = action.payload;
            })
            .addCase(fetchValidation.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message
            })
    }
});
export const { editValidationRequest } = getUserValidationSlice.actions;
export default getUserValidationSlice.reducer;