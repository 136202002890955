import React from 'react';
import { Spinner } from 'react-bootstrap';

const LazyLoader = () => {
  return (
    <div className="d-flex justify-content-center align-items-center vh-100">
      <Spinner animation="grow" className='text-primary' />
    </div>
  );
};

export default LazyLoader;
