import { lazy } from 'react';
import SkillSeekerBreadCrumb from '../../views/Skill Profile Management/SkillSeekerBreadCrumb';
import SeekerPersonalDetailLayout from '../../views/profile management/Skill Seeker Module/SeekerPersonalDetailLayout';
import CreateOpportunityManagement from '../../views/Skill Profile Management/CreateOpportunityManagement';


const skillSeekerRoutes = [
  {
    exact: 'true',
    path: 'profile-management/profile-info',
    layout: SeekerPersonalDetailLayout,
    element: lazy(() => import('../../views/profile management/Skill Seeker Module/Personal/SeekerPersonalDetails'))
  },
  {
    exact: 'true',
    path: 'profile-management/company',
    layout: SeekerPersonalDetailLayout,
    element: lazy(() => import('../../views/profile management/Skill Seeker Module/Company/SeekerCompanyDetails'))
  },
  {
    exact: 'true',
    path: 'Home',
    element: lazy(() => import('../../pages/SkillAvailer/SkillSeekerHomePage'))
  },
  {
    exact: 'true',
    path: 'Skill-Search/Database-Access',
    layout: SkillSeekerBreadCrumb,
    element: lazy(() => import('../../pages/SkillAvailer/SkillSearchDashboard'))
  },
  {
    exact: 'true',
    path: 'Skill-Search/Saved-Searches',
    layout: SkillSeekerBreadCrumb,
    element: lazy(() => import('../../pages/SkillAvailer/Skill Search/Saved Searches/SavedSearchDashBoard'))
  },
  {
    exact: 'true',
    path: 'Skill-Search/Saved-Searches/Candidate-management',
    layout: SkillSeekerBreadCrumb,
    element: lazy(() => import('../../pages/SkillAvailer/CandidateManagementDashBoard'))
  },
  {
    exact: 'true',
    path: 'Opportunities',
    layout: SkillSeekerBreadCrumb,
    element: lazy(() => import('../../pages/SkillAvailer/OpportunitiesViewDashboard'))
  },
  // {
  //   exact: 'true',
  //   path: 'Candidate-management',
  //   layout: SkillSeekerBreadCrumb,
  //   element: lazy(() => import('../../pages/SkillAvailer/CandidateManagementDashBoard'))
  // },
  {
    exact: 'true',
    path: 'Opportunities/Candidate-management',
    layout: SkillSeekerBreadCrumb,
    element: lazy(() => import('../../pages/SkillAvailer/CandidateManagementDashBoard'))
  },
  {
    exact: 'true',
    path: 'Opportunities/Create',
    layout: CreateOpportunityManagement,
    element: lazy(() => import('../../components/SkillAvailer/Opportunity Creation/CreateOpportunity'))
  },
  {
    exact: 'true',
    path: 'Opportunities/Edit',
    layout: SkillSeekerBreadCrumb,
    element: lazy(() => import('../../pages/SkillAvailer/EditOpportunity'))
  },
];

export default skillSeekerRoutes;
