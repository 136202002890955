import { createAsyncThunk } from "@reduxjs/toolkit";
import { BASE_URL } from "../../config/Properties";

export const GetCompanyListByUserId = createAsyncThunk(
    "SkillSeeker/CompanyList",
    async () => {
        try {
            const token = localStorage.getItem("token");
            const userId = localStorage.getItem("userId");
            const response = await fetch(`${BASE_URL}/skill/api/v1/skills/get-all-user-data-by-userId/User Companies/${userId}?authToken=${token}`);

            const data = await response.json();
            console.log(data);
            return data;
        } catch (error) {
            throw error;
        }
    }
);

