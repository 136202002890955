import { lazy } from 'react';

const skillOwnerAuthRoutes = [
  {
    exact: 'true',
    path: 'user-auth',
    element: lazy(() => import('../pages/AuthPages/Login & Singup/UserVerificationEmail'))
  },
  {
    exact: 'true',
    path: 'signin',
    element: lazy(() => import('../pages/AuthPages/Login & Singup/Login Component/LoginTemplate'))
  },
  {
    exact: 'true',
    path: 'signup',
    element: lazy(() => import('../pages/AuthPages/Login & Singup/SignUp Component/SignUpTemplate'))
  }
];

export default skillOwnerAuthRoutes;
