// import { lazy } from 'react';

// const newUserSORoutes = [
//   {
//     exact: 'true',
//     path: 'resumeparser',
//     element: lazy(() => import('../../pages/SkillOwner/NewUser/ResumeParser'))
//   },
//   {
//     exact: 'true',
//     path: 'resumeresults',
//     element: lazy(() => import('../../pages/SkillOwner/NewUser/ResumeResults'))
//   },
//   {
//     exact: 'true',
//     path: 'basicInfo',
//     element: lazy(() => import('../../pages/SkillOwner/NewUser/NewUser'))
//   },
//   {
//     exact: 'true',
//     path: 'linkskills',
//     element: lazy(() => import('../../pages/SkillOwner/NewUser/NewUserLinkSkills'))
//   },
//   {
//     exact: 'true',
//     path: 'destination',
//     element: lazy(() => import('../../pages/SkillOwner/NewUser/components/NewUserDestinationScreen'))
//   },
//   {
//     exact: 'true',
//     path: '*',
//     element: lazy(() => import('../../pages/NotFound'))
//   },
// ];

// export default newUserSORoutes;
import { lazy } from 'react';

const newUserSORoutes = [
  {
    exact: 'true',
    path: '',
    element: lazy(() => import('../../pages/SkillOwner/NewUser/NavbarForNewUser')),
    children: [
        {
            exact: 'true',
            path: 'resumeparser',
            element: lazy(() => import('../../pages/SkillOwner/NewUser/ResumeParser'))
          },
          {
            exact: 'true',
            path: 'resumeresults',
            element: lazy(() => import('../../pages/SkillOwner/NewUser/ResumeResults'))
          },
          {
            exact: 'true',
            path: 'basicInfo',
            element: lazy(() => import('../../pages/SkillOwner/NewUser/NewUser'))
          },
          {
            exact: 'true',
            path: 'linkskills',
            element: lazy(() => import('../../pages/SkillOwner/NewUser/NewUserLinkSkills'))
          },
          {
            exact: 'true',
            path: '*',
            element: lazy(() => import('../../pages/NotFound'))
          },
    ]
  },
];

export default newUserSORoutes;
