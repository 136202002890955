import { createSlice } from "@reduxjs/toolkit"

const detailedProfileEditSlice = createSlice({
    name: "detailedProfileEdit",
    initialState: null,
    reducers: {
        setDetailedProfileEditData: (state, action) => action.payload
    }
});

export const { setDetailedProfileEditData } = detailedProfileEditSlice.actions;
export default detailedProfileEditSlice.reducer;