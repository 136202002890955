import { createSlice } from "@reduxjs/toolkit";
import { fetchUsersBasedOnSkillSearch } from '../../../api/SkillSeeker/fetchUsersBasedOnSkillSearch.js';



const initialState = {
    SkillsInSearch: [],
    data: [],
    noOfResult: 0,
    status: 'idle', // 'idle', 'loading', 'succeeded', 'failed'
    error: null,
    view: 'list',
    metaData: {
        start: 0,
        size: 15,
    },
    showSearchBox: true,
    searchMode: 1, //the radio button quick = 1 , exact = 2
    selectedMode: 0,//The results are based on which search mode  >>This is updated when a search is click >> Required for Refining, 
}






const SkillBasedResultSlice = createSlice({
    name: "SkillBasedResult",
    initialState: {
        data: [],
        view: 'list',
        noOfResult: 0,
        status: 'idle', // 'idle', 'loading', 'succeeded', 'failed'
        error: null,
        metaData: {
            start: 0,
            size: 15,
        },
        showSearchBox: true,
        searchMode: 1, //the radio button quick = 1 , exact = 2
        selectedMode: 0,//The results are based on which search mode  >>This is updated when a search is click >> Required for Refining, 
        SelectedOwner: {},
        SkillsInSearch: [],
    },
    reducers: {
        setSkillSearchResult: (state, action) => {

            state.data = [action.payload.data];

        },
        setSkillsInSearch: (state, action) => {
            state.SkillsInSearch = action.payload;

        },
        enableSearchBox: (state) => {
            state.showSearchBox = true;
        },
        disableSearchBox: (state) => {
            state.showSearchBox = false;
        },
        //   logout reset to initial state
        emptySkillSearchResult: (state) => {
            return initialState;
        },
        updateSearchMode: (state, action) => {
            state.searchMode = action.payload;
        },
        updateSelectedMode: (state, action) => {
            state.selectedMode = action.payload;
        },
        logOutForSkillSeekerSearch: () => {
            return initialState;
        },
        // setAcquiredSkills: (state, action) => {
        //     const { userId, acquiredSkillData } = action.payload;
        //     const index = state.data.findIndex(obj => obj.userId === userId);

        //     // If the object with the given userId is found
        //     if (index !== -1) {
        //         console.log("Object with id axquired", acquiredSkillData, " found.");

        //         if (acquiredSkillData?.length > 0) {
        //             acquiredSkillData?.map((datas) => {
        //                 return {
        //                     ...datas,
        //                     validation: datas?.ValidationData?.length > 0 ? "Yes" : "No"
        //                 }
        //             })
        //         }



        //         // Create a copy of the object with the additional field added
        //         state.data[index] = { ...state.data[index], skillacq: acquiredSkillData };
        //         console.log(state.data);
        //     } else {
        //         console.log("Object with id axquired", acquiredSkillData, " not found.");
        //     }
        // },
        // setAppliedSkills: (state, action) => {
        //     const { userId, appliedSkillData } = action.payload;
        //     const index = state.data.findIndex(obj => obj.userId === userId);

        //     // If the object with the given userId is found
        //     if (index !== -1) {

        //         if (appliedSkillData?.length > 0) {
        //             appliedSkillData?.map((datas) => {
        //                 return {
        //                     ...datas,
        //                     validation: datas?.ValidationData?.length > 0 ? "Yes" : "No"
        //                 }
        //             })
        //         }

        //         console.log(appliedSkillData);
        //         // Create a copy of the object with the additional field added
        //         state.data[index] = { ...state.data[index], skillapp: appliedSkillData };
        //         console.log("Object with id applied", appliedSkillData, "  found.");
        //         console.log("indx ", state.data[index].skillapp);
        //     } else {
        //         console.log("Object with id applied", appliedSkillData, " not found.");
        //     }
        // },
        // setDetailedProfileData: (state, action) => {
        //     console.log("action of detailed page ", action.payload);
        //     const { userID } = action.payload;
        //     if (userID === null) {
        //         return;
        //     }
        //     const index = state.data.findIndex(obj => obj.userId === action.payload.userID);

        //     // If the object with the given userId is found
        //     if (index !== -1) {
        //         console.log("All details of user", action.payload, " found.");

        //         if (action.payload) {
        //             state.data[index] = { ...state.data[index], detailedProfileData: action.payload };

        //         }

        //         // Create a copy of the object with the additional field added

        //     } else {
        //         console.log("Object with id axquired", userID, " not found.");
        //     }
        // },
        setAcquiredSkills: (state, action) => {
            const { userId, acquiredSkillData } = action.payload;
            const index = state.SelectedOwner && state.SelectedOwner.userId === userId ? 0 : -1;
            console.log("found Acquired  index ", index);
            // If the object with the given userId is found
            if (index !== -1) {
                console.log("Object with id axquired", acquiredSkillData, " found.");

                if (acquiredSkillData?.length > 0) {
                    acquiredSkillData?.map((datas) => {
                        return {
                            ...datas,
                            validation: datas?.ValidationData?.length > 0 ? "Yes" : "No"
                        }
                    })
                }



                // Create a copy of the object with the additional field added
                state.SelectedOwner = { ...state.SelectedOwner, skillacq: acquiredSkillData };
                console.log(state.SelectedOwner);
            } else {
                console.log("Object with id axquired", acquiredSkillData, " not found.");
            }
        },
        setAppliedSkills: (state, action) => {
            const { userId, appliedSkillData } = action.payload;
            const index = state.SelectedOwner && state.SelectedOwner.userId === userId ? 0 : -1;
            console.log("found Applie  index ", index);
            // If the object with the given userId is found
            if (index !== -1) {

                if (appliedSkillData?.length > 0) {
                    appliedSkillData?.map((datas) => {
                        return {
                            ...datas,
                            validation: datas?.ValidationData?.length > 0 ? "Yes" : "No"
                        }
                    })
                }

                console.log(appliedSkillData);
                // Create a copy of the object with the additional field added
                state.SelectedOwner = { ...state.SelectedOwner, skillapp: appliedSkillData };
                console.log("Object with id applied", appliedSkillData, "  found.");
                console.log("indx ", state.SelectedOwner);
            } else {
                console.log("Object with id applied", appliedSkillData, " not found.");
            }
        },
        setValidationData: (state, action) => {
            const { userId, data } = action.payload;
            const index = state.SelectedOwner && state.SelectedOwner.userId === userId ? 0 : -1;
            console.log("found Applie  index ", index);
            // If the object with the given userId is found
            if (index !== -1) {
                console.log(data);
                state.SelectedOwner = { ...state.SelectedOwner, validationDatas: data };
                console.log("Object with id Validatin", data, "  found.");
                console.log("indx ", state.SelectedOwner);
            } else {
                console.log("Object with Validatin", data, " not found.");
            }
        },
        setDetailedProfileData: (state, action) => {
            console.log("action of detailed page ", action.payload);
            const { userID } = action.payload;
            if (userID === null) {
                return;
            }
            const index = state.SelectedOwner && state.SelectedOwner.userId === userID ? 0 : -1;
            console.log("found Detaile  index ", index);
            // If the object with the given userId is found
            if (index !== -1) {
                console.log("All details of user", action.payload, " found.");

                if (action.payload) {
                    state.SelectedOwner = { ...state.SelectedOwner, detailedProfileData: action.payload };

                }

                // Create a copy of the object with the additional field added

            } else {
                console.log("Object with id axquired", userID, " not found.");
            }
        },
        setSelectedOwner: (state, action) => {
            let data = action.payload;

            state.SelectedOwner = data;
            console.log(action.payload);
            //state.SelectedJd = action.payload;

        },
        emptySelectedOwner: (state) => {
            state.SelectedOwner = {};
        },
        setView: (state, action) => {
            console.log("view changed to ", action.payload);
            state.view = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchUsersBasedOnSkillSearch.pending, (state) => {
                state.status = "loading";
                state.error = null;
            })
            .addCase(fetchUsersBasedOnSkillSearch.fulfilled, (state, action) => {

                let data = action.payload.data;
                console.log("data ", data);

                if (data && data.length > 0) {
                    if (data[data.length - 1].hasOwnProperty("totalCount")) {
                        state.noOfResult = data[data.length - 1].totalCount;
                        data = data.slice(0, data.length - 1);

                    }
                    else state.noOfResult = data.length;




                } else {
                    state.noOfResult = 0;

                }

                state.data = data;
                state.status = "success";
                state.error = null;
            })
            .addCase(fetchUsersBasedOnSkillSearch.rejected, (state, action) => {
                state.status = "error";
                state.data = [];
                state.noOfResult = 0;

                state.error = action.error.message;
            });
    },

});

export const { setSkillSearchResult, setView, emptySkillSearchResult, logOutForSkillSeekerSearch, setValidationData,
    setAcquiredSkills, setAppliedSkills, setDetailedProfileData, disableSearchBox, enableSearchBox,
    updateSelectedMode, updateSearchMode, setSelectedOwner, emptySelectedOwner, setSkillsInSearch } = SkillBasedResultSlice.actions;
export default SkillBasedResultSlice.reducer;



