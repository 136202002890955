import { lazy } from 'react';
import ProfileManagement from '../views/profile management/ProfileManagement';


const profileManagementRoutes = [
  {
    exact: 'true',
    path: 'profile-info',
    layout: ProfileManagement,
    element: lazy(() => import('../views/profile management/profile data/ProfileData'))
  },
  {
    exact: 'true',
    path: 'employment',
    layout: ProfileManagement,
    element: lazy(() => import('../views/profile management/employment data/EmploymentData'))
  },
  {
    exact: 'true',
    path: 'work-details',
    layout: ProfileManagement,
    element: lazy(() => import('../views/profile management/work data/WorkData'))
  },
  {
    exact: 'true',
    path: 'learnings',
    layout: ProfileManagement,
    element: lazy(() => import('../views/profile management/learning data/LearningData'))
  },
  {
    exact: 'true',
    path: 'skills',
    layout: ProfileManagement,
    element: lazy(() => import('../views/profile management/skills data/SkillsData'))
  }
];

export default profileManagementRoutes;
