import { lazy } from 'react';

const authRoutes = [
  {
    exact: 'true',
    path: '',
    element: lazy(() => import('../pages/AuthPages/HscLogin'))
  },
  {
    exact: 'true',
    path: 'login',
    element: lazy(() => import('../pages/AuthPages/HscLogin'))
  },
  {
    exact: 'true',
    path: 'signup',
    element: lazy(() => import('../pages/AuthPages/HscSignup'))
  },
  {
    exact: 'true',
    path: 'forgotpassword',
    element: lazy(() => import('../pages/AuthPages/ForgotPassword'))
  },
  {
    exact: 'true',
    path: 'forgotpassword-noaccess',
    element: lazy(() => import('../pages/AuthPages/ForgotPasswordNoAccess'))
  },
  {
    exact: 'true',
    path: 'forgotpassword-altemail',
    element: lazy(() => import('../pages/AuthPages/ForgotPasswordWithAltEmail'))
  },
  {
    exact: 'true',
    path: 'reset/:id',
    element: lazy(() => import('../pages/template/Forgot Password/ForgotPasswordTemplate'))
  }
];

export default authRoutes;
