import { createSlice } from "@reduxjs/toolkit";

const selectedOpportunity = createSlice({
    name: "selectedOpportunity",
    initialState: null,
    reducers: {
        setSelectedOpportunityData: (state, action) => action.payload
    }
    
});

export const { setSelectedOpportunityData } = selectedOpportunity.actions;
export default selectedOpportunity.reducer;