import { createSlice } from "@reduxjs/toolkit";

//const initialState = [{ skill: "", experience: "", required: true, validated: true, edit: false, mandatory: false, show: true }];
const initialState = {
    skillsInMyReq: [],
    locationsInMyReq: []
}

const MyRequirementSkillSlice = createSlice({
    name: "MyRequirement",
    initialState: {
        skillsInMyReq: [],
        locationsInMyReq: []
    }
    ,
    reducers: {
        setMyReqSkills: (state, action) => {
            state.skillsInMyReq = action.payload;


        },
        setMyReqLocations: (state, action) => {
            state.locationsInMyReq = action.payload;
            console.log(action.payload);

        },
        emptyMyReqSkills: (state) => {
            console.log("emptied my requirments");
            return initialState
        },
        logoutforMyRequirements: () => { return initialState }
    }
})

export const { setMyReqSkills, emptyMyReqSkills, setMyReqLocations, logoutforMyRequirements } = MyRequirementSkillSlice.actions;
export default MyRequirementSkillSlice.reducer;