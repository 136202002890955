import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import SecondaryBtnLoader from "./Buttons/SecondaryBtnLoader";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { fetchDataSkillsApplied } from "../api/fetchAllData/fetchDataSkillsApplied";
import { fetchDataSkillsAcquired } from "../api/fetchAllData/fetchDataSkillsAcquired";
import { DayDifferenceToDynamicView } from "./SkillOwner/HelperFunction/DayDifferenceToDynamicView";
import { formatTimestampToDate } from "./SkillOwner/HelperFunction/FormatTimestampToDate";
import { BASE_URL } from "../config/Properties";
import { MdOpenInNew } from "react-icons/md";
import DeleteApi from "../api/DeleteData/DeleteApi";
import { showSuccessToast } from "./ToastNotification/showSuccessToast";
import { showErrorToast } from "./ToastNotification/showErrorToast";
import { SkillingDelete } from "../reducer/detailedProfile/skillingsSlice";
import { TrainingDelete } from "../reducer/detailedProfile/trainingSlice";
import { ConferenceDelete } from "../reducer/detailedProfile/conferenceSlice";
import { LicensesDelete } from "../reducer/detailedProfile/licensesSlice";
import { AwardsDelete } from "../reducer/detailedProfile/achievementSlice";
import { MembershipDelete } from "../reducer/detailedProfile/membershipSlice";
import { OtherDelete } from "../reducer/detailedProfile/otherSlice";
import { fetchAllRelationship } from "../api/relationshipApi/fetchAllRelationship";
import warningImg from "../Images/warning.png";

import axios from "axios";
import Loader from "./Loader";
import { FetchProjectHistory } from "../api/fetchAllData/FetchProjectHistory";
import { CertificationDelete } from "../reducer/detailedProfile/certificationSlice";
import { ProjectDelete } from "../reducer/detailedProfile/projectSlice";
import { EducationDelete } from "../reducer/detailedProfile/educationSlice";
import { EmploymentDelete } from "../reducer/detailedProfile/employmentSlice";
import { KnownLanguagesDelete } from "../reducer/detailedProfile/languageKnownSlice";
import useContentLabel from "../hooks/useContentLabel";
import { fetchTopSkill } from "../api/fetchAllData/fetchTopSkill";
import EditApi from "../api/editData/EditApi";
import { replaceTopSkillById } from "../reducer/mySkills/TopSkillSlice";
import { calculateDaysDifference } from "./SkillOwner/HelperFunction/CalculateDaysDifference ";
import { editAcquiredExp, editYoe } from "../reducer/mySkills/SkillSelectedSlice";

const DeleteFormDetailedProfile = () => {
  const contentLabel = useContentLabel();

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /* STORE IMPORTS */
  // const {
  //     skillValidation: validationData,
  //     getUserValidation: { userValidationData },
  //     language: selectedLanguage,
  //     SkillsApplied,
  //     SkillsAcquired,
  //     SkillSelected,
  //     content,
  //     deleteDetailedProfile
  // } = useSelector((state) => state);

  // console.log(SkillsApplied);
  // const dispatch = useDispatch()
  // const buttonRef = useRef()

  // //skill acquried delete list
  // const [deleteSkillAcquired, setDeleteSkillAcquired] = useState([])

  // // validation delete list
  // const [validationReqData, setValidationReqData] = useState([]);

  // // validation delete list
  // const [attachmentData, setAttachmentData] = useState([]);

  // // attachment delete lists
  // const token = localStorage.getItem("token");
  // const userId = localStorage.getItem("userId");
  // const [data, setData] = useState(); //string
  // const [fileString, setFileString] = useState(""); //string
  // const [fileObj, setFileObj] = useState([]); //list of file obj [{name:'str.png',id:"dsd"}]
  // const attachmentPdfBaseUrl = `${BASE_URL}/skill/api/v1/skills/pdfHandle/filedownload/`;
  // const attachmentImgBaseUrl = `${BASE_URL}/skill/api/v1/skills/imgHandle/filedownload/`;
  // const attachmentDownload = `${BASE_URL}/skill/api/v1/skills/download/filedownload/`;

  // //DeleteLoader
  // const [isDeleting, setIsDeleting] = useState(false)

  // console.log(deleteDetailedProfile);
  // console.log(SkillsAcquired);
  // console.log(deleteSkillAcquired);
  // console.log(validationReqData);
  // console.log(fileObj);

  // const handleDelete = () => {
  //     if (isDeleting) {
  //         return
  //     }

  //     setIsDeleting(true)
  //     DeleteApi(deleteDetailedProfile.applicationName, deleteDetailedProfile.id)
  //         .then((res) => {
  //             console.log(res);
  //             if (res.data.deletedStatus === "true") {
  //                 if (deleteDetailedProfile.applicationName === 'Skilling') {
  //                     dispatch(SkillingDelete(deleteDetailedProfile.id));
  //                 }
  //                 if (deleteDetailedProfile.applicationName === 'Training') {
  //                     dispatch(TrainingDelete(deleteDetailedProfile.id));
  //                 }
  //                 if (deleteDetailedProfile.applicationName === 'Conferences') {
  //                     dispatch(ConferenceDelete(deleteDetailedProfile.id));
  //                 }
  //                 if (deleteDetailedProfile.applicationName === 'Licenses') {
  //                     dispatch(LicensesDelete(deleteDetailedProfile.id));
  //                 }
  //                 if (deleteDetailedProfile.applicationName === 'Awards') {
  //                     dispatch(AwardsDelete(deleteDetailedProfile.id));
  //                 }
  //                 if (deleteDetailedProfile.applicationName === 'Memberships') {
  //                     dispatch(MembershipDelete(deleteDetailedProfile.id));
  //                 }
  //                 if (deleteDetailedProfile.applicationName === 'Others') {
  //                     dispatch(OtherDelete(deleteDetailedProfile.id));
  //                 }
  //                 showSuccessToast("Data Deleted Successfully");

  //                 buttonRef.current.click()
  //             } else {
  //                 console.log("Error in deleting data");
  //                 showErrorToast("You already deleted this data");
  //             }

  //             setIsDeleting(false)
  //         })
  //         .catch((err) => {
  //             console.log(err);
  //             showErrorToast("Error in deleting data");
  //             setIsDeleting(false)
  //         });
  // };

  // //fetching skill applied and acquired if data is not present
  // useEffect(() => {
  //     if (SkillsApplied.status === "idle") {
  //         dispatch(fetchDataSkillsApplied());
  //     }
  //     if (SkillsAcquired.status === "idle") {
  //         dispatch(fetchDataSkillsAcquired());
  //     }

  // }, [])

  // //setting skill accquired delete list
  // useEffect(() => {
  //     setDeleteSkillAcquired([])

  //     let filteredSkillAcquired = SkillsAcquired?.data?.filter((skill) => {
  //         return skill.mtype === deleteDetailedProfile?.applicationName && skill.source === deleteDetailedProfile?.title

  //     })

  //     setDeleteSkillAcquired(filteredSkillAcquired)

  // }, [deleteDetailedProfile])

  // // //setting validationdelete list
  // useEffect(() => {
  //     setValidationReqData([])

  //     let validationData = []

  //     deleteSkillAcquired?.map((skill) => {

  //         const filterValidationReqData = userValidationData?.filter(
  //             (val) => val?.requestorItemId === skill?.id
  //         );

  //         validationData = [...validationData, ...filterValidationReqData]

  //     })

  //     setValidationReqData(validationData)

  // }, [deleteSkillAcquired]);

  // //setting attachment delete list
  // const convertStingToFileObject = (inputString) => {
  //     // Split the input string by commas to get individual file strings
  //     const fileStrings = inputString.split(', ');
  //     console.log(fileStrings);

  //     // Map over each file string and parse it to extract name and fileId
  //     const data = fileStrings.map(fileString => {
  //         if (fileString?.length > 0) {

  //             // Extract name and fileId using regex
  //             if (fileString.match(/name = (.+?) \|/)[1].trim() && fileString.match(/fileId = (.+)/)[1].trim()) {
  //                 const name = fileString.match(/name = (.+?) \|/)[1].trim();
  //                 const fileId = fileString.match(/fileId = (.+)/)[1].trim();
  //                 console.log(name + " " + fileId);
  //                 return { name, fileId };
  //             }
  //             // Return an object with name and fileId

  //         }
  //     });

  //     return data;
  // }

  // useEffect(() => {

  //     setFileString(null)
  //     setFileObj(null)

  //     let filteredAttachmentData = []
  //     deleteSkillAcquired?.map((datas) => {

  //         if (datas?.attachmentFileNames?.length !== null) {
  //             let attachmentArray = datas?.attachmentFileNames?.split(',')

  //             if (attachmentArray) {

  //                 filteredAttachmentData = [...filteredAttachmentData, ...attachmentArray]
  //             }

  //         }
  //     })

  //     if (filteredAttachmentData) {

  //         setFileString(filteredAttachmentData?.join())
  //     }

  // }, [deleteSkillAcquired])

  // useEffect(() => {

  //     console.log(fileString);
  //     if (fileString && fileString !== undefined && fileString.length > 0) {
  //         setFileObj(convertStingToFileObject(fileString));
  //     }
  // }, [fileString])

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  /* STORE IMPORTS */
  const {
    language: selectedLanguage,
    content,
    deleteDetailedProfile,
  } = useSelector((state) => state);

  const dispatch = useDispatch();
  const buttonRef = useRef();
  const buttonRef1 = useRef();

  const topSkill = useSelector((state) => state.TopSkill);

  useEffect(() => {
    if (topSkill.status === "idle") {
      dispatch(fetchTopSkill());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topSkill.status]);

  const [deleteData, setDeleteData] = useState(null);

  //skill acquried delete list
  const [deleteProjects, setDeleteProjects] = useState([]);

  //skill acquried delete list
  const [deleteSkillAcquired, setDeleteSkillAcquired] = useState([]);

  //skill applied delete list
  const [deleteSkillApplied, setDeleteSkillApplied] = useState([]);

  // validation delete list
  const [deleteValidation, setDeleteValidation] = useState([]);

  const [fileObj, setFileObj] = useState([]); //list of file obj [{name:'str.png',id:"dsd"}]

  const [isDeleting, setIsDeleting] = useState(false);
  const [isFetchingDeleteData, setIsFetchingDeleteData] = useState(false);

  useEffect(() => {
    const url = `${BASE_URL}/skill/api/v1/skills/delete/relationship`;

    const body = {
      parentTicketId: deleteDetailedProfile?.id,
      applicationName: deleteDetailedProfile?.applicationName,
    };
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "Content-Type": "application/json",
    };

    async function fetchData() {
      if (deleteDetailedProfile?.id) {
        setIsFetchingDeleteData(true);
        try {
          const response = await axios.post(url, body, { headers });
          console.log("relationship", response);

          if (Array.isArray(response?.data)) {
            setDeleteData(() => {
              return response?.data?.filter((data) => data.id);
            });
            setDeleteProjects(() => {
              return response?.data?.filter(
                (data) => data.applicationName === "Project  History"
              );
            });
            setDeleteSkillAcquired(() => {
              return response?.data?.filter(
                (data) => data.applicationName === "Skills Acquired"
              );
            });
            setDeleteSkillApplied(() => {
              return response?.data?.filter(
                (data) => data.applicationName === "Skills Applied"
              );
            });
            setDeleteValidation(() => {
              return response?.data?.filter(
                (data) => data.applicationName === "Validation Requests"
              );
            });
          }

          setIsFetchingDeleteData(false);
        } catch (err) {
          console.log(err);
          showErrorToast("Something Went Wrong");
          setIsFetchingDeleteData(false);
        }
      }
    }

    fetchData();
  }, [deleteDetailedProfile?.id]);

  // useEffect(() => {
  //     setDeleteSkillAcquired(() => {
  //         return deleteData?.filter((data) => data.applicationName === 'Skills Acquired')
  //     })
  //     setDeleteSkillApplied(() => {
  //         return deleteData?.filter((data) => data.applicationName === 'Skills Applied')
  //     })
  //     setDeleteValidation(() => {
  //         return deleteData?.filter((data) => data.applicationName === 'Validation Requests')
  //     })
  // }, [deleteData])

  const handleDelete = async () => {
    const url = `${BASE_URL}/skill/api/v1/skills/remove/${deleteDetailedProfile?.applicationName}`;

    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "Content-Type": "application/json",
    };

    setIsDeleting(true);

    try {
      if (deleteValidation.length > 0) {
        const response2 = await axios.post(
          `${BASE_URL}/skill/api/v1/skills/remove/Validation Requests`,
          { ticketids: deleteValidation.map((data) => data.id) },
          { headers }
        );
      }
      if (deleteSkillAcquired.length > 0) {
        const response2 = await axios.post(
          `${BASE_URL}/skill/api/v1/skills/remove/Skills Acquired`,
          { ticketids: deleteSkillAcquired.map((data) => data.id) },
          { headers }
        );
        dispatch(fetchDataSkillsAcquired());
      }
      if (deleteSkillApplied.length > 0) {
        const response2 = await axios.post(
          `${BASE_URL}/skill/api/v1/skills/remove/Skills Applied`,
          { ticketids: deleteSkillApplied.map((data) => data.id) },
          { headers }
        );
        dispatch(fetchDataSkillsApplied());
      }
      if (deleteProjects.length > 0) {
        const response2 = await axios.post(
          `${BASE_URL}/skill/api/v1/skills/remove/Project  History`,
          { ticketids: deleteProjects.map((data) => data.id) },
          { headers }
        );
        dispatch(FetchProjectHistory());
      }

      const response1 = await axios.post(
        `${BASE_URL}/skill/api/v1/skills/remove/${deleteDetailedProfile?.applicationName}`,
        { ticketids: [deleteDetailedProfile?.id] },
        { headers }
      );
      if (response1?.data.success === "true") {
        if (deleteDetailedProfile.applicationName === "Skills Acquired") {
                  
          const selectedSkill = topSkill?.data?.find((topSkill)=>topSkill?.skillOccupation === deleteDetailedProfile?.title )
          
          if(selectedSkill?.id){
            // after update into Myskill Experinece in DND
            const days = calculateDaysDifference(
              deleteDetailedProfile?.fromDate,
              deleteDetailedProfile?.toDate ? deleteDetailedProfile?.toDate : Date.now()
            );
            const totalDays =  Number(selectedSkill?.yoe) - days;
            const totalExp =
              (selectedSkill?.skillAcquiredExp
                ? Number(selectedSkill?.skillAcquiredExp)
                : 0) - days;
  
            EditApi("User Skills", selectedSkill?.id, {
              yoe: `${totalDays}`,
              skillAcquiredExp: `${totalExp}`,
            })
              .then((res) => {
                dispatch(
                  replaceTopSkillById({
                    id: selectedSkill?.id,
                    updatedData: res.data,
                  })
                );
                dispatch(editYoe(totalDays));
                dispatch(editAcquiredExp(totalExp));
              })
              .catch((err) => {
                console.error(err);
              });

          }
          dispatch(fetchDataSkillsAcquired());

        }
        if (deleteDetailedProfile.applicationName === "Skills Applied") {
          const selectedSkill = topSkill?.data?.find((topSkill)=>topSkill?.skillOccupation === deleteDetailedProfile?.title )
          
          if(selectedSkill?.id){
            // after update into Myskill Experinece in DND
            const days = calculateDaysDifference(
              deleteDetailedProfile?.fromDate,
              deleteDetailedProfile?.toDate ? deleteDetailedProfile?.toDate : Date.now()
            );
            const totalDays =  Number(selectedSkill?.yoe) - days;
            const totalExp =
              (selectedSkill?.skillAppliedExp
                ? Number(selectedSkill?.skillAppliedExp)
                : 0) - days;
  
            EditApi("User Skills", selectedSkill?.id, {
              yoe: `${totalDays}`,
              skillAppliedExp: `${totalExp}`,
            })
              .then((res) => {
                dispatch(
                  replaceTopSkillById({
                    id: selectedSkill?.id,
                    updatedData: res.data,
                  })
                );
                dispatch(editYoe(totalDays));
                dispatch(editAcquiredExp(totalExp));
              })
              .catch((err) => {
                console.error(err);
              });

          }
          dispatch(fetchDataSkillsApplied());
        }
        if (deleteDetailedProfile.applicationName === "Employment History") {
          dispatch(EmploymentDelete(deleteDetailedProfile.id));
        }
        if (deleteDetailedProfile.applicationName === "Project  History") {
          dispatch(ProjectDelete(deleteDetailedProfile.id));
        }
        if (deleteDetailedProfile.applicationName === "Education History") {
          dispatch(EducationDelete(deleteDetailedProfile.id));
        }
        if (deleteDetailedProfile.applicationName === "Certification History") {
          dispatch(CertificationDelete(deleteDetailedProfile.id));
        }
        if (deleteDetailedProfile.applicationName === "Skilling") {
          dispatch(SkillingDelete(deleteDetailedProfile.id));
        }
        if (deleteDetailedProfile.applicationName === "Training") {
          dispatch(TrainingDelete(deleteDetailedProfile.id));
        }
        if (deleteDetailedProfile.applicationName === "Conferences") {
          dispatch(ConferenceDelete(deleteDetailedProfile.id));
        }
        if (deleteDetailedProfile.applicationName === "Licenses") {
          dispatch(LicensesDelete(deleteDetailedProfile.id));
        }
        if (deleteDetailedProfile.applicationName === "Awards") {
          dispatch(AwardsDelete(deleteDetailedProfile.id));
        }
        if (deleteDetailedProfile.applicationName === "Memberships") {
          dispatch(MembershipDelete(deleteDetailedProfile.id));
        }
        if (deleteDetailedProfile.applicationName === "User Languages") {
          dispatch(KnownLanguagesDelete(deleteDetailedProfile.id));
        }
        if (deleteDetailedProfile.applicationName === "Others") {
          dispatch(OtherDelete(deleteDetailedProfile.id));
        }

        buttonRef?.current?.click();
        handleModalClose();
        showSuccessToast("Data Deleted Successfully");
      } else {
        console.log("Error in deleting data");
        showErrorToast("You already deleted this data");
      }
      setIsDeleting(false);
    } catch (err) {
      console.log(err);
      showErrorToast("Something Went Wrong");
      setIsDeleting(false);
    }
  };

  console.log(deleteData);
  console.log(deleteProjects);
  console.log(deleteSkillAcquired);
  console.log(deleteSkillApplied);
  console.log(deleteValidation);

  /* HANDLE MODAL CLOSE */
  const handleModalClose = useCallback(() => {
    buttonRef1?.current?.click();
  }, []);

  return (
    <>
      <div
        class="modal fade modal-xl"
        id="deleteformdetailedprofile"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-scrollable">
          <div class="modal-content">
            <div class="modal-header">
              <div>
                <p class="modal-title fs-5" id="exampleModalLabel">
                  {" "}
                  Detete{" "}
                </p>
              </div>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={buttonRef1}
              ></button>
            </div>
            <div class="modal-body">
              <div class="col-md-12">
                {deleteDetailedProfile?.applicationName ===
                  "Skills Applied" && (
                    <p className="">
                      You are Deleting{" "}
                      <span className="fw-bold mx-1 " style={{}}>
                        {deleteDetailedProfile.projectActivity}
                      </span>{" "}
                      from{" "}
                      <span className="fw-bold ms-1" style={{}}>
                        {deleteDetailedProfile.applicationName}
                      </span>{" "}
                    </p>
                  )}
                {deleteDetailedProfile?.applicationName ===
                  "Skills Acquired" && (
                    <p className="">
                      You are Deleting{" "}
                      <span className="fw-bold mx-1 " style={{}}>
                        {deleteDetailedProfile.source}
                      </span>{" "}
                      from{" "}
                      <span className="fw-bold ms-1" style={{}}>
                        {deleteDetailedProfile.applicationName}
                      </span>{" "}
                    </p>
                  )}
                {deleteDetailedProfile?.applicationName ===
                  "Project  History" && (
                    <p className="">
                      You are Deleting{" "}
                      <span className="fw-bold mx-1 " style={{}}>
                        {deleteDetailedProfile.projectActivity}
                      </span>{" "}
                      from{" "}
                      <span className="fw-bold ms-1" style={{}}>
                        {deleteDetailedProfile.applicationName}
                      </span>{" "}
                    </p>
                  )}
                {deleteDetailedProfile?.applicationName ===
                  "Education History" && (
                    <p className="">
                      You are Deleting{" "}
                      <span className="fw-bold mx-1 " style={{}}>
                        {deleteDetailedProfile.course}
                      </span>{" "}
                      from{" "}
                      <span className="fw-bold ms-1" style={{}}>
                        {deleteDetailedProfile.applicationName}
                      </span>{" "}
                    </p>
                  )}
                {deleteDetailedProfile?.applicationName ===
                  "Employment History" && (
                    <p className="">
                      You are Deleting{" "}
                      <span className="fw-bold mx-1 " style={{}}>
                        {deleteDetailedProfile.organization}
                      </span>{" "}
                      from{" "}
                      <span className="fw-bold ms-1" style={{}}>
                        {deleteDetailedProfile.applicationName}
                      </span>{" "}
                    </p>
                  )}
                {deleteDetailedProfile?.applicationName ===
                  "Certification History" && (
                    <p className="">
                      You are Deleting{" "}
                      <span className="fw-bold mx-1 " style={{}}>
                        {deleteDetailedProfile.certificationName}
                      </span>{" "}
                      from{" "}
                      <span className="fw-bold ms-1" style={{}}>
                        {deleteDetailedProfile.applicationName}
                      </span>{" "}
                    </p>
                  )}
                {deleteDetailedProfile?.applicationName === "Skilling" && (
                  <p className="">
                    You are Deleting{" "}
                    <span className="fw-bold mx-1 " style={{}}>
                      {deleteDetailedProfile?.skillingName ||
                        deleteDetailedProfile?.title}
                    </span>{" "}
                    from{" "}
                    <span className="fw-bold ms-1" style={{}}>
                      {deleteDetailedProfile.applicationName}
                    </span>{" "}
                  </p>
                )}
                {deleteDetailedProfile?.applicationName === "Training" && (
                  <p className="">
                    You are Deleting{" "}
                    <span className="fw-bold mx-1 " style={{}}>
                      {deleteDetailedProfile?.trainingName ||
                        deleteDetailedProfile?.title}
                    </span>{" "}
                    from{" "}
                    <span className="fw-bold ms-1" style={{}}>
                      {deleteDetailedProfile.applicationName}
                    </span>{" "}
                  </p>
                )}
                {deleteDetailedProfile?.applicationName === "Conferences" && (
                  <p className="">
                    You are Deleting{" "}
                    <span className="fw-bold mx-1 " style={{}}>
                      {deleteDetailedProfile?.conferencesName ||
                        deleteDetailedProfile?.title}
                    </span>{" "}
                    from{" "}
                    <span className="fw-bold ms-1" style={{}}>
                      {deleteDetailedProfile.applicationName}
                    </span>{" "}
                  </p>
                )}
                {deleteDetailedProfile?.applicationName === "Licenses" && (
                  <p className="">
                    You are Deleting{" "}
                    <span className="fw-bold mx-1 " style={{}}>
                      {deleteDetailedProfile.licenseName}
                    </span>{" "}
                    from{" "}
                    <span className="fw-bold ms-1" style={{}}>
                      {deleteDetailedProfile.applicationName}
                    </span>{" "}
                  </p>
                )}
                {deleteDetailedProfile?.applicationName === "Awards" && (
                  <p className="">
                    You are Deleting{" "}
                    <span className="fw-bold mx-1 " style={{}}>
                      {deleteDetailedProfile?.awardName ||
                        deleteDetailedProfile?.title}
                    </span>{" "}
                    from{" "}
                    <span className="fw-bold ms-1" style={{}}>
                      {deleteDetailedProfile.applicationName}
                    </span>{" "}
                  </p>
                )}
                {deleteDetailedProfile?.applicationName === "Memberships" && (
                  <p className="">
                    You are Deleting{" "}
                    <span className="fw-bold mx-1 " style={{}}>
                      {deleteDetailedProfile.title}
                    </span>{" "}
                    from{" "}
                    <span className="fw-bold ms-1" style={{}}>
                      {deleteDetailedProfile.applicationName}
                    </span>{" "}
                  </p>
                )}
                {deleteDetailedProfile?.applicationName ===
                  "User Languages" && (
                    <p className="">
                      You are Deleting{" "}
                      <span className="fw-bold mx-1 " style={{}}>
                        {deleteDetailedProfile?.userLanguage ||
                          deleteDetailedProfile?.title}
                      </span>{" "}
                      from{" "}
                      <span className="fw-bold ms-1" style={{}}>
                        {deleteDetailedProfile.applicationName}
                      </span>{" "}
                    </p>
                  )}
                {deleteDetailedProfile?.applicationName === "Others" && (
                  <p className="">
                    You are Deleting{" "}
                    <span className="fw-bold mx-1 " style={{}}>
                      {deleteDetailedProfile.title}
                    </span>{" "}
                    from{" "}
                    <span className="fw-bold ms-1" style={{}}>
                      {deleteDetailedProfile.applicationName}
                    </span>{" "}
                  </p>
                )}

                {isFetchingDeleteData ? (
                  <div
                    class="d-flex justify-content-center align-items-center"
                    style={{ height: "30vh" }}
                  >
                    <div
                      class="spinner-border"
                      style={{ width: "5rem", height: "5rem" }}
                      role="status"
                    >
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : (
                  <>
                    {deleteSkillApplied.length > 0 ||
                      deleteSkillAcquired.length > 0 ||
                      deleteValidation.length > 0 ? (
                      <>
                        <i>
                          {" "}
                          Note : Please note that deleting this entry will also
                          remove all associated records.
                        </i>

                        <div className="d-flex fw-bold gap-1 mt-3">
                          <p style={{ color: "var(--primary-color)" }}>
                            associated records :
                          </p>
                        </div>
                      </>
                    ) : (
                      <div>{/* Are you sure ? */}</div>
                    )}

                    <div class="accordion" id="accordionPanelsStayOpenExample">
                      {deleteProjects?.length > 0 && (
                        <div class="accordion-item">
                          <h2 class="accordion-header">
                            <button
                              class="accordion-button collapsed  "
                              style={{
                                backgroundColor: "var(--primary-color)",
                                color: "white",
                              }}
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#panelsStayOpen-collapsedeleteSix"
                              aria-expanded="true"
                              aria-controls="panelsStayOpen-collapsedeleteSix"
                            >
                              Projects
                            </button>
                          </h2>
                          <div
                            id="panelsStayOpen-collapsedeleteSix"
                            class={`accordion-collapse collapse show`}
                          >
                            <div class="accordion-body">
                              <table className="table table-sm   table-fixed table-hover    ">
                                <thead>
                                  <tr className="border-dark-subtle border-bottom ">
                                    <th
                                      scope="col"
                                      className="bg-body- "
                                      style={{ width: "32%" }}
                                    >
                                      {(
                                        content[selectedLanguage].find(
                                          (item) =>
                                            item.elementLabel === "ProjectName"
                                        ) || {}
                                      ).mvalue || "not found"}
                                    </th>
                                    <th
                                      scope="col"
                                      className="bg-body- "
                                      style={{ width: "19%" }}
                                    >
                                      {(
                                        content[selectedLanguage].find(
                                          (item) =>
                                            item.elementLabel ===
                                            "ProjectOrganization"
                                        ) || {}
                                      ).mvalue || "not found"}
                                    </th>
                                    <th
                                      scope="col"
                                      className="bg-body- "
                                      style={{ width: "13%" }}
                                    >
                                      {(
                                        content[selectedLanguage].find(
                                          (item) =>
                                            item.elementLabel ===
                                            "ProjectDuration"
                                        ) || {}
                                      ).mvalue || "not found"}
                                    </th>

                                    <th scope="col" style={{ width: "13%" }}>
                                      {(
                                        content[selectedLanguage].find(
                                          (item) =>
                                            item.elementLabel === "ProjecStart"
                                        ) || {}
                                      ).mvalue || "not found"}
                                    </th>
                                    <th scope="col" style={{ width: "13%" }}>
                                      {(
                                        content[selectedLanguage].find(
                                          (item) =>
                                            item.elementLabel === "ProjectEnd"
                                        ) || {}
                                      ).mvalue || "not found"}
                                    </th>
                                    <th
                                      scope="col"
                                      className="bg-body- "
                                      style={{ width: "14%" }}
                                    >
                                      {(
                                        content[selectedLanguage].find(
                                          (item) =>
                                            item.elementLabel ===
                                            "ProjectLocation"
                                        ) || {}
                                      ).mvalue || "not found"}{" "}
                                    </th>
                                    <th scope="col" style={{ width: "12%" }}>
                                      {(
                                        content[selectedLanguage].find(
                                          (item) =>
                                            item.elementLabel ===
                                            "ProjectValidation"
                                        ) || {}
                                      ).mvalue || "not found"}{" "}
                                    </th>
                                  </tr>
                                </thead>
                                <tbody className="">
                                  {deleteProjects?.map((proj, index) => (
                                    <>
                                      {proj.mlanguage === selectedLanguage && (
                                        <tr key={index} className="">
                                          <td>
                                            {proj.projectActivity
                                              ? proj.projectActivity.length > 17
                                                ? proj.projectActivity.substring(
                                                  0,
                                                  17
                                                ) + "..."
                                                : proj.projectActivity
                                              : ""}
                                          </td>
                                          <td>
                                            {proj.organization
                                              ? proj.organization.length > 17
                                                ? proj.organization.substring(
                                                  0,
                                                  17
                                                ) + "..."
                                                : proj.organization
                                              : "NA"}
                                          </td>
                                          <td>
                                            {" "}
                                            {DayDifferenceToDynamicView(
                                              proj.duration
                                            )}
                                          </td>
                                          <td>
                                            {formatTimestampToDate(
                                              proj.fromDate
                                            )}
                                          </td>
                                          <td>
                                            {proj.toDate
                                              ? formatTimestampToDate(
                                                proj.toDate,
                                                "mm/dd/yyyy"
                                              )
                                              : "on-going"}
                                          </td>
                                          <td
                                            data-tooltip-id="my-tooltip"
                                            data-tooltip-content={proj.location}
                                          >
                                            {proj.location
                                              ? proj.location.length > 12
                                                ? proj.location.substring(
                                                  0,
                                                  12
                                                ) + "..."
                                                : proj.location
                                              : ""}
                                          </td>
                                          <td className=" ">
                                            {proj.validation}
                                          </td>
                                        </tr>
                                      )}
                                    </>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      )}

                      {deleteSkillAcquired?.length > 0 && (
                        <div class="accordion-item">
                          <h2 class="accordion-header">
                            <button
                              class="accordion-button collapsed  "
                              style={{
                                backgroundColor: "var(--primary-color)",
                                color: "white",
                              }}
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#panelsStayOpen-collapsedeleteOne"
                              aria-expanded="true"
                              aria-controls="panelsStayOpen-collapsedeleteOne"
                            >
                              Skills Acquired
                            </button>
                          </h2>
                          <div
                            id="panelsStayOpen-collapsedeleteOne"
                            class={`accordion-collapse collapse show`}
                          >
                            <div class="accordion-body">
                              <table className="table table-sm   table-fixed table-hover    ">
                                <thead>
                                  <tr className="">
                                    <th scope="col" style={{ width: "20%" }}>
                                      {(
                                        content[selectedLanguage].find(
                                          (item) => item.elementLabel === "Name"
                                        ) || {}
                                      ).mvalue || "Name"}
                                    </th>
                                    <th scope="col" style={{ width: "20%" }}>
                                      {(
                                        content[selectedLanguage].find(
                                          (item) =>
                                            item.elementLabel === "SkillName"
                                        ) || {}
                                      ).mvalue || "nf Skill Name"}
                                    </th>
                                    <th scope="col" style={{ width: "13%" }}>
                                      {(
                                        content[selectedLanguage].find(
                                          (item) =>
                                            item.elementLabel ===
                                            "ProjectDuration"
                                        ) || {}
                                      ).mvalue || "not found"}
                                    </th>
                                    <th scope="col" style={{ width: "13%" }}>
                                      {(
                                        content[selectedLanguage].find(
                                          (item) =>
                                            item.elementLabel === "ProjecStart"
                                        ) || {}
                                      ).mvalue || "not found"}
                                    </th>
                                    <th scope="col" style={{ width: "13%" }}>
                                      {(
                                        content[selectedLanguage].find(
                                          (item) =>
                                            item.elementLabel === "ProjectEnd"
                                        ) || {}
                                      ).mvalue || "not found"}
                                    </th>
                                    {/* <th scope="col" style={{ width: "17%" }}>
                                                            {(
                                                                content[selectedLanguage].find(
                                                                    (item) => item.elementLabel === "ProjectType"
                                                                ) || {}
                                                            ).mvalue || "not found"}
                                                        </th> */}
                                    <th scope="col" style={{ width: "15%" }}>
                                      {(
                                        content[selectedLanguage].find(
                                          (item) =>
                                            item.elementLabel ===
                                            "ProjectLocation"
                                        ) || {}
                                      ).mvalue || "not found"}
                                    </th>
                                    <th scope="col" style={{ width: "10%" }}>
                                      {(
                                        content[selectedLanguage].find(
                                          (item) =>
                                            item.elementLabel ===
                                            "ProjectValidation"
                                        ) || {}
                                      ).mvalue || "not found"}
                                    </th>
                                  </tr>
                                  {/* horizontal line */}
                                  <tr
                                    style={{
                                      borderColor: "gray",
                                      borderWidth: "1px",
                                    }}
                                  />
                                </thead>

                                <tbody className="">
                                  {deleteSkillAcquired.map((skill, index) => (
                                    <>
                                      {skill.mlanguage === selectedLanguage && (
                                        <tr className="" key={index}>
                                          <td
                                            data-tooltip-id="my-tooltip"
                                            data-tooltip-content={skill.source}
                                          >
                                            {skill.source
                                              ? skill.source.length > 17
                                                ? skill.source.substring(
                                                  0,
                                                  17
                                                ) + "..."
                                                : skill.source
                                              : ""}
                                          </td>
                                          <td
                                            data-tooltip-id="my-tooltip"
                                            data-tooltip-content={skill.title}
                                          >
                                            {skill.title
                                              ? skill.title.length > 17
                                                ? skill.title.substring(0, 17) +
                                                "..."
                                                : skill.title
                                              : ""}
                                          </td>
                                          <td>
                                            {DayDifferenceToDynamicView(
                                              skill.duration
                                            )}
                                          </td>
                                          <td>
                                            {formatTimestampToDate(
                                              skill.fromDate,
                                              "mm/dd/yyyy"
                                            )}
                                          </td>
                                          <td>
                                            {skill.toDate
                                              ? formatTimestampToDate(
                                                skill.toDate,
                                                "mm/dd/yyyy"
                                              )
                                              : "on-going"}
                                          </td>
                                          {/* <td>{skill.mtype}</td> */}
                                          <td
                                            data-tooltip-id="my-tooltip"
                                            data-tooltip-content={
                                              skill.location
                                            }
                                          >
                                            {skill.location
                                              ? skill.location.length > 12
                                                ? skill.location.substring(
                                                  0,
                                                  12
                                                ) + "..."
                                                : skill.location
                                              : ""}
                                          </td>
                                          <td className=" ">
                                            {skill.validation}
                                          </td>
                                        </tr>
                                      )}
                                    </>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      )}

                      {deleteSkillApplied?.length > 0 && (
                        <div class="accordion-item">
                          <h2 class="accordion-header">
                            <button
                              class="accordion-button collapsed  "
                              style={{
                                backgroundColor: "var(--primary-color)",
                                color: "white",
                              }}
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#panelsStayOpen-collapsedeleteFive"
                              aria-expanded="true"
                              aria-controls="panelsStayOpen-collapsedeleteFive"
                            >
                              Skills Applied
                            </button>
                          </h2>
                          <div
                            id="panelsStayOpen-collapsedeleteFive"
                            class={`accordion-collapse collapse show`}
                          >
                            <div class="accordion-body">
                              <table className="table table-sm   table-fixed table-hover    ">
                                <thead>
                                  <tr className="">
                                    <th scope="col" style={{ width: "20%" }}>
                                      {(
                                        content[selectedLanguage].find(
                                          (item) => item.elementLabel === "Name"
                                        ) || {}
                                      ).mvalue || "Name"}
                                    </th>
                                    <th scope="col" style={{ width: "20%" }}>
                                      {(
                                        content[selectedLanguage].find(
                                          (item) =>
                                            item.elementLabel === "SkillName"
                                        ) || {}
                                      ).mvalue || "nf Skill Name"}
                                    </th>
                                    <th scope="col" style={{ width: "13%" }}>
                                      {(
                                        content[selectedLanguage].find(
                                          (item) =>
                                            item.elementLabel ===
                                            "ProjectDuration"
                                        ) || {}
                                      ).mvalue || "not found"}
                                    </th>
                                    <th scope="col" style={{ width: "13%" }}>
                                      {(
                                        content[selectedLanguage].find(
                                          (item) =>
                                            item.elementLabel === "ProjecStart"
                                        ) || {}
                                      ).mvalue || "not found"}
                                    </th>
                                    <th scope="col" style={{ width: "13%" }}>
                                      {(
                                        content[selectedLanguage].find(
                                          (item) =>
                                            item.elementLabel === "ProjectEnd"
                                        ) || {}
                                      ).mvalue || "not found"}
                                    </th>
                                    {/* <th scope="col" style={{ width: "17%" }}>
                                                            {(
                                                                content[selectedLanguage].find(
                                                                    (item) => item.elementLabel === "ProjectType"
                                                                ) || {}
                                                            ).mvalue || "not found"}
                                                        </th> */}
                                    <th scope="col" style={{ width: "15%" }}>
                                      {(
                                        content[selectedLanguage].find(
                                          (item) =>
                                            item.elementLabel ===
                                            "ProjectLocation"
                                        ) || {}
                                      ).mvalue || "not found"}
                                    </th>
                                    <th scope="col" style={{ width: "10%" }}>
                                      {(
                                        content[selectedLanguage].find(
                                          (item) =>
                                            item.elementLabel ===
                                            "ProjectValidation"
                                        ) || {}
                                      ).mvalue || "not found"}
                                    </th>
                                  </tr>
                                  {/* horizontal line */}
                                  <tr
                                    style={{
                                      borderColor: "gray",
                                      borderWidth: "1px",
                                    }}
                                  />
                                </thead>

                                <tbody className="">
                                  {deleteSkillApplied.map((skill, index) => (
                                    <>
                                      {skill.mlanguage === selectedLanguage && (
                                        <tr className="" key={index}>
                                          <td
                                            data-tooltip-id="my-tooltip"
                                            data-tooltip-content={
                                              skill.projectActivity
                                            }
                                          >
                                            {skill.projectActivity
                                              ? skill.projectActivity.length >
                                                17
                                                ? skill.projectActivity.substring(
                                                  0,
                                                  17
                                                ) + "..."
                                                : skill.projectActivity
                                              : ""}
                                          </td>
                                          <td
                                            data-tooltip-id="my-tooltip"
                                            data-tooltip-content={skill.title}
                                          >
                                            {skill.title
                                              ? skill.title.length > 17
                                                ? skill.title.substring(0, 17) +
                                                "..."
                                                : skill.title
                                              : ""}
                                          </td>
                                          <td>
                                            {DayDifferenceToDynamicView(
                                              skill.duration
                                            )}
                                          </td>
                                          <td>
                                            {formatTimestampToDate(
                                              skill.fromDate,
                                              "mm/dd/yyyy"
                                            )}
                                          </td>
                                          <td>
                                            {skill.toDate
                                              ? formatTimestampToDate(
                                                skill.toDate,
                                                "mm/dd/yyyy"
                                              )
                                              : "on-going"}
                                          </td>
                                          {/* <td>{skill.mtype}</td> */}
                                          <td
                                            data-tooltip-id="my-tooltip"
                                            data-tooltip-content={
                                              skill.location
                                            }
                                          >
                                            {skill.location
                                              ? skill.location.length > 12
                                                ? skill.location.substring(
                                                  0,
                                                  12
                                                ) + "..."
                                                : skill.location
                                              : ""}
                                          </td>
                                          <td className=" ">
                                            {skill.validation}
                                          </td>
                                        </tr>
                                      )}
                                    </>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      )}

                      {deleteValidation?.length ? (
                        <div class="accordion-item">
                          <h2 class="accordion-header">
                            <button
                              class="accordion-button collapsed  "
                              style={{
                                backgroundColor: "var(--primary-color)",
                                color: "white",
                              }}
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#panelsStayOpen-collapsedeleteThree"
                              aria-expanded="true"
                              aria-controls="panelsStayOpen-collapsedeleteThree"
                            >
                              {contentLabel('MyValidations', 'nf MyValidations')}
                            </button>
                          </h2>
                          <div
                            id="panelsStayOpen-collapsedeleteThree"
                            class={`accordion-collapse collapse show`}
                          >
                            <div class="accordion-body">
                              <table className="table table-hover">
                                <thead
                                  style={{
                                    position: "sticky",
                                    top: "0",
                                    zIndex: "2",
                                  }}
                                >
                                  <tr>
                                    <th className="fw-bold">
                                      {contentLabel("DateSent", "nf Date Sent")}
                                    </th>
                                    <th className="fw-bold">
                                      {contentLabel(
                                        "ProjeValidator",
                                        "nf ProjeValidator"
                                      )}
                                    </th>
                                    <th className="fw-bold">
                                      {contentLabel(
                                        "ProjectValidatorRelationship",
                                        "nf ProjectValidatorRelationship"
                                      )}
                                    </th>
                                    <th className="fw-bold">
                                      {contentLabel("Email", "nf Email")}
                                    </th>
                                    <th className="fw-bold">
                                      {contentLabel("Phone", "nf Phone")}
                                    </th>
                                    <th className="fw-bold">
                                      {contentLabel(
                                        "ValidationStatus",
                                        "nf ValidationStatus"
                                      )}
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {deleteValidation?.length ? (
                                    deleteValidation.map(
                                      (validatior, index) => (
                                        <React.Fragment key={index}>
                                          <tr
                                            style={{
                                              cursor: "pointer",
                                              pointerEvents: `${validatior?.validatorResponse ===
                                                  "Request Expired"
                                                  ? "none"
                                                  : ""
                                                }`,
                                              opacity: `${validatior?.validatorResponse ===
                                                  "Request Expired"
                                                  ? "0.5"
                                                  : ""
                                                }`,
                                            }}
                                            data-tooltip-id="my-tooltip"
                                            data-tooltip-place="top"
                                            data-tooltip-content={`Click to view validation details`}
                                          >
                                            <td>
                                              {formatTimestampToDate(
                                                Number(validatior?.dateSent),
                                                "mm/dd/yyyy"
                                              )}
                                            </td>
                                            <td>{validatior.validatorName}</td>
                                            <td>{validatior.relationship}</td>
                                            <td>{validatior.validatorEmail}</td>
                                            <td>
                                              {validatior.validatorPhone
                                                ? validatior.validatorPhone
                                                : "N/A"}
                                            </td>
                                            <td>
                                              {validatior?.validatorResponse ===
                                                "Request Sent" ||
                                                validatior?.validatorResponse ===
                                                "Request Expired"
                                                ? validatior?.validatorResponse
                                                : validatior?.validatorResponse ===
                                                  "Resend"
                                                  ? "Resent"
                                                  : `${validatior?.validatorResponse}ed`}
                                            </td>
                                          </tr>
                                        </React.Fragment>
                                      )
                                    )
                                  ) : (
                                    <tr></tr>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div></div>
                      )}

                      {/* {fileObj && fileObj.length > 0 &&
                                    <div class="accordion-item " >
                                        <h2 class="accordion-header " >
                                            <button class="accordion-button collapsed " style={{ backgroundColor: 'var(--primary-color)', color: 'white', }} type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapsedeleteTwo" aria-expanded='true' aria-controls="panelsStayOpen-collapsedeleteTwo">
                                                Attachments
                                            </button>
                                        </h2>
                                        <div id="panelsStayOpen-collapsedeleteTwo" class={`accordion-collapse collapse show`}>
                                            <div class="accordion-body">


                                               

                                                <React.Fragment>

                                                    <div className='mt-2 rounded-3 p-0'  >
                                                        {fileObj.map((file, i) => file !== undefined &&
                                                            <div className='mt-2 mb-2 badge  d-flex rounded-0 p-0' key={i}
                                                                style={{
                                                                    borderBottom: "solid 1px",
                                                                    textAlign: "left", borderStyle: "solid", borderWidth: "0px",
                                                                    width: "100%", color: "#815F0B",
                                                                    height: "30px", fontSize: "16px"
                                                                }} >

                                                                <div>
                                                                    {file.name}
                                                                </div>
                                                                <div className='mx-2'>

                                                                    <a rel="noreferrer" href={file.name.includes(".pdf") ? `${attachmentPdfBaseUrl}${data?.id}/${file.name}?authToken=${token}` :
                                                                        `${attachmentImgBaseUrl}${data?.id}/${file.name}?authToken=${token}`} target="_blank" >
                                                                        <MdOpenInNew className='' style={{ color: "#577126", height: "16px", width: "16px" }} />
                                                                    </a>
                                                                </div>


                                                            </div>)}
                                                    </div>
                                                </React.Fragment>




                                            </div>
                                        </div>
                                    </div>
                                } */}
                    </div>
                  </>
                )}
              </div>
              <div className="d-flex justify-content-end py-0 m-3  ">
                <div className="d-flex gap-2">
                  <button
                    className="btn btn-secondary"
                    onClick={handleModalClose}
                  >
                    {(
                      content[selectedLanguage].find(
                        (item) => item.elementLabel === "Cancel"
                      ) || {}
                    ).mvalue || "nf Cancel"}
                  </button>
                  <button
                    className="btn btn-primary"
                    data-bs-toggle="modal"
                    data-bs-target="#areyousure"
                  >
                    {" "}
                    {(
                      content[selectedLanguage].find(
                        (item) => item.elementLabel === "Delete"
                      ) || {}
                    ).mvalue || "nf Delete"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal fade modal"
        id="areyousure"
        style={{ marginTop: "50px" }}
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-scrollable">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="exampleModalLabel">
                {contentLabel("Delete", "nf Delete")}
              </h1>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={buttonRef}
              ></button>
            </div>
            <div class="modal-body">
              <div className="d-flex gap-2  align-items-center">
                <div>
                  <img src={warningImg} alt="warning img" width={"30px"} />
                </div>
                <div>{contentLabel("AreYouSure?", "nf AreYouSure?")}</div>
              </div>
            </div>
            <div className="d-flex justify-content-end py-0 m-3  ">
              <div className="d-flex gap-2">
                <button
                  className="btn btn-primary"
                  onClick={() => buttonRef?.current?.click()}
                  disable={isDeleting ? true : false}
                >
                  {(
                    content[selectedLanguage].find(
                      (item) => item.elementLabel === "No"
                    ) || {}
                  ).mvalue || "nf No"}
                </button>
                <button
                  className="btn btn-secondary"
                  onClick={() => handleDelete()}
                >
                  {isDeleting && (
                    <span
                      class="spinner-border spinner-border-sm me-2"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                  {(
                    content[selectedLanguage].find(
                      (item) => item.elementLabel === "Yes"
                    ) || {}
                  ).mvalue || "nf Yes"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeleteFormDetailedProfile;
