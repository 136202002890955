import { createAsyncThunk } from "@reduxjs/toolkit";
import { BASE_URL } from "../../config/Properties";

export const fetchCourseSelectedData = createAsyncThunk(
    "fetchCourseSelectedData/fetchData",
    async (body) => {
        try {
            const token = localStorage.getItem("token");
            const userId = localStorage.getItem("userId");

            const response = await fetch(`${BASE_URL}/skill/api/v1/skills/Courses/CourseResponse?filter=userCourseId%3A${body.courseId}${body.preFilter ? body.preFilter : ""}${body.filter ? body.filter : ""}&start=${body.start}&size=${body.size}&sortField=${body.sortField}&sortOrder=${body.sortOrder}&authToken=${token}`);

            const data = await response.json();
            return data;
        } catch (error) {
            throw error;
        }
    }
);
