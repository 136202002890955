import { createSlice } from "@reduxjs/toolkit";

const editCourseSlice = createSlice({
    name: "editCourse",
    initialState: {
    },
    reducers: {
        seteditCourseData: (state, action) => action.payload
    }
    
});

export const { seteditCourseData } = editCourseSlice.actions;
export default editCourseSlice.reducer;