import React, { useMemo } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { FaAngleRight } from "react-icons/fa6";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import BackToTop from "../../components/BackToTop/BackToTop";
import DeleteFormDetailedProfile from "../../components/DeleteFormDetailedProfile";
import CheckoutStepper from "../../components/Steppers/Stepper";
import useContentLabel from "../../hooks/useContentLabel";

const ProfileManagement = ({ children }) => {
  const contentLabel = useContentLabel();
  const { pathname } = useLocation();
  //store
  const content = useSelector((state) => state.content);
  const selectedLanguage = useSelector((state) => state.language);
  const regionalData = useSelector((state) => state.regionalData);

  const steps = [
    {
      name: contentLabel("MyProfile", "nf MyProfile"),
      number: 1,
      link: "/owner/profile-management/profile-info",
      Component: () => <div>{contentLabel("step", "step")}1</div>,
    },
    {
      name: contentLabel("Employment", "nf Employment"),
      number: 2,
      link: "/owner/profile-management/employment",
      Component: () => <div>{contentLabel("step", "step")}2</div>,
    },
    {
      name: contentLabel("MyProjects", "nf MyProjects"),
      number: 3,
      link: "/owner/profile-management/work-details",
      Component: () => <div>{contentLabel("step", "step")}3</div>,
    },
    {
      name: contentLabel("Learnings", "nf Learnings"),
      number: 4,
      link: "/owner/profile-management/learnings",
      Component: () => <div>{contentLabel("step", "step")}4</div>,
    },
    {
      name: contentLabel("Skills", "nf Skills"),
      number: 5,
      link: "/owner/profile-management/skills",
      Component: () => <div> {contentLabel("step", "step")}5</div>,
    },
    {
      name: contentLabel("Next", "nf Next"),
      number: <FaAngleRight />,
      link: "/owner/skill-mapping/skills-acquired",
      Component: () => <div> {contentLabel("step", "step")}6</div>,
    },
  ];

  //   const steps = [
  //     {
  //       name: `${(content[selectedLanguage].find(item => item.elementLabel === 'ProfileInfo') || {}).mvalue || "nf EProfileInfo"}`,
  //       number: 1,
  //       link: '/profile-management/profile-info',
  //       Component: () => <div>step1</div>,
  //     },
  //     {
  //       name: `${(content[selectedLanguage].find(item => item.elementLabel === 'EmploymentHistory') || {}).mvalue || "nf Employment History"}`,
  //       number: 2,
  //       link: '/profile-management/employment',
  //       Component: () => <div>step2</div>,
  //     },
  //     {
  //       name: `${(content[selectedLanguage].find(item => item.elementLabel === 'ProjectHistory') || {}).mvalue || "nf Project History"}`,
  //       number: 3,
  //       link: '/profile-management/work-details',
  //       Component: () => <div>step3</div>,
  //     },
  //     {
  //       name: `${(content[selectedLanguage].find(item => item.elementLabel === 'Learnings') || {}).mvalue || "nf ELearnings"}`,
  //       number: 4,
  //       link: '/profile-management/learnings',
  //       Component: () => <div>step4</div>,
  //     },
  //     {
  //       name: `${(content[selectedLanguage].find(item => item.elementLabel === 'Skills') || {}).mvalue || "nf ESkills"}`,
  //       number: 5,
  //       link: '/profile-management/skills',
  //       Component: () => <div> step5</div>,
  //     },
  //     {
  //       name: `${(content[selectedLanguage].find(item => item.elementLabel === 'Next') || {}).mvalue || "nf Next"}`,
  //       number: <FaAngleRight />,
  //       link: '/skill-mapping/skills-applied',
  //       Component: () => <div> step6</div>,
  //     },
  //   ];

  const activePath = useMemo(() => {
    if (pathname === "/owner/profile-management/profile-info") {
      return contentLabel("MyProfile", "nf Profile");
    }
    if (pathname === "/owner/profile-management/employment") {
      return contentLabel("Employment", "nf Employment");
    }
    if (pathname === "/owner/profile-management/work-details") {
      return contentLabel("MyProjects", "nf MyProjects");
    }
    if (pathname === "/owner/profile-management/learnings") {
      return contentLabel("Learnings", "nf Learnings");
    }
    if (pathname === "/owner/profile-management/skills") {
      return contentLabel("Skills", "nf Skills");
    }
  }, [pathname, contentLabel]);

  return (
    <div class="content-body">
      <div class="container-fluid">
        {/* <Outlet />  */}

        <DeleteFormDetailedProfile />

        <div className="profile-management-layout">
          <div class="row page-titles card" style={{ backgroundColor: "#fff" }}>
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="javascript:void(0);">
                  {contentLabel("ProfileManagement", "Profile Management")}
                </a>
              </li>
              <li class="breadcrumb-item active">
                <a href="javascript:void(0);">{activePath}</a>
              </li>
            </ol>
          </div>

          <Row>
            <Col sm={12}>
              <Card className="p-4">
                <CheckoutStepper steps={steps} />
              </Card>
            </Col>
          </Row>

          {children}
        </div>
      </div>
      <BackToTop />
    </div>
  );
};

export default ProfileManagement;
