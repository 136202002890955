import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../config/Properties";

export const fetchCategory = createAsyncThunk('category/fetchData', async () => {
    try {
        const res = await axios.get(`${BASE_URL}/skill/api/v1/skills/get-master-data/Category%20Map`)
        return res.data;
    } catch (error) {
        throw error;
    }
});

const categorySlice = createSlice({
    name: 'category',
    initialState: {
        specificCheckbox: [],
        loading: false,
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchCategory.pending, (state) => {
                state.loading = true;
                state.error = null
            })
            .addCase(fetchCategory.fulfilled, (state, action) => {
                state.loading = false;
                state.specificCheckbox = action.payload;
            })
            .addCase(fetchCategory.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message
            });
    }
});

export default categorySlice.reducer;