import React from "react";
import { Button } from "react-bootstrap";

const CustomButton = ({ title, disabled, onClick }) => {
  return (
    <Button variant="primary" disabled={disabled} onClick={onClick}>
      {title}
    </Button>
  );
};

export default CustomButton;
