import React, { useMemo } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import CheckoutStepper from '../../components/Steppers/Stepper';
import BackToTop from '../../components/BackToTop/BackToTop';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import icons from './../../constants/icons';
import useContentLabel from '../../hooks/useContentLabel';

const SkillSeekerBreadCrumb = ({ children }) => {
    const contentLabel = useContentLabel();
    const { pathname, search } = useLocation();
    const queryParams = new URLSearchParams(search);
    const navigate = useNavigate();
    const jdStore = useSelector(state => state.JdDataSlice);

    // const steps = [
    //     {
    //         name: "Profile Info",
    //         number: 1,
    //         link: '/profile-management/profile-info',
    //         Component: () => <div>step1</div>,
    //     },
    //     {
    //         name: "Employment",
    //         number: 2,
    //         link: '/profile-management/employment',
    //         Component: () => <div>step2</div>,
    //     },
    //     {
    //         name: "Work Details",
    //         number: 3,
    //         link: '/profile-management/work-details',
    //         Component: () => <div>step3</div>,
    //     },
    //     {
    //         name: "Learnings",
    //         number: 4,
    //         link: '/profile-management/learnings',
    //         Component: () => <div>step4</div>,
    //     },
    //     {
    //         name: "Skills",
    //         number: 5,
    //         link: '/profile-management/skills',
    //         Component: () => <div> step5</div>,
    //     },
    //     {
    //         name: "Next",
    //         number: <FaAngleRight />,
    //         link: '/skill-mapping/skills-applied',
    //         Component: () => <div> step6</div>,
    //     },
    // ];


    const activePath = useMemo(() => {

        if (pathname === '/skillSeeker/Opportunities') {
            return {
                label: contentLabel("Opportunities", "nf Opportunities"),
                path: "/skillSeeker/Opportunities",
                isArray: false,

            }
        }

        if (pathname.includes('/skillSeeker/Opportunities')) {
            return {
                label: contentLabel("Opportunities", "nf Opportunities"),
                isArray: true,
                path: "/skillSeeker/Opportunities",

            }
        }



        if (pathname.includes('/skillSeeker/Skill-Search')) {
            return {
                label: contentLabel("SkillSearch", "nf Skill Search"),
                path: "/skillSeeker/Skill-Search",
                isArray: false,
            }
        }
        if (pathname === '/skillSeeker/Candidate-management') {
            return {
                label: contentLabel("CandidateManagement", "nf Candidate Management"),
                path: "/skillSeeker/Candidate-management",
                isArray: false,

            }
        }
        if (pathname === '/skillSeeker/learnings') {
            return {
                label: 'Learning',
                path: "/skillSeeker/learnings",
                isArray: false,

            }
        }
        if (pathname === '/skillSeeker/skills') {
            return {
                label: 'Skills',
                path: "/skillSeeker/skills",
                isArray: false,

            }
        }
    }, [pathname])

    return (
        <div class="content-body">
            <div class="container-fluid">

                {/* <Outlet />  */}

                <div >
                    {

                        <div className='d-flex mb-1 mt-0   ' style={{ color: "#875712", cursor: "pointer" }} >
                            <p className='  text-decoration-underline fw-bold me-1  ' onClick={() => navigate(activePath?.path)} >{activePath?.label}</p>


                            {/* Create Opportunity  */}
                            {
                                pathname.includes('/skillSeeker/Opportunities/Create') &&


                                <React.Fragment>
                                    <icons.FaAngleRight size={22} />    <p className='  text-decoration-underline fw-bold me-1  ' onClick={() => navigate(activePath?.path)} >{contentLabel("Create", "nf Create")}</p>
                                </React.Fragment>

                            }
                            {/* Edit Opportunity  */}
                            {
                                pathname.includes('/skillSeeker/Opportunities/Edit') &&


                                <React.Fragment>
                                    <icons.FaAngleRight size={22} />    <p className='  text-decoration-underline fw-bold me-1  ' onClick={() => navigate(activePath?.path)} >{contentLabel("Edit", "nf Edit")}</p>
                                </React.Fragment>

                            }

                            {/* Selected Opportunity In BreadCrumb */}
                            {
                                jdStore.SelectedJd &&
                                jdStore.SelectedJd.hasOwnProperty("title") &&
                                (pathname === '/skillSeeker/Opportunities' || pathname === '/skillSeeker/Opportunities/Candidate-management' || pathname === '/skillSeeker/Opportunities/Edit') &&

                                <React.Fragment>
                                    <icons.FaAngleRight size={22} /> <p className='ms-1  text-decoration-underline fw-bold'  >{jdStore?.SelectedJd?.title}</p>
                                </React.Fragment>

                            }

                            {/* Skill search sub menus  */}


                            {
                                //   Access DB
                                pathname.includes('/skillSeeker/Skill-Search/Database-Access') ?


                                    <React.Fragment>

                                        <icons.FaAngleRight size={22} />
                                        <p className='  text-decoration-underline fw-bold me-1  '
                                            onClick={() => navigate('/skillSeeker/Skill-Search/Database-Access')}
                                        >
                                            {contentLabel("DatabaseAccess", "nf Database Access")}
                                        </p>

                                        {
                                            //       navigate(`/skillSeeker/Skill-Search/Database-Access?Title=${title}&Id=${id}&Saved=${false}`);
                                            queryParams.get("Title") &&
                                            <React.Fragment>

                                                <icons.FaAngleRight size={22} />    <p className='  text-decoration-underline fw-bold me-1  '
                                                //   onClick={() => navigate('/skillingagency/my-courses')}
                                                >{queryParams.get("Title")}</p>
                                            </React.Fragment>
                                        }

                                    </React.Fragment>


                                    :
                                    //  Saved-Searches
                                    pathname.includes('/skillSeeker/Skill-Search/Saved-Searches') &&


                                    <React.Fragment>
                                        <icons.FaAngleRight size={22} />
                                        <p className='  text-decoration-underline fw-bold me-1  '
                                            onClick={() => navigate('/skillSeeker/Skill-Search/Saved-Searches')}
                                        >
                                            {contentLabel("SavedSearches", "nf Saved Searches")}
                                        </p>
                                    </React.Fragment>

                            }


                            {/* Selected saved Search In BreadCrumb */}
                            {
                                queryParams.get("Title") &&

                                (pathname === '/skillSeeker/Skill-Search/Saved-Searches' ||
                                    pathname === '/skillSeeker/Skill-Search/Saved-Searches/Candidate-management' ||
                                    pathname === '/skillSeeker/Skill-Search/Saved-Searches/Edit')
                                &&

                                <React.Fragment>
                                    <icons.FaAngleRight size={22} /> <p className='ms-1  text-decoration-underline fw-bold'
                                    // onClick={() => navigate('/skillSeeker/Skill-Search/Saved-Searches')}
                                    >
                                        {queryParams.get("Title")}</p>
                                </React.Fragment>

                            }

                            {/* In Candidate management  */}
                            {
                                pathname.includes('Candidate-management') &&


                                <React.Fragment>
                                    <icons.FaAngleRight size={22} />
                                    <p className='  text-decoration-underline fw-bold me-1  '
                                    // onClick={() => navigate(activePath?.path)}
                                    >
                                        {contentLabel("CandidateManagement", "nf Candidate Management")}
                                    </p>
                                </React.Fragment>

                            }

                        </div>
                    }




                    {/* <Row>
                        <Col sm={12}>
                            <Card className='p-4'>
                                <CheckoutStepper steps={steps} />
                            </Card>
                        </Col>
                    </Row> */}

                    {children}

                </div>
            </div>
            <BackToTop />
        </div>
    )
}

export default SkillSeekerBreadCrumb