
import React, { useEffect, useMemo } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { Link, Outlet, useLocation, useParams } from 'react-router-dom';
import BackToTop from '../../components/BackToTop/BackToTop';
import CheckoutStepper from '../../components/Steppers/Stepper';
import { FaAngleLeft } from 'react-icons/fa6';
import { useDispatch } from 'react-redux';
import selectedOpportunity, { setSelectedOpportunityData } from '../../reducer/opportunities/selectedOpportunity';
import { setSelectedUpSkillingData } from '../../reducer/upskilling/selectedUpSkilling';

const UpSkillings = ({ children }) => {
    const { pathname } = useLocation()
    const { id, name } = useParams()
    const dispatch = useDispatch()

    const activePath = useMemo(() => {
        if (pathname === '/owner/up-skilling/view-courses' || name) {
            return 'View Courses'
        }
    }, [pathname])

    useEffect(() => {
        dispatch(setSelectedUpSkillingData(null))
    }, [pathname])


    return (
        <div class="content-body">
            <div class="container-fluid">

                {/* <Outlet />  */}

                <div className="profile-management-layout">
                    <div class="row page-titles card" style={{ backgroundColor: '#fff' }}>
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="javascript:void(0);">Up skilling</a></li>
                            <li class={`breadcrumb-item  ${name ? '' : 'active'}`} ><Link to={'/owner/up-skilling/view-courses'}>{activePath}</Link></li>
                            {name && <li class="breadcrumb-item active"><a href="javascript:void(0);">{name}</a></li>}
                        </ol>
                    </div>


                    {children}

                </div>
            </div>
            <BackToTop />
        </div>
    );
}

export default UpSkillings
