import { lazy } from 'react';
import SkillMapping from '../views/skill mapping/SkillMapping';

const skillMappingRoutes = [
  {
    exact: 'true',
    path: 'skills-applied',
    layout: SkillMapping,
    element: lazy(() => import('../views/skill mapping/skills applied/SkillsAppliedData'))
  },
  {
    exact: 'true',
    path: 'skills-acquired',
    layout: SkillMapping,
    element: lazy(() => import('../views/skill mapping/skills acquired/SkillsAcquiredData'))
  },

];

export default skillMappingRoutes;
