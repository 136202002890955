import { lazy } from 'react';
import UpSkillings from '../views/upskillings/UpSkillings';


const upSkillingRoutes = [
  {
    exact: 'true',
    path: 'view-courses',
    layout: UpSkillings,
    element: lazy(() => import('../views/upskillings/view upskilling/ViewUpSkillings'))
  },
  {
    exact: 'true',
    path: 'view-courses/:name/:id',
    layout:  UpSkillings,
    element: lazy(() => import('../views/upskillings/view upskilling/CourseViewSo')) //need to change this path like top one
  },

];

export default upSkillingRoutes;