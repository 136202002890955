import { createSlice } from "@reduxjs/toolkit";

const createCourseSlice = createSlice({
    name: "createCourseSlice",
    initialState: {
        remoteCheckBox: false,
        data: {
        },
        preRequisiteSkills: [],
        skillsAttainable: [],
        questions: [],
        attachmentAdded: false,
        attachment: null,
        postLoading: false,
    },
    reducers: {
        setCourseQuestions: (state, action) => {
            state.questions = action.payload;
            console.log("questions", " ", state.questions);
        },
    },


});

export const { 
    setCourseQuestions } = createCourseSlice.actions;
export default createCourseSlice.reducer;



