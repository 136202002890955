import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GetJdSkillAndQnWithId } from "../../../api/SkillSeeker/job detail/GetJdSkillAndQnWithId.js";
import { showErrorToast } from "../../../components/ToastNotification/showErrorToast.js";
import { useDispatch } from 'react-redux';
import store from "../../../Store.js";
import { fetchAgencySavedSearch } from "../../../api/SkillingAgency/fetchAgencySavedSearch.js";

// Define a thunk for recalling the JD list

export const recallJd = createAsyncThunk(
    "AgencySavedSearchSlice/recallCourse",
    async (_, { getState, rejectWithValue }) => {
        const { AgencySavedSearchSlice } = getState();
        try {
            const response = await fetchAgencySavedSearch(AgencySavedSearchSlice.searchQuery);
            return response;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const initialState = {
    myCoursesList: [],
    selectedCourse: {},
    editCourse: {},
    selectedCourseApplication: {},
    noOfResult: 0,
    status: 'idle',
    myCoursesListLoading: false,
    myCoursesApiTrigger: false,
    // 'idle', 'loading', 'succeeded', 'failed'
    error: null,
    searchQuery: {
        start: 0,
        size: 10,
        sortOrder: "desc",
        sortField: "",
        filter: ""
    },
    metaData: {
        totalCount: 0,
        nextStart: 0,
        hasNext: true
    }

};
const AgencySavedSearchSlice = createSlice({
    name: "AgencySavedSearchSlice",
    initialState: {
        myCoursesList: [],
        selectedCourse: {},
        editCourse: {},
        noOfResult: 0,
        status: 'idle',
        myCoursesListLoading: false,
        selectedCourseApplication: {},
        myCoursesApiTrigger: false,
        // 'idle', 'loading', 'succeeded', 'failed'
        error: null,
        searchQuery: {
            start: 0,
            size: 10,
            sortOrder: "desc",
            sortField: "",
            filter: ""
        },
        metaData: {
            totalCount: 0,
            nextStart: 0,
            hasNext: true
        }

    },
    reducers: {
        setMyCoursesList: (state, action) => {

            state.myCoursesList = action.payload;

        },

        setCourseCandidateAcquiredSkills: (state, action) => {
            const { userId, acquiredSkillData } = action.payload;
            const index = state.selectedCourseApplication && state.selectedCourseApplication.mystProfile === userId ? 0 : -1;
            console.log("found Acquired  index ", index);
            // If the object with the given userId is found
            if (index !== -1) {
                console.log("Object with id axquired", acquiredSkillData, " found.");

                // if (acquiredSkillData?.length > 0) {
                //     acquiredSkillData?.map((datas) => {
                //         return {
                //             ...datas,
                //             validation: datas?.ValidationData?.length > 0 ? "Yes" : "No"
                //         }
                //     })
                // }



                // Create a copy of the object with the additional field added
                state.selectedCourseApplication = { ...state.selectedCourseApplication, skillacq: acquiredSkillData };
                console.log(state.selectedCourseApplication);
            } else {
                console.log("Object with id axquired", acquiredSkillData, " not found.");
            }
        },
        setCourseCandidateAppliedSkills: (state, action) => {
            const { userId, appliedSkillData } = action.payload;
            const index = state.selectedCourseApplication && state.selectedCourseApplication.mystProfile === userId ? 0 : -1;
            console.log("found Applie  index ", index);
            // If the object with the given userId is found
            if (index !== -1) {

                // if (appliedSkillData?.length > 0) {
                //     appliedSkillData?.map((datas) => {
                //         return {
                //             ...datas,
                //             validation: datas?.ValidationData?.length > 0 ? "Yes" : "No"
                //         }
                //     })
                // }

                console.log(appliedSkillData);
                // Create a copy of the object with the additional field added
                state.selectedCourseApplication = { ...state.selectedCourseApplication, skillapp: appliedSkillData };
                console.log("Object with id applied", appliedSkillData, "  found.");
                console.log("indx ", state.selectedCourseApplication);
            } else {
                console.log("Object with id applied", appliedSkillData, " not found.");
            }
        },

        setCourseCandidateValidationData: (state, action) => {
            const { userId, data } = action.payload;
            const index = state.selectedCourseApplication && state.selectedCourseApplication.mystProfile === userId ? 0 : -1;
            console.log("found Applie  index ", index);
            // If the object with the given userId is found
            if (index !== -1) {
                console.log(data);
                state.selectedCourseApplication = { ...state.selectedCourseApplication, validationDatas: data };
                console.log("Object with id Validatin", data, "  found.");
                console.log("indx ", state.selectedCourseApplication);
            } else {
                console.log("Object with Validatin", data, " not found.");
            }
        },
        
        setCourseCandidateDetailedProfileData: (state, action) => {
            console.log("action of detailed page ", action.payload);
            const { userID } = action.payload;
            if (userID === null) {
                return;
            }
            const index = state.selectedCourseApplication && state.selectedCourseApplication.mystProfile === userID ? 0 : -1;
            console.log("found Detaile  index ", index);
            // If the object with the given userId is found
            if (index !== -1) {
                console.log("All details of user", action.payload, " found.");

                if (action.payload) {
                    state.selectedCourseApplication = { ...state.selectedCourseApplication, detailedProfileData: action.payload };

                }

                // Create a copy of the object with the additional field added

            } else {
                console.log("Object with id axquired", userID, " not found.");
            }
        },

        handleAgencyCandidateApiTrigger: (state) => {
            state.myCoursesApiTrigger = !state.myCoursesApiTrigger;
            console.log("handleAgencyCandidateApiTrigger");
        },
        setSelectedCourse: (state, action) => {
            let id = action.payload;
            let data = state.myCoursesList?.findIndex(item => item.id === id);

            state.selectedCourse = state?.myCoursesList[data];
            console.log(action.payload);
            // state.selectedCourse = action.payload;

        },
        setSelectedCourseWithData: (state, action) => {
            // let id = action.payload;
            // let data = state.myCoursesList?.findIndex(item => item.id === id);

            // state.selectedCourse = state?.myCoursesList[data];
            // console.log(action.payload);
            state.selectedCourse = action.payload;
        },

        setEditCourse: (state, action) => {
            state.editCourse = action.payload;
        },
        setSelectedCourseApplication: (state, action) => {
            let data = action.payload;

            state.selectedCourseApplication = data;
            console.log(action.payload);
            //state.selectedCourse = action.payload;

        },
        //   logout reset to initial state
        emptyMyCourses: (state) => {
            return initialState;

        },
        emptySelectedCourseApplication: (state) => {
            state.selectedCourseApplication = {};
        },

        updateMyCourse: (state, action) => {
            let data = action.payload;
            console.log("data ", data);
            if (data.length === 0) {
                return;
            }
            let courseDataIndx = state.myCoursesList.findIndex(item => item.id === data.id);

            state.myCoursesList[courseDataIndx] = data;
            state.selectedCourse = data;
        },
        setPaginationStore: (state, action) => {
            state.searchQuery.start = action.payload.start;
            state.searchQuery.size = action.payload.size;
        },
        setSortingStore: (state, action) => {
            console.log("state ", action.payload);
            state.searchQuery.sortOrder = action.payload.sortOrder;
            state.searchQuery.sortField = action.payload.sortField;
        },
        setFilterStore: (state, action) => {
            state.searchQuery.filter = action.payload.filter;
        },
        getQueryDetails: (state) => {
            console.log("Query Details JD ", state.searchQuery);
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(recallJd.pending, (state) => {
                state.status = "loading";
                state.error = null;
                state.myCoursesListLoading = true
            })
            .addCase(recallJd.fulfilled, (state, action) => {
                try {
                    let data = action.payload;
                    const length = data.length;
                    state.myCoursesList = data.slice(0, length - 1);
                    state.metaData = data[length - 1];
                    state.status = "success";
                    state.error = null;
                    state.myCoursesListLoading = false;
                    console.log("jd Lists ", data);
                } catch (error) {
                    console.log(error);
                   

                }
            })
            .addCase(recallJd.rejected, (state, action) => {
                state.status = "error";
                state.myCoursesListLoading = false;
                state.error = action.error.message;
            })
            .addCase(fetchAgencySavedSearch.pending, (state) => {
                state.status = "loading";
                state.error = null;
                state.myCoursesListLoading = true
            })
            .addCase(fetchAgencySavedSearch.fulfilled, (state, action) => {
                try {
                    let data = action.payload;
                    const length = data.length;
                    state.myCoursesList = data.slice(0, length - 1);
                    state.metaData = data[length - 1];
                    state.status = "success";
                    state.error = null;
                    state.myCoursesListLoading = false;
                    console.log("jd Lists ", data);
                } catch (error) {
                    console.log(error);
                  

                }
            })
            .addCase(fetchAgencySavedSearch.rejected, (state, action) => {
                state.status = "error";
                state.myCoursesListLoading = false;
                state.error = action.error.message;
            })

    },

});

export const { setJdBasedResult, emptyJdDetails, setSelectedData, setSavedDataList,
    setJdSkillsInSelectedData, updateJd, setPaginationStore, setSelectedApplication,
    setSortingStore, setFilterStore, handleAgencyCandidateApiTrigger, setSelectedCourse,
    setAcquiredSkills, setAppliedSkills, setDetailedProfileData, emptySelectedApplication,setCourseCandidateValidationData, setSelectedCourseWithData } = AgencySavedSearchSlice.actions;
export default AgencySavedSearchSlice.reducer;



