import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { HashRouter } from "react-router-dom";
import "./App.css";
import { setRegCountry } from "./reducer/localization/CountryRegionalSlice";
import renderRoutes, { routes } from "./routes";
import ErrorBoundary from "./Error Boundary/ErrorBoundary";

const queryClient = new QueryClient();

function App() {
  const dispatch = useDispatch();
  const regionalData = useSelector(
    (state) => state.regionalData.listOfCountries
  );
  const selectedCountry = useSelector(
    (state) => state.regionalData.selectedCountry
  );

  useEffect(() => {
    if (regionalData) {
      regionalData?.map((country) => {
        if (country?.countryCode === localStorage.getItem("countryCode")) {
          dispatch(setRegCountry(country));
          return;
        }
      });
    }
  }, [localStorage.getItem("countryCode"), localStorage.getItem("TimeZone")]);
  useEffect(() => {
    regionalData?.map((country) => {
      if (country?.countryCode === localStorage.getItem("countryCode")) {
        dispatch(setRegCountry(country));
        return;
      }
    });
  }, []);

  useEffect(() => {
  }, [selectedCountry]);

  return (
    <QueryClientProvider client={queryClient}>
      <ErrorBoundary>
        <HashRouter basename={"/"}>
          {renderRoutes(routes)}
        </HashRouter>
      </ErrorBoundary>
    </QueryClientProvider>
  );
}

export default App;
