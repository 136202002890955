import axios from "axios";
import { BASE_URL } from "../../../config/Properties";
import { createAsyncThunk } from "@reduxjs/toolkit";


export const GetJdSkillAndQnWithId = createAsyncThunk(
    "GetJdDetail/fetchData",
    async (jid) => {
        try {
            const token = localStorage.getItem("token");
            //http://localhost:8080/skill/api/v1/skills/dynamics/JQuestions?filter=jid%3AJDS-3382988&authToken=32c08936aa89ee4f3fc652b506f62fbb
            const response = await fetch(`${BASE_URL}/skill/api/v1/skills/jd/getJdSkillAndQns/${jid}?AuthToken=${token}`);
            const data = await response.json();
            return data;
        } catch (error) {
            throw error;
        }
    }
);