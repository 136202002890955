import { createSlice } from "@reduxjs/toolkit";

const deleteDetailedProfileSlice = createSlice({
    name: "deleteDetailedProfile",
    initialState: null,
    reducers: {
        setDeleteDetailedProfileData: (state, action) => action.payload
    }
    
});

export const { setDeleteDetailedProfileData } = deleteDetailedProfileSlice.actions;
export default deleteDetailedProfileSlice.reducer;