import { lazy } from 'react';

const supportServicesRoutes = [
  {
    exact: 'true',
    path: 'skillsearch',
    element: lazy(() => import('../../pages/SupportService/ServiceDashboardLayout')),
    children: [
        {
            path: '',
            exact: 'true',
            element: lazy(() => import('../../pages/SupportService/ServiceDashboard'))
        },
        {
            path: ':id',
            exact: 'true',
            element: lazy(() => import('../../pages/SupportService/CompanyList'))
        }
    ]
  },
  {
    exact: 'true',
    path: 'servicesearch',
    element: lazy(() => import('../../pages/SupportService/ServiceSearch/ServiceSearch'))
  },
  {
    exact: 'true',
    path: 'servicesearch/topservices',
    element: lazy(() => import('../../pages/SupportService/TopServices'))
  },
  {
    exact: 'true',
    path: 'createservice',
    element: lazy(() => import('../../pages/SupportService/CreateService.jsx/CreateService'))
  },
  {
    exact: 'true',
    path: ':id',
    element: lazy(() => import('../../components/Supportservices/ServiceDetails'))
  },
  {
    exact: 'true',
    path: ':id',
    element: lazy(() => import('../../pages/SupportService/EditService'))
  }
];

export default supportServicesRoutes;
