import React from "react";
import { Button } from "react-bootstrap";
import useContentLabel from "../../../hooks/useContentLabel";

const CancelButton = ({ disabled, onClick }) => {
  /* LABEL CONTENT */
  const contentLabel = useContentLabel();
  return (
    <Button variant="secondary" disabled={disabled} onClick={onClick}>
      {contentLabel("Cancel", "nf Cancel")}
    </Button>
  );
};

export default CancelButton;
