import { createSlice } from "@reduxjs/toolkit";

const screenNameSlice = createSlice({
    name: "screenName",
    initialState: "MAIN",
    reducers: {
        setScreenName: (state, action) => {
            return action.payload
        },
        removeScreenName: (state, action) => {
            return action.payload = "MAIN"
        }
    },
});

export const { setScreenName, removeScreenName } = screenNameSlice.actions;
export default screenNameSlice.reducer;