import { createSlice } from "@reduxjs/toolkit";

//const initialState = [{ skill: "", experience: "", required: true, validated: true, edit: false, mandatory: false, show: true }];

const intitalState = {
    skillsInRefined: [],
    locationsInRefined: []
}

const RefMyRequirementsSkillSlice = createSlice({
    name: "RefMyRequirements",
    initialState: {
        skillsInRefined: [],
        locationsInRefined: [],

    }
    ,
    reducers: {
        setMyRefinedSkills: (state, action) => {
            state.skillsInRefined = action.payload;
            console.log("refine skils ", action.payload);

        }, setMyRefinedLocations: (state, action) => {
            state.locationsInRefined = action.payload;

        },

        emptyMyRefinedSkills: (state) => {
           return intitalState


        },
        logoutforRefMyRequirements: () => { return intitalState }
    }
})

export const { setMyRefinedSkills,
    setMyRefinedLocations,
    emptyMyRefinedSkills,
    logoutforRefMyRequirements
} = RefMyRequirementsSkillSlice.actions;
export default RefMyRequirementsSkillSlice.reducer;