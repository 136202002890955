import React, { useMemo } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { FaAngleLeft } from "react-icons/fa6";
import { useLocation } from "react-router-dom";
import BackToTop from "../../components/BackToTop/BackToTop";
import DeleteFormDetailedProfile from "../../components/DeleteFormDetailedProfile";
import CheckoutStepper from "../../components/Steppers/Stepper";
import useContentLabel from "../../hooks/useContentLabel";

const SkillMapping = ({ children }) => {
  const contentLabel = useContentLabel();
  const { pathname } = useLocation();
  const steps = [
    {
      name: contentLabel("Back", "nf Back"),
      number: <FaAngleLeft />,
      link: "/owner/profile-management/skills",
      Component: () => <div> {contentLabel("step", "step")}6</div>,
    },
    {
      name: contentLabel("SkillsAcquiredTitle", "nf Skills Acquired"),
      number: 6,
      link: "/owner/skill-mapping/skills-acquired",
      Component: () => <div>{contentLabel("step", "step")}1</div>,
    },
    {
      name: contentLabel("SkillsAppliedTitle", "nf Skills Applied"),
      number: 7,
      link: "/owner/skill-mapping/skills-applied",
      Component: () => <div>{contentLabel("step", "step")}2</div>,
    },
  ];

  const activePath = useMemo(() => {
    if (pathname === "/owner/skill-mapping/skills-applied") {
      return contentLabel("SkillsAppliedTitle", "nf Skills Applied");
    }
    if (pathname === "/owner/skill-mapping/skills-acquired") {
      return contentLabel("SkillsAcquiredTitle", "nf Skills Acquired");
    }
  }, [pathname, contentLabel]);

  return (
    <div class="content-body">
      <div class="container-fluid">
        <DeleteFormDetailedProfile />
        {/* <Outlet />  */}

        <div className="profile-management-layout">
          <div class="row page-titles card" style={{ backgroundColor: "#fff" }}>
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="javascript:void(0);">
                  {contentLabel("SkillsMapping", "Skills Mapping")}
                </a>
              </li>
              <li class="breadcrumb-item active">
                <a href="javascript:void(0);">{activePath}</a>
              </li>
            </ol>
          </div>

          <Row>
            <Col sm={12}>
              <Card className="p-4">
                <CheckoutStepper steps={steps} />
              </Card>
            </Col>
          </Row>

          {children}
        </div>
      </div>
      <BackToTop />
    </div>
  );
};

export default SkillMapping;
