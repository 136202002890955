import axios from "axios";
import { BASE_URL } from "../../config/Properties";

const AttachmentPost = async (form, id, body) => {


    return axios
        .post(`${BASE_URL}/skill/api/v1/skills/${form}/fileupload/${id}`,
            body,
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                    "Content-Type": "multipart/form-data",
                }
            })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            throw error;
        });
};

export default AttachmentPost;
