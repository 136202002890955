import { createSlice } from "@reduxjs/toolkit";

const selectedUpSkilling = createSlice({
    name: "selectedUpSkilling",
    initialState: null,
    reducers: {
        setSelectedUpSkillingData: (state, action) => action.payload
    }
    
});

export const { setSelectedUpSkillingData } = selectedUpSkilling.actions;
export default selectedUpSkilling.reducer;