import { createSlice } from "@reduxjs/toolkit";

const validationUploadSlice = createSlice({
    name: "skillValidation",
    initialState: null,
    reducers: {
        setValidationUploadData: (state, action) => action.payload
    }
});

export const { setValidationUploadData } = validationUploadSlice.actions;
export default validationUploadSlice.reducer;