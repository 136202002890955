import { lazy } from 'react';
import Opportunities from '../views/opportunities/Opportunities';

const opportunitiesRoutes = [
  {
    exact: 'true',
    path: 'view-opportunities',
    layout: Opportunities,
    element: lazy(() => import('../views/opportunities/view opportunities/ViewOpportunities'))
  },
  {
    exact: 'true',
    path: 'view-opportunities/:name/:id',
    layout: Opportunities,
    element: lazy(() => import('../views/opportunities/view opportunities/JobViewSo')) //need to change this path like top one
  },

];

export default opportunitiesRoutes;