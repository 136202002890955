// input number of days return to readable format

function formatNumberWithOneDecimalPlace(number) {
  
  // Check if the number is an integer
  if (Number.isInteger(number)) {
    return number + ".0"; // Add .0 for integers
  } else {
    // Round to one decimal place and return as string]
    
    return Math.trunc(number * 10) / 10;
    //11.9
  }
}
export function DayDifferenceToDynamicView(totalDays) {
  if(!totalDays) {
    return "0";
  }
  totalDays = Number(totalDays);
  totalDays = totalDays+1

  // if (totalDays === 0) {
  //   return "0";
  // }
  if (totalDays <= 3) {
    return "< 0.1M";
  }


  if (totalDays < 365) {
    let duration = formatNumberWithOneDecimalPlace((totalDays / 30.41))
    return `${duration}M`;
  } else {
    let year = Math.floor(totalDays / 365)
    let months = formatNumberWithOneDecimalPlace((totalDays % 365) / 30.41)
    
    return months >0 ? `${year}Y ${months}M`  : `${year}Y` ;
  }

}

export function calculateDateDifference(startDateStr, endDateStr = "") {
  // Parse the input date strings
  const startDate = new Date(startDateStr);
  const endDate = endDateStr ? new Date(endDateStr) : new Date();

  // Include the end date in the calculation by adding one day to the end date
  endDate.setDate(endDate.getDate() + 1);

  // Calculate the difference in milliseconds
  const diffInMs = endDate - startDate;
  const diffInDays = diffInMs / (1000 * 60 * 60 * 24);

  const daysInYear = 365.25;
  const daysInMonth = daysInYear / 12;

  const years = Math.floor(diffInDays / daysInYear);
  let remainingDays = diffInDays % daysInYear;
  const months = Math.floor(remainingDays / daysInMonth);
  remainingDays = remainingDays % daysInMonth;

  // Format the output based on the provided criteria
  if (years >= 1) {
    return `${years}Y ${months}M`;
  } else if (months >= 1) {
    return `${(years * 12 + months + remainingDays / daysInMonth).toFixed(1)}M`;
  } else {
    return `${(remainingDays / daysInMonth).toFixed(1)}M`;
  }
}

