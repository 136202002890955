import { createSlice } from "@reduxjs/toolkit";
import { fetchTopSkill } from "../../api/fetchAllData/fetchTopSkill";

const initialState = {
  data: [
    {
      id: "",
      userSkill: "",
      yoe: "",
      userRank: 1,
      mlanguage: "",
      status: "",
    },
  ],
  status: "idle",
  error: null,
};

const TopSkillSlice = createSlice({
  name: "TopSkill",
  initialState,
  reducers: {
    addNewTopSkill: (state, action) => {
      state.data.push(action.payload);

      // Sort the data based on userRank
      state.data.sort((a, b) => a.userRank - b.userRank);
    },
    replaceTopSkillById: (state, action) => {
      const { id, updatedData } = action.payload;

      // Find the index of the item with the provided id
      const index = state.data.findIndex((item) => item.id === id);

      // If the item is found, update it with the new data
      if (index !== -1) {
        state.data[index] = { ...state.data[index], ...updatedData };
      }
    },
    logoutTopSkill: ()=> initialState
    
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTopSkill.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchTopSkill.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = "success";
        state.error = null;

        // Sort the data based on userRank
        state.data.sort((a, b) => a.userRank - b.userRank);
      })
      .addCase(fetchTopSkill.rejected, (state, action) => {
        state.status = "error";
        state.error = action.error.message;
      });
    
  },
});

export const { addNewTopSkill, replaceTopSkillById,logoutTopSkill } = TopSkillSlice.actions;
export default TopSkillSlice.reducer;
