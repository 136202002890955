import { createSlice } from "@reduxjs/toolkit";
import { fetchCountryRegional } from "../../api/content/fetchRegionalCountries"

/* INITIAL VALUE */
const initialCountryState = {
    country: "United States",
    region: "USA", continent: "America",
    dateFormat: "mm/dd/yyyy",
    currency: "$",
    currencySeparator: "2,2",
    timeZone: "",
    homeLanguage: "EN-US",
    homeCountry: "us",
    language: "EN",
    length: "meter",
    weight: "kg",
    borderColor: "red",
    countryCode: "US",
}

const CountryRegionalSlice = createSlice({
    name: 'regionalData',
    initialState: {
        listOfCountries: [],
        status: "", error: "",
        selectedCountry: initialCountryState,

    }, // Set the default language here
    reducers: {
        setRegCountry: (state, action) => {

            state.selectedCountry = action.payload;
        },
        getList: (state) => {
            return state.listOfCountries;
        },
        resetCountry: (state) => {
            state.selectedCountry = initialCountryState;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCountryRegional.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchCountryRegional.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.listOfCountries = action.payload;
            })
            .addCase(fetchCountryRegional.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
            });
    },

});
export const { setRegCountry, resetCountry } = CountryRegionalSlice.actions;
export default CountryRegionalSlice.reducer;
