import { createSlice } from "@reduxjs/toolkit";
import { fetchAwardsHistory } from "../../api/fetchAllData/fetchAwardsHistory";
const initialState = {
  data: [
    {
    mtype:"Awards",
    fromDate:"",
    toDate:"",
    duration:"",
    briefDescriptions:"",
    location:"",
    showHide:"",
    validation:"",
    blockChain:"",
    mlanguage:"",
    AwardsName:"",
    organization:"",
    userId:"",
    title:""
  }
  ],
  status: "idle", // possible values: "idle", "loading", "success", "error"
  error: null,
};
const achievementSlice = createSlice({
  name: "achievementHistory",
  initialState,
  reducers: {
    setAwards: (state, action) => {
      state.data = action.payload;
      state.status = "success";
      state.error = null;
    },
    emptyAwards: (state) => {
      state.data = [];
      state.status = "idle";
      state.error = null;
    },
    addNewAwards: (state, action) => {
      state.data = [action.payload,...state.data ];
      state.status = "success";
      state.error = null;
    },
    AwardsDelete: (state, action) => {
      state.data = state.data.filter((skill) => skill.id !== action.payload);
    },
    logoutAwards: ()=> initialState,
    achievementUpdate: (state, action) => {
      const { id, validation } = action.payload;
      const skillToUpdate = state.data.find((skill) => skill.id === id);
      if (skillToUpdate) {
        skillToUpdate.validation = validation;
      }
    },
    achievementUpdateRecord: (state, action) => {
      state.data = state.data.map((skill) => {
        if (skill.id === action.payload.id) {

          return {
            ...skill,
            ...action.payload,
          };
        }

        return skill;
      });
      console.log(state.data);
      state.status = "success";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAwardsHistory.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchAwardsHistory.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = "success";
        state.error = null;
      })
      .addCase(fetchAwardsHistory.rejected, (state, action) => {
        state.status = "error";
        state.error = action.error.message;
      });
  },
});

export const {achievementUpdateRecord,setAwards, emptyAwards,addNewAwards,AwardsDelete,logoutAwards, achievementUpdate} = achievementSlice.actions;
export default achievementSlice.reducer;
