// store.js
import { configureStore } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { fetchCountryRegional } from "./api/content/fetchRegionalCountries";
import { fetchUserAttachment } from "./reducer/attachments/getUserAttachmentSlice";
import { fetchUserDetailData } from "./reducer/detailed profile/userDetailedProfilSlice";
import { logoutEmployment } from "./reducer/detailedProfile/employmentSlice";
import { logoutProject } from "./reducer/detailedProfile/projectSlice";
import { fetchData } from "./reducer/localization/contentSlice";
import { fetchLanguages } from "./reducer/localization/listOfLangSlice";
import { logoutSkillSelected } from "./reducer/mySkills/SkillSelectedSlice";
import { logoutTopSkill } from "./reducer/mySkills/TopSkillSlice";
import { fetchCategory } from "./reducer/nf specific/categorySlice";
import { removeResumeSkills } from "./reducer/resume/resumeSkillSlice";
import { fetchRoles } from "./reducer/roles/RoleMappingSlice";
import rootReducer from "./reducer/rootReducer";
import { removeScreenName } from "./reducer/screen/screenNameSlice";
import { logoutSkillsAcquired } from "./reducer/skillProfile/SkillsAcquiredSlice";
import { logoutSkillsApplied } from "./reducer/skillProfile/SkillsAppliedSlice";
import { emptyJdApplicationDetails } from "./reducer/SkillSeeker/JdData/JdApplicationSlice";
import { emptyJdDetails } from "./reducer/SkillSeeker/JdData/JdDataSlice";
import { emptyCompanyData } from "./reducer/SkillSeeker/JdData/UserCompanyDataSlice";
import { logoutforMyRequirements } from "./reducer/SkillSeeker/SkillBasedSearch/MyRequirementSkillSlice";
import { logoutforRefMyRequirements } from "./reducer/SkillSeeker/SkillBasedSearch/RefMyRequirementsSkillSlice";
import { logOutForSkillSeekerSearch } from "./reducer/SkillSeeker/SkillBasedSearch/SkillBasedResultSlice";
import { fetchValidation } from "./reducer/validation/getUserValidationSlice";
import { fetchMenuItemR1, fetchMenuItemR2, fetchMenuItemR3 } from "./reducer/menuItems/menuItemR1Slice";
import { logoutEducation } from "./reducer/detailedProfile/educationSlice";
import { logoutCertification } from "./reducer/detailedProfile/certificationSlice";
import { logoutTraining } from "./reducer/detailedProfile/trainingSlice";
import { logoutConference } from "./reducer/detailedProfile/conferenceSlice";
import { logoutSkilling } from "./reducer/detailedProfile/skillingsSlice";





const store = configureStore({
  reducer: rootReducer,

});

export const useAppSelector = useSelector;
// Dispatch the fetchData action when the store is configured
// write here what ever you want to load on app load
store.dispatch(fetchData());
store.dispatch(fetchLanguages());
store.dispatch(fetchRoles());
store.dispatch(fetchMenuItemR1());
store.dispatch(fetchMenuItemR2());
store.dispatch(fetchMenuItemR3());

/* SPECIFIC CATEGORY DISPATCH */
store.dispatch(fetchCategory());
store.dispatch(fetchCountryRegional());

/* GET USER VALIDATION DISPATCH */
store.dispatch(fetchValidation());
/* GET USER ATTACHMENT */
store.dispatch(fetchUserAttachment());
store.dispatch(fetchUserDetailData());

// logout function to set all user data alone to initial state
export const logoutUser = () => {
  store.dispatch(logoutTopSkill());
  store.dispatch(logoutSkillsApplied());
  store.dispatch(logoutSkillsAcquired());
  store.dispatch(logoutSkillSelected());
  store.dispatch(logoutEmployment());
  store.dispatch(logoutProject());
  store.dispatch(logoutEducation());
  store.dispatch(logoutCertification());
  store.dispatch(logoutTraining());
  store.dispatch(logoutConference());
  store.dispatch(logoutSkilling());
  store.dispatch(removeResumeSkills());
  store.dispatch(removeScreenName());

  //seeker logout data
  store.dispatch(logOutForSkillSeekerSearch());
  store.dispatch(logoutforRefMyRequirements());
  store.dispatch(logoutforMyRequirements());
  store.dispatch(emptyJdDetails());
  store.dispatch(emptyJdApplicationDetails());
  store.dispatch(emptyCompanyData());
  console.log("logout fn2 ");

};





export default store;
