import { createSlice } from "@reduxjs/toolkit"

const skillsAppliedEditSlice = createSlice({
    name: "skillsAppliedEdit",
    initialState: null,
    reducers: {
        setSkillsAppliedEditData: (state, action) => action.payload
    }
});

export const { setSkillsAppliedEditData } = skillsAppliedEditSlice.actions;
export default skillsAppliedEditSlice.reducer;