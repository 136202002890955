import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const showErrorToast = (message, options) => {
  toast.error(message, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: false,
    pauseOnHover: false,
    draggable: false,
    // progress: undefined,
    theme: "colored",
    closeButton: false,
    ...options,
    className: "snackbar", 
    bodyClassName: "snackbar-body",
    style:{ backgroundColor: "#875712" }
  });
};
