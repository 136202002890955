const seekerMenuItems = {
  items: [
    {
      id: 'navigation',
      title: 'Analytics',
      type: 'group',
      icon: 'icon-navigation',
      children: [
        {
          id: 'dashboard',
          title: 'Dashboard',
          type: 'item',
          icon: 'feather icon-grid',
          url: '/basic/dashboard'
        }
      ]
    },

    {
      id: 'dashboard',
      title: 'Navigation',
      type: 'group',
      icon: 'feather icon-grid',
      children: [
        {
          id: 'profile-management',
          title: 'Profile ',
          type: 'collapse',
          icon: 'feather icon-user',
          children: [
            {
              id: 'Personal-details',
              title: 'Personal Details',
              type: 'item',
              url: '/skillSeeker/profile-management/profile-info'
            },
            {
              id: 'Company',
              title: 'Company',
              type: 'item',
              url: '/skillSeeker/profile-management/company'
            }
          ]
        },

        {
          id: 'opportunities',
          title: 'Opportunities',
          type: 'item',
          icon: 'feather feather icon-bar-chart',
          url: '/skillSeeker/Opportunities',

        },
        {
          id: 'skill-search',
          title: 'Skill Search',
          type: 'collapse',
          icon: 'feather icon-search',
          children: [
            {
              id: 'Database-Access',
              title: 'Database Access',
              type: 'item',
              url: '/skillSeeker/Skill-Search/Database-Access'
            },
            {
              id: 'Saved-Searches',
              title: 'Saved Searches',
              type: 'item',
              url: '/skillSeeker/Skill-Search/Saved-Searches'
            }
          ]
        },
        // {
        //   id: 'skill-search',
        //   title: 'Skill Search',
        //   type: 'item',
        //   icon: 'feather icon-search',
        //   url: '/skillSeeker/Skill-Search',

        // },
      ]
    },
    {
      id: 'consoles',
      title: 'Console',
      type: 'group',
      icon: 'icon-navigation',
      children: [
        // {
        //   id: 'candidate-tracker',
        //   title: 'Candidate Tracker',
        //   type: 'item',
        //   icon: 'feather icon-check-circle',
        //   url: '/skillSeeker/Candidate-management'
        // },
        {
          id: 'attachment-console',
          title: 'Attachment Console',
          type: 'item',
          icon: 'feather icon-paperclip',
          url: "/console/attachment",
        },

        // {
        //   id: 'privacy-console',
        //   title: 'Privacy Console',
        //   type: 'item',
        //   icon: 'feather icon-shield',
        //   url: '/skillSeeker/analytics'
        // },
      ]
    },
    // {
    //   id: 'auth',
    //   title: 'Authentication',
    //   type: 'group',
    //   icon: 'icon-pages',
    //   children: [
    //     {
    //       id: 'sign in',
    //       title: 'Login',
    //       type: 'item',
    //       icon: 'feather icon-lock',
    //       url: '/auth/signin-1',
    //       target: true,
    //       breadcrumbs: false
    //     },
    //     {
    //       id: 'sign Up',
    //       title: 'Register',
    //       type: 'item',
    //       icon: 'feather icon-log-in',
    //       url: '/auth/signup-1',
    //       target: true,
    //       breadcrumbs: false
    //     },
    //     {
    //       id: 'reset-pass',
    //       title: 'Reset Password',
    //       type: 'item',
    //       icon: 'feather icon-unlock',
    //       url: '/auth/reset-password-1',
    //       target: true,
    //       breadcrumbs: false
    //     }
    //   ]
    // },
    // {
    //   id: 'support',
    //   title: 'Support',
    //   type: 'group',
    //   icon: 'icon-support',
    //   children: [
    //     {
    //       id: 'sample-page',
    //       title: 'Chat',
    //       type: 'item',
    //       url: '/basic/sample-page',
    //       classes: 'nav-item',
    //       icon: 'feather icon-sidebar'
    //     }
    //     // ,
    //     // {
    //     //   id: 'documentation',
    //     //   title: 'Documentation',
    //     //   type: 'item',
    //     //   icon: 'feather icon-help-circle',
    //     //   classes: 'nav-item',
    //     //   url: 'https://codedthemes.gitbook.io/gradient-able-react/',
    //     //   target: true,
    //     //   external: true
    //     // }
    //   ]
    // }
    {
      id: 'settings',
      title: 'Settings',
      type: 'group',
      icon: 'icon-navigation',
      children: [
        {
          id: 'account-settings',
          title: 'Account Settings',
          type: 'item',
          icon: 'feather icon-settings',
          url: '/settings/account-settings',
          target: false,
          breadcrumbs: false
        }
      ]
    },
  ]
}

export default seekerMenuItems;