import { Component } from "react";
import SomethingWentWrong from "./something wrong page/SomethingWentWrong";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    console.error("ERROR BOUNDARY CATCH AN ERROR: ", info, error);
  }

  render() {
    /* INCASE OF ERROR */
    if (this.state.hasError) {
      return <SomethingWentWrong />;
    }

    /* INCASE OF NO ERROR */
    return this.props.children;
  }
}

export default ErrorBoundary;
