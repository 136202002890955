import { createSlice } from "@reduxjs/toolkit";
import { FetchOrganizationHistory } from "../../api/fetchAllData/fetchOrganization";

const initialState = {
  data: [
    {
      certificationName: "",
      startDate: "",
      endDate: "",
      duration: "",
      briefDescription: "",
      organizationName: "",
      location: "",
      type: "",
      showHide: "",
      validation: "",
      blockChain: "",
      dateSent: "",
      validatorName: "",
      relationship: "",
      description: "",
      validatorEmail: "",
      validatorPhone: "",
      isValidated: "",
    },
  ],
  status: "idle", // possible values: "idle", "loading", "success", "error"
  error: null,
};

const employmentSlice = createSlice({
  name: "employmentHistory",
  initialState,
  reducers: {
    setemployment: (state, action) => {
      state.data = action.payload;
      state.status = "success";
      state.error = null;
    },
    emptyemployment: (state) => {
      state.data = [];
      state.status = "idle";
      state.error = null;
    },
    addNewEmployment: (state, action) => {
      state.data = [ action.payload,...state.data];
      state.status = "success";
      state.error = null;
    },
    EmploymentDelete: (state, action) => {
      state.data = state.data.filter((skill) => skill.id !== action.payload);
    },
    logoutEmployment: ()=> initialState,
    employmentUpdate: (state, action) => {
      const { id, validation } = action.payload;
      const skillToUpdate = state.data.find((skill) => skill.id === id);
      if (skillToUpdate) {
        skillToUpdate.validation = validation;
      }
    },
    updateEmployment: (state, action) => {
      state.data = state.data.map((skill) => {
        if (skill.id === action.payload.id) {
          
          return {
            ...skill,
            ...action.payload,
          };
        }
        
        return skill;
      });
      console.log(state.data);
      state.status = "success";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(FetchOrganizationHistory.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(FetchOrganizationHistory.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = "success";
        state.error = null;
      })
      .addCase(FetchOrganizationHistory.rejected, (state, action) => {
        state.status = "error";
        state.error = action.error.message;
      });
  },
});

export const { setemployment, updateEmployment, emptyemployment,addNewEmployment,EmploymentDelete,logoutEmployment, employmentUpdate } = employmentSlice.actions;
export default employmentSlice.reducer;
