import { createSlice } from "@reduxjs/toolkit";

const selectedCourseApplicationSlice = createSlice({
    name: "selectedCourseApplication",
    initialState: {
        SelectedApplication: {},
    },
    reducers: {
        setSelectedCourseApplicationData: (state, action) => {
           state.SelectedApplication = action.payload
        },

        setCourseCandidateAcquiredSkills: (state, action) => {
            const { userId, acquiredSkillData } = action.payload;
            const index = state.SelectedApplication && state.SelectedApplication.mystProfile === userId ? 0 : -1;
            console.log("found Acquired  index ", index);
            // If the object with the given userId is found
            if (index !== -1) {
                console.log("Object with id axquired", acquiredSkillData, " found.");

                if (acquiredSkillData?.length > 0) {
                    acquiredSkillData?.map((datas) => {
                        return {
                            ...datas,
                            validation: datas?.ValidationData?.length > 0 ? "Yes" : "No"
                        }
                    })
                }



                // Create a copy of the object with the additional field added
                state.SelectedApplication = { ...state.SelectedApplication, skillacq: acquiredSkillData };
                console.log(state.SelectedApplication);
            } else {
                console.log("Object with id axquired", acquiredSkillData, " not found.");
            }
        },
        setCourseCandidateAppliedSkills: (state, action) => {
            const { userId, appliedSkillData } = action.payload;
            const index = state.SelectedApplication && state.SelectedApplication.mystProfile === userId ? 0 : -1;
            console.log("found Applie  index ", index);
            // If the object with the given userId is found
            if (index !== -1) {

                if (appliedSkillData?.length > 0) {
                    appliedSkillData?.map((datas) => {
                        return {
                            ...datas,
                            validation: datas?.ValidationData?.length > 0 ? "Yes" : "No"
                        }
                    })
                }

                console.log(appliedSkillData);
                // Create a copy of the object with the additional field added
                state.SelectedApplication = { ...state.SelectedApplication, skillapp: appliedSkillData };
                console.log("Object with id applied", appliedSkillData, "  found.");
                console.log("indx ", state.SelectedApplication);
            } else {
                console.log("Object with id applied", appliedSkillData, " not found.");
            }
        },
        setCourseCandidateDetailedProfileData: (state, action) => {
            console.log("action of detailed page ", action.payload);
            const { userID } = action.payload;
            if (userID === null) {
                return;
            }
            const index = state.SelectedApplication && state.SelectedApplication.mystProfile === userID ? 0 : -1;
            console.log("found Detaile  index ", index);
            // If the object with the given userId is found
            if (index !== -1) {
                console.log("All details of user", action.payload, " found.");

                if (action.payload) {
                    state.SelectedApplication = { ...state.SelectedApplication, detailedProfileData: action.payload };

                }

                // Create a copy of the object with the additional field added

            } else {
                console.log("Object with id axquired", userID, " not found.");
            }
        },

        // handleOpportunitiesApiTrigger: (state) => {
        //     state.opportunitiesApiTrigger = !state.opportunitiesApiTrigger;
        //     console.log("handleOpportunitiesApiTrigger");
        // },

        //   logout reset to initial state

        emptySelectedCourseApplication: (state) => {
            state.SelectedApplication = {};
        },
    }

});

export const { setSelectedCourseApplicationData , setCourseCandidateAcquiredSkills ,setCourseCandidateDetailedProfileData, setCourseCandidateAppliedSkills,   emptySelectedCourseApplication} = selectedCourseApplicationSlice.actions;
export default selectedCourseApplicationSlice.reducer;