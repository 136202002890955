// dateformat must like these MM/DD/YYYY
export const calculateDaysDifference = (startDate, endDate) => {
  // Convert start and end dates to Date objects if they are in number or string format
  const startDateObj = isNaN(Number(startDate))
    ? new Date(startDate)
    : new Date(Number(startDate));
  const endDateObj = isNaN(Number(endDate))
    ? new Date(endDate)
    : new Date(Number(endDate));

  const timeDifference = endDateObj - startDateObj;
  const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

  return daysDifference;
};
