import React from 'react'

const SmallLoader = ({height,width,bg,color}) => {
    return (
        <div class="d-flex justify-content-center align-items-center" style={{ height: bg ? bg :"100%" }}>
            <div class="spinner-border " style={{ width: width ? width : "2rem", height: height ? height : "2rem", color: color ? color : "black"  }} role="status">
               
            </div>
        </div>
    )
}

export default SmallLoader