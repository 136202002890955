import { createSlice } from "@reduxjs/toolkit";

const validationSlice = createSlice({
    name: "skillValidation",
    initialState: null,
    reducers: {
        setValidationData: (state, action) => action.payload
    }
});

export const { setValidationData } = validationSlice.actions;
export default validationSlice.reducer;