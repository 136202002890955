import { Fragment, lazy, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import AuthGuardProvider from './AuthGuard/AuthGuardProvider';
import ContentLoader from './components/CustomLoader/ContentLoader';
import LazyLoader from './components/Loader/LazyLoader';
import { BASE_URL_PATH } from './config/constant';
import AdminLayout from './layouts/AdminLayout';
import authRoutes from './routes/authRoutes';
import consoleRoutes from './routes/consoles/consoleRoutes';
import opportunitiesRoutes from './routes/opportunitiesRoutes';
import profileManagementRoutes from './routes/profileManagementRoutes';
import reportsRoutes from './routes/reports/reportsRoutes';
import skillAgencyRoutes from './routes/skill agency/skillAgencyRoutes';
import newUserSORoutes from './routes/skill owner/newUserSORoutes';
import skillOwnerRoutes from './routes/skill owner/skillOwnerRoutes';
import CreateOpportunityRoutes from './routes/skill seeker/CreateOpportunityRoutes';
import skillSeekerRoutes from './routes/skill seeker/skillSeekerRoutes';
import skillMappingRoutes from './routes/skillMappingRoutes';
import skillOwnerAuthRoutes from './routes/skillOwnerAuthRoutes';
import supportServicesRoutes from './routes/support services/supportServicesRoutes';
import upSkillingRoutes from './routes/upSkillingRoutes';

const renderRoutes = (routes = []) => (
  <Suspense fallback={<LazyLoader />}>
    <ContentLoader>
      <Routes>
        {routes.map((route, i) => {
          const Guard = route.guard || Fragment;
          const Layout = route.layout || Fragment;
          const Element = route.element;

          return (
            <Route
              key={i}
              path={route.path}
              exact={route.exact}
              element={
                <Guard>
                  <Layout>
                    {route.routes ? renderRoutes(route.routes) : <Element />}
                  </Layout>
                </Guard>
              }
            >
              {/* CHILDREN ROUTES */}
              {route.children && route.children.map((childRoute, j) => (
                <Route
                  key={j}
                  path={childRoute.path}
                  exact={childRoute.exact}
                  element={<childRoute.element />}
                />
              ))}
            </Route>
          );
        })}
      </Routes>
    </ContentLoader>
  </Suspense>
);

export const routes = [
  {
    exact: 'true',
    path: '/auth/signup-1',
    element: lazy(() => import('./views/auth/signup/SignUp1'))
  },
  {
    exact: 'true',
    path: '/auth/signin-1',
    element: lazy(() => import('./views/auth/signin/SignIn1'))
  },
  {
    exact: 'true',
    path: '/auth/reset-password-1',
    element: lazy(() => import('./views/auth/reset-password/ResetPassword1'))
  },
  {
    path: '/skillSeeker/Opportunities/Create/*',
    layout: AdminLayout,
    routes: CreateOpportunityRoutes
  },
  {
    path: 'owner/profile-management/*',
    layout: AdminLayout,
    routes: profileManagementRoutes
  },
  {
    path: 'owner/skill-mapping/*',
    layout: AdminLayout,
    routes: skillMappingRoutes,
    guard: (props) => <AuthGuardProvider role={['R1']} isSkillOwner={true} {...props} />
  },
  {
    path: 'owner/opportunities/*',
    layout: AdminLayout,
    routes: opportunitiesRoutes
  },
  {
    path: 'owner/up-skilling/*',
    layout: AdminLayout,
    routes: upSkillingRoutes
  },
  {
    path: '/console/*',
    layout: AdminLayout,
    routes: consoleRoutes
  },
  {
    path: 'owner/reports/*',
    layout: AdminLayout,
    routes: reportsRoutes
  },
  {
    path: '/*',
    exact: 'true',
    routes: authRoutes
  },
  {
    path: '/skillowner/*',
    exact: 'true',
    routes: skillOwnerAuthRoutes
  },
  {
    path: '/newuser/*',
    exact: 'true',
    routes: newUserSORoutes,
    guard: (props) => <AuthGuardProvider role={['R1']} isSkillOwner={true} {...props} />
  },
  {
    exact: 'true',
    path: '/newuser/destination',
    element: lazy(() => import('./pages/SkillOwner/NewUser/components/NewUserDestinationScreen'))
  },
  {
    path: '/dashboard/*',
    exact: 'true',
    routes: skillOwnerRoutes,
    guard: (props) => <AuthGuardProvider role={['R1']} isSkillOwner={true} {...props} />
  },
  {
    path: '/skillSeeker/*',
    layout: AdminLayout,
    routes: skillSeekerRoutes,
    guard: (props) => <AuthGuardProvider role={['R2']} {...props} />
  },
  {
    path: '/skillingagency/*',
    exact: 'true',
    layout: AdminLayout,
    routes: skillAgencyRoutes
  },
  {
    path: '/supportservices/*',
    exact: 'true',
    routes: supportServicesRoutes
  },
  {
    exact: 'true',
    path: '/dashboard/validation/:id',
    element: lazy(() => import('./pages/template/ValidationTemp'))
  },
  {
    exact: 'true',
    path: '/jd/:id',
    element: lazy(() => import('./components/GlobalJobView'))
  },
  {
    exact: 'true',
    path: '/courseview/:id',
    element: lazy(() => import('./pages/SkillOwner/course view/CourseViewSo'))
  },
  {
    path: '/owner/*',
    layout: AdminLayout,
    routes: [
      {
        exact: 'true',
        path: 'dashboard',
        element: lazy(() => import('./views/dashboard'))
      },
      {
        exact: 'true',
        path: 'support-service',
        element: lazy(() => import('./views/settings/support service/SupportService'))
      },
      {
        exact: 'true',
        path: 'independent-assessments',
        element: lazy(() => import('./views/settings/independent assessments/IndependentAssessments'))
      },
      {
        exact: 'true',
        path: 'settings/account-settings',
        element: lazy(() => import('./views/settings/account settings/Accounts'))
      },
      {
        exact: 'true',
        path: 'button',
        element: lazy(() => import('./views/ui-elements/BasicButton'))
      },
      {
        exact: 'true',
        path: 'badges',
        element: lazy(() => import('./views/ui-elements/BasicBadges'))
      },
      {
        exact: 'true',
        path: 'breadcrumb-pagination',
        element: lazy(() => import('./views/ui-elements/BasicBreadcrumbPagination'))
      },
      {
        exact: 'true',
        path: 'collapse',
        element: lazy(() => import('./views/ui-elements/BasicCollapse'))
      },

      {
        exact: 'true',
        path: 'typography',
        element: lazy(() => import('./views/ui-elements/BasicTypography'))
      },
      {
        exact: 'true',
        path: 'tooltip-popovers',
        element: lazy(() => import('./views/ui-elements/BasicTooltipsPopovers'))
      },
      {
        exact: 'true',
        path: '/sample-page',
        element: lazy(() => import('./views/extra/SamplePage'))
      },
      /* WILDCARD */
      {
        path: '*',
        exact: 'true',
        element: () => <Navigate to={BASE_URL_PATH} />
      }
    ]
  }
];

export default renderRoutes;
